(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbGdpr.controller:PrivacyPreferencesDialogCtrl
   *
   * @description
   *
   */
  angular
    .module('wbGdpr')
    .controller('PrivacyPreferencesDialogCtrl', PrivacyPreferencesDialogCtrl);

  function PrivacyPreferencesDialogCtrl(PrivacyPreferences, $mdDialog, _) {
    var vm = this;

    vm.cookies = PrivacyPreferences.getConsents();

    vm.getCookiesByType = function getCookiesByType(type) {
      return _.filter(vm.cookies, {type: type});
    };

    vm.save = function save() {
      $mdDialog.hide(vm.cookies);
    };

    vm.hide = function hide() {
      $mdDialog.cancel();
    };
  }
}());
