(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbAuth.controller:PasswordResetConfirmCtrl
   *
   * @description
   *
   */
  angular
    .module('wbAuth')
    .controller('PasswordResetConfirmCtrl', PasswordResetConfirmCtrl);

  function PasswordResetConfirmCtrl(confirmationCode, $scope, User, $translate, $document, $mdDialog) {
    var vm = this;

    $scope.$on('$stateChangeSuccess', onStateChangeSuccess);

    vm.confirmIsFailed = false;

    function onStateChangeSuccess() {
      User.resetPassword({confirmationCode: confirmationCode}).$promise.catch(function () {
        $mdDialog.show(
          $mdDialog.alert({focusOnOpen: false})
            .parent(angular.element($document.body))
            .title($translate.instant('passwordResetConfirmDialog.title'))
            .content($translate.instant('passwordResetConfirmDialog.content'))
            .ariaLabel($translate.instant('passwordResetConfirmDialog.title'))
            .ok($translate.instant('passwordResetConfirmDialog.button.ok'))
        ).finally(function () {
          vm.confirmIsFailed = true;
        });
      });
    }
  }
}());
