(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbAccount.wbCompany.factory:Company
   *
   * @description
   *
   */
  angular
    .module('wbAccount.wbCompany')
    .factory('Company', Company);

  function Company(wbResource, Order) {
    var url = 'companies/'
      , CompanyBase = wbResource('company', url + ':companyId', {companyId: '@id'}, {
        query: {
          method: 'GET',
          cache: false,
          isArray: true
        },
        get: {
          method: 'GET',
          cache: false,
          isArray: false
        },
        callback: {
          method: 'POST',
          url: 'callback',
          cache: false
        }
      });

    CompanyBase.prototype.getOrder = function getOrder() {
      return Order.getAsSub(url + this.id + '/');
    };

    return CompanyBase;
  }
}());
