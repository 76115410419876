(function () {
  'use strict';

  angular
    .module('wbPrints')
    .config(config);

  function config($stateProvider, authenticatedOnly) {
    $stateProvider
      .state('wbPrints', {
        url: '/documents',
        templateUrl: 'wb-prints/views/wb-prints.tpl.html',
        controller: 'WbPrintsCtrl',
        controllerAs: 'wbPrints',
        restrict: authenticatedOnly,
        resolve: {
          prints: function (Prints) {
            return Prints.query().$promise;
          }
        }
      });
  }
}());
