(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbTimedInformationDialog
   * @description To encapsulate the information dialogs which have a live timespan
   */
  angular
    .module('wbTimedInformationDialog', [])
    .run(displayTimedInformationDialogOnLogin);

  function displayTimedInformationDialogOnLogin($rootScope, $state, $mdDialog, $document, moment, wbTimedInformationDialogConstant) {
    var currentDialog = wbTimedInformationDialogConstant.edenredServiceAlert;

    $rootScope.$on('$stateChangeSuccess', function () {
      if (!$state.is('login')) {
        return;
      }

      if (!moment().isBetween(currentDialog.startDate, currentDialog.endDate)) {
        return;
      }

      if (currentDialog.onceOnly && localStorage.getItem(currentDialog.name)) {
        return;
      }

      $mdDialog.show({
        controller: 'WbTimedInformationDialogCtrl',
        controllerAs: 'wbTimedInformationDialog',
        templateUrl: 'wb-timed-information-dialog/views/wbTimedInformationDialog.tpl.html',
        parent: angular.element($document.body),
        focusOnOpen: false
      });
    });
  }
}());
