(function () {
  'use strict';

  /* @ngdoc object
   * @name wbForm
   * @description
   *
   */
  angular
    .module('wbForm', [
      'ngMessages',
      'pascalprecht.translate',
      'validation.match',
      'ngMaterial',
      'ui.mask'
    ])
    .config(migrateFirstDayOfWeekFunctionalityFromFutureVersionOfAngularMaterial)
    .config(fixAngularMaterialDatepickerInputHasNoAriaLabel)
    .config(setupMaterialDatepicker);

  function setupMaterialDatepicker($mdDateLocaleProvider, moment, _) {
    $mdDateLocaleProvider.firstDayOfWeek = 1;

    $mdDateLocaleProvider.formatDate = function (date) {
      if (!_.isDate(date)) {
        return date;
      }

      return moment(date).format('YYYY.MM.DD');
    };

    $mdDateLocaleProvider.parseDate = function (dateString) {
      var date = moment(dateString, 'YYYY.MM.DD', true);

      return date.isValid() ? date.toDate() : new Date(NaN);
    }
  }

  function migrateFirstDayOfWeekFunctionalityFromFutureVersionOfAngularMaterial($provide) {
    $provide.decorator('mdCalendarDirective', function ($delegate) {
      var datepicker = $delegate[0];

      /**
       * Builds and appends a day-of-the-week header to the calendar.
       * This should only need to be called once during initialization.
       */
      datepicker.controller.prototype.buildWeekHeader = function () {
        /*eslint-disable*/
        /* jshint ignore:start */
        /*jscs: disable*/
        var firstDayOfWeek = 1
          , shortDays = this.dateLocale.shortDays
          , row = document.createElement('tr')
          , i;

        for (i = 0; i < 7; i++) {
          var th = document.createElement('th');

          th.textContent = shortDays[(i + firstDayOfWeek) % 7];
          row.appendChild(th);
        }

        this.$element.find('thead').append(row);
        /*jscs: enable*/
        /* jshint ignore:end */
        /*eslint-enable*/
      };

      return $delegate;
    });

    $provide.decorator('mdCalendarMonthDirective', function ($delegate) {
      /*eslint-disable*/
      /* jshint ignore:start */
      /*jscs: disable*/
      var datepicker = $delegate[0];

      datepicker.controller.prototype.getLocaleDay_ = function (date) {
        var firstDayOfWeek = 1;

        return (date.getDay() + (7 - firstDayOfWeek)) % 7;
      };

      datepicker.controller.prototype.buildCalendarForMonth = function (opt_dateInMonth) {
        var date = this.dateUtil.isValidDate(opt_dateInMonth) ? opt_dateInMonth : new Date();

        var firstDayOfMonth = this.dateUtil.getFirstDateOfMonth(date);
        var firstDayOfTheWeek = this.getLocaleDay_(firstDayOfMonth);
        var numberOfDaysInMonth = this.dateUtil.getNumberOfDaysInMonth(date);

        // Store rows for the month in a document fragment so that we can append them all at once.
        var monthBody = document.createDocumentFragment();

        var rowNumber = 1;
        var row = this.buildDateRow(rowNumber);
        monthBody.appendChild(row);

        // If this is the final month in the list of items, only the first week should render,
        // so we should return immediately after the first row is complete and has been
        // attached to the body.
        var isFinalMonth = this.offset === this.calendarCtrl.items.length - 1;

        // Add a label for the month. If the month starts on a Sun/Mon/Tues, the month label
        // goes on a row above the first of the month. Otherwise, the month label takes up the first
        // two cells of the first row.
        var blankCellOffset = 0;
        var monthLabelCell = document.createElement('td');
        monthLabelCell.classList.add('md-calendar-month-label');
        // If the entire month is after the max date, render the label as a disabled state.
        if (this.calendarCtrl.maxDate && firstDayOfMonth > this.calendarCtrl.maxDate) {
          monthLabelCell.classList.add('md-calendar-month-label-disabled');
        }
        monthLabelCell.textContent = this.dateLocale.monthHeaderFormatter(date);
        if (firstDayOfTheWeek <= 2) {
          monthLabelCell.setAttribute('colspan', '7');

          var monthLabelRow = this.buildDateRow();
          monthLabelRow.appendChild(monthLabelCell);
          monthBody.insertBefore(monthLabelRow, row);

          if (isFinalMonth) {
            return monthBody;
          }
        } else {
          blankCellOffset = 2;
          monthLabelCell.setAttribute('colspan', '2');
          row.appendChild(monthLabelCell);
        }

        // Add a blank cell for each day of the week that occurs before the first of the month.
        // For example, if the first day of the month is a Tuesday, add blank cells for Sun and Mon.
        // The blankCellOffset is needed in cases where the first N cells are used by the month label.
        for (var i = blankCellOffset; i < firstDayOfTheWeek; i++) {
          row.appendChild(this.buildDateCell());
        }

        // Add a cell for each day of the month, keeping track of the day of the week so that
        // we know when to start a new row.
        var dayOfWeek = firstDayOfTheWeek;
        var iterationDate = firstDayOfMonth;
        for (var d = 1; d <= numberOfDaysInMonth; d++) {
          // If we've reached the end of the week, start a new row.
          if (dayOfWeek === 7) {
            // We've finished the first row, so we're done if this is the final month.
            if (isFinalMonth) {
              return monthBody;
            }
            dayOfWeek = 0;
            rowNumber++;
            row = this.buildDateRow(rowNumber);
            monthBody.appendChild(row);
          }

          iterationDate.setDate(d);
          var cell = this.buildDateCell(iterationDate);
          row.appendChild(cell);

          dayOfWeek++;
        }

        // Ensure that the last row of the month has 7 cells.
        while (row.childNodes.length < 7) {
          row.appendChild(this.buildDateCell());
        }

        // Ensure that all months have 6 rows. This is necessary for now because the virtual-repeat
        // requires that all items have exactly the same height.
        while (monthBody.childNodes.length < 6) {
          var whitespaceRow = this.buildDateRow();
          for (var i = 0; i < 7; i++) {
            whitespaceRow.appendChild(this.buildDateCell());
          }
          monthBody.appendChild(whitespaceRow);
        }

        return monthBody;
      };

      /*jscs: enable*/
      /* jshint ignore:end */
      /*eslint-enable*/
      return $delegate;
    });
  }

  function fixAngularMaterialDatepickerInputHasNoAriaLabel($provide) {
    $provide.decorator('mdDatepickerDirective', function ($delegate) {
      var datepicker = $delegate[0];

      datepicker.template =
        '<md-button class="md-datepicker-button md-icon-button" type="button" ' +
        'tabindex="-1" aria-hidden="true" ' +
        'ng-click="ctrl.openCalendarPane($event)">' +
        '<md-icon class="md-datepicker-calendar-icon" md-svg-icon="md-calendar"></md-icon>' +
        '</md-button>' +
        '<div class="md-datepicker-input-container" ' +
        'ng-class="{\'md-datepicker-focused\': ctrl.isFocused}">' +
        '<input class="md-datepicker-input" aria-haspopup="true" ' +
        'ng-focus="ctrl.setFocused(true)" ng-blur="ctrl.setFocused(false)"' +
        'aria-label="{{::ctrl.dateLocale.msgOpenCalendar}}">' +
        '<md-button type="button" md-no-ink ' +
        'class="md-datepicker-triangle-button md-icon-button" ' +
        'ng-click="ctrl.openCalendarPane($event)" ' +
        'aria-label="{{::ctrl.dateLocale.msgOpenCalendar}}">' +
        '<div class="md-datepicker-expand-triangle"></div>' +
        '</md-button>' +
        '</div>' +

          // This pane will be detached from here and re-attached to the document body.
        '<div class="md-datepicker-calendar-pane md-whiteframe-z1">' +
        '<div class="md-datepicker-input-mask">' +
        '<div class="md-datepicker-input-mask-opaque"></div>' +
        '</div>' +
        '<div class="md-datepicker-calendar">' +
        '<md-calendar role="dialog" aria-label="{{::ctrl.dateLocale.msgCalendar}}" ' +
        'md-min-date="ctrl.minDate" md-max-date="ctrl.maxDate"' +
        'ng-model="ctrl.date" ng-if="ctrl.isCalendarOpen">' +
        '</md-calendar>' +
        '</div>' +
        '</div>';

      return $delegate;
    });
  }
}());
