(function () {
  'use strict';
  // jscs:disable maximumLineLength

  angular
    .module('wbInfo')
    .config(config);

  function config($translateProvider) {
    $translateProvider.translations('hu', {
      'info.info.title': 'Információ',
      'info.info.content': 'Alkalmazásunkat mobil eszközökről is használhatja',
      'info.contact.title': 'Kapcsolat',
      'info.contact.1.title': 'Hívjon minket',
      'info.contact.openingHours': '(H-CS: 8-17, P: 8-15)',
      'info.contact.1.content': '+36 1 413 3333',
      'info.contact.2.title': 'Írjon nekünk',
      'info.contact.2.content': 'info@rewin.hu',
      'info.cards.title': 'Kártyáink',
      'info.cards.description': 'Tudjon meg többet kártyáinkról!',
      'info.requestCallback.title': 'Visszahívás kérés',
      'info.requestCallback.subtitle': 'Szakértő kollégánk rövid időn belül felkeresi Önt.',
      'info.requestCallback.button': 'Visszahívást kérek'
    });
  }
}());
