(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbOrder.controller:OrderCtrl
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .controller('OrderCtrl', OrderCtrl);

  function OrderCtrl($scope, $state, FormControl, OrderService, isAuthenticated, _) {
    var vm = this
      , formControl = new FormControl();

    vm.activeStateIndex = 0;

    $scope.$on('$stateChangeSuccess', onStateChangeSuccess);
    $scope.$on('$destroy', resetOrder);

    vm.isAuthenticated = isAuthenticated;

    vm.prevStep = function prevStep(newState, prevState) {
      vm.activeStateIndex--;

      $state.go(newState);
    };

    vm.nextStep = function nextStep(newState, prevState) {
      vm.activeStateIndex++;
      $state.go(newState);
    };

    $scope.$on('order.next', function ($event, newState, prevState) {
      vm.nextStep(newState, prevState);
    });

    $scope.$on('order.prev', function ($event, newState, prevState) {
      vm.prevStep(newState, prevState);
    });

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    function onStateChangeSuccess(event, toState) {
      var stateToGo = null;

      if (toState.name === 'order') {
        stateToGo = 'intro';
        vm.activeStateIndex = 0;
      }

      if (!_.isNull(stateToGo)) {
        $state.go(stateToGo);
      }
    }

    function resetOrder() {
      OrderService.resetOrder();
    }
  }
}());
