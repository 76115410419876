<section class="wb-login" layout="column" layout-align="center center" layout-fill>
  <h1 class="wb-auth-screen-title text-center">{{::'auth.title' | translate}}</h1>

  <p class="wb-auth-screen-subtitle text-center">{{::'auth.subtitle' | translate}}</p>

  <div class="wb-container wide">
    <div class="wb-auth-screen-box-wrapper md-whiteframe-z1" layout="row" layout-align="center center">
      <div flex>
        <form name="Login.loginForm" method="post"
          ng-submit="Login.loginForm.$valid && !Login.isProcessing() && Login.submit()" novalidate layout-md="column"
          layout-gt-md="row" layout-wrap>

          <div class="wb-auth-box wb-login-box" flex>
            <div class="wb-auth-box-heading wb-auth-box-heading-login">
              <h1 class="wb-auth-box-title">{{::'auth.login.title' | translate}}</h1>
            </div>

            <div class="wb-auth-box-inner">
              <md-input-container flex>
                <md-icon class="wb-tooltip zmdi zmdi-help zmdi-hc-lg"></md-icon>
                <label>{{::'auth.email' | translate}}</label>
                <md-tooltip md-direction="right">
                  <span ng-bind-html="::'auth.login.legend' | translate"></span>
                </md-tooltip>
                <input type="email" ng-maxlength="320" required name="identifier" ng-model="Login.session.identifier">
                <wb-messages form="Login.loginForm" server="Login.getServerValidation()" field-name="identifier">
                </wb-messages>
              </md-input-container>

              <md-input-container flex>
                <label>{{::'auth.password' | translate}}</label>
                <input type="password" required name="password" ng-model="Login.session.password">
                <wb-messages form="Login.loginForm" server="Login.getServerValidation()" field-name="password">
                </wb-messages>
              </md-input-container>

              <div class="wb-auth-box-btn-container" flex layout="row" layout-align="space-between center">
                <md-button class="md-flat"
                           ui-sref="passwordReset"
                           aria-label="{{::'auth.passwordReset' | translate}}">
                  {{::'auth.passwordReset' | translate}}
                </md-button>
                <div ng-hide="Login.isProcessing()">
                  <md-button type="submit"
                             ng-disabled="Login.errorIsLoginLimit()"
                             class="md-raised md-primary wb-auth-login-btn md-button-lg"
                             aria-label="{{::'auth.login' | translate}}">
                    {{::'auth.login.title' | translate}}
                  </md-button>
                </div>
                <div class="button-loader-wrapper" ng-show="Login.isProcessing()">
                  <md-progress-circular class="md-accent button-loader" md-diameter="36px" md-mode="indeterminate"></md-progress-circular>
                </div>
              </div>
            </div>

            <div class="wb-auth-box-footer">
              <p>{{::'auth.login.help.legend' | translate}}</p>

              <div layout="row" layout-align="center center">
                <div class="wb-auth-box-footer-contact-part" layout="row" layout-align="center center">
                  <md-icon md-font-icon="zmdi zmdi-phone"></md-icon>
                  <strong>+36 1 413 3333</strong>
                </div>
                <div class="wb-auth-box-footer-contact-part" layout="row" layout-align="center center">
                  <md-icon md-font-icon="zmdi zmdi-email"></md-icon>
                  <strong><a href="mailto:info@rewin.hu">info@rewin.hu</a></strong>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div flex>
      <!--
        <a href="https://edenred.hu/wp-content/uploads/2023/01/Fenntarthato_Dolgozoi_juttatasok_2023_Edenred.pdf" target="_blank" rel="noopener noreferrer">
          <img class="wb-auth-box-image" src="images/banners/525x480px-v15.png">
        </a>
      -->
      </div>
    </div>
  </div>

</section>
