(function () {
  'use strict';
  // jscs:disable maximumLineLength

  angular
    .module('wbInfo')
    .config(config);

  function config($translateProvider) {
    $translateProvider.translations('en', {
      'info.info.title': 'Information',
      'info.info.content': 'Our application is available on smartphones as well',
      'info.contact.title': 'Contact',
      'info.contact.1.title': 'Call us',
      'info.contact.openingHours': '(M-TH: 8-17, F: 8-15)',
      'info.contact.1.content': '+36 1 413 3333',
      'info.contact.2.title': 'Write to us',
      'info.contact.2.content': 'info@rewin.hu',
      'info.cards.title': 'Our cards',
      'info.cards.description': 'Learn more about our cards!',
      'info.requestCallback.title': 'Request callback',
      'info.requestCallback.subtitle': 'Our sales representative will call You back soon.',
      'info.requestCallback.button': 'Request callback'
    });
  }
}());
