(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbOrder.controller:SuccessCtrl
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .controller('SuccessCtrl', SuccessCtrl);

  function SuccessCtrl(LoggedUser, $translate) {
    var vm = this
      , user = LoggedUser.getUser();

    vm.user = user;
    vm.steps = [
      {
        icon: 'email',
        colorClass: 'purple',
        title: $translate.instant('order.success.first.title'),
        legend: $translate.instant('order.success.first.legend', {email: user.email})
      },
      {
        icon: 'tv-list',
        colorClass: 'lightblue',
        title: $translate.instant('order.success.second.title'),
        legend: $translate.instant('order.success.second.legend')
      },
      {
        icon: 'truck',
        colorClass: 'primary',
        title: $translate.instant('order.success.third.title'),
        legend: $translate.instant('order.success.third.legend')
      }
    ];
  }
}());
