(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbOrder.controller:ItemListCtrl
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .controller('ItemListCtrl', ItemListCtrl);

  function ItemListCtrl(_, $scope, OrderService, items) {
    var vm = this
      , existingItems = [];

    var VOUCHERS = {
      GIFT: 50,
      FOOD: 560
    };

    vm.order = OrderService.getOrder();
    existingItems = vm.order.items || [];

    vm.isFoodVoucherOrder = function isFoodVoucherOrder() {
      return vm.order.voucherId === VOUCHERS.FOOD;
    };

    items = _.map(items, function (item, index) {
      return _.assign({}, item, {unitQuantity: 0}, {boxId: item.id});
    });

    vm.order.items = _.map(items, function (item) {
      return _.assign(item, _.findWhere(existingItems, {id: item.id}));
    });

    vm.removeItem = function removeItem($index) {
      vm.order.items[$index].unitQuantity = 0;
    };

    vm.calculateItemSum = function calculateItemSum($index) {
      var item = vm.order.items[$index]
        , sum = item.unitQuantity * item.unit_amount;

      return sum;
    };

    vm.calculateTotal = function calculateTotal() {
      return _.reduce(vm.order.items, function (acc, item) {
        return acc + (item.unitQuantity * item.unit_amount);
      }, 0);
    };

    vm.prevStep = function prevStep() {
      $scope.$emit('order.prev', 'intro');
    };

    vm.nextStep = function nextStep() {
      $scope.$emit('order.next', 'invoice');
    };

    vm.submit = function submit() {
      vm.order.items = _.filter(vm.order.items, function (item) {
        return item.unitQuantity;
      });

      OrderService.setOrder(vm.order);
      vm.nextStep();
    };
  }
}());
