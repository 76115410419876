<md-dialog class="wb-privacy-preferences wb-dialog-fullscreen">
  <md-dialog-content>
    <h2 class="md-title">{{::'gdpr.privacyPreferences.title' | translate}}</h2>

    <md-tabs md-border-bottom>
      <md-tab label="{{::'gdpr.privacyPreferences.disclaimer' | translate}}">
        <md-content class="md-padding">
          <div ng-bind-html="::'gdpr.privacyPreferences.disclaimer.text' | translate"></div>
        </md-content>
      </md-tab>

      <md-tab label="{{::'gdpr.privacyPreferences.strictlyNecessaryCookies' | translate}}">
        <md-content class="md-padding">
          <div ng-bind-html="::'gdpr.privacyPreferences.strictlyNecessaryCookies.text' | translate"></div>

          <md-list>
            <md-list-item class="md-2-line"
                          ng-repeat="cookie in privacyPreferences.cookies | filter : { type: ('strictlyNecessary') }">
              <div class="md-list-item-text">
                <h3>{{::cookie.name}}</h3>
                <p>{{::cookie.translatedDescriptionKey | translate}}</p>
              </div>
              <md-switch ng-model="cookie.allowed" ng-disabled="cookie.mandatory"></md-switch>
            </md-list-item>
          </md-list>
        </md-content>
      </md-tab>

      <md-tab label="{{::'gdpr.privacyPreferences.performanceCookies' | translate}}">
        <md-content class="md-padding">
          <div ng-bind-html="::'gdpr.privacyPreferences.performanceCookies.text' | translate"></div>

          <md-list>
            <md-list-item class="md-2-line" ng-repeat="cookie in privacyPreferences.cookies | filter : { type: 'performance' }">
              <div class="md-list-item-text">
                <h3>{{::cookie.name}}</h3>
                <p>{{::cookie.translatedDescriptionKey | translate}}</p>
              </div>
              <md-switch class="md-primary" ng-model="cookie.allowed" ng-disabled="cookie.mandatory"></md-switch>
            </md-list-item>
          </md-list>
        </md-content>
      </md-tab>
    </md-tabs>
  </md-dialog-content>

  <div class="md-actions" layout="column" layout-align="start start" layout-gt-sm="row" layout-align-gt-sm="end center">
    <md-button type="button" ng-click="privacyPreferences.hide()">
      {{::'gdpr.privacyPreferences.hide' | translate}}
    </md-button>
    <md-button class="md-primary" type="button" ng-click="privacyPreferences.save()">
      {{::'gdpr.privacyPreferences.save' | translate}}
    </md-button>
  </div>
</md-dialog>
