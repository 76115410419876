(function () {
  'use strict';

  /* @ngdoc object
   * @name wbInfo
   * @description
   *
   */
  angular
    .module('wbInfo', [
      'ngMaterial',
      'pascalprecht.translate',
      'ngSanitize'
    ]);
}());
