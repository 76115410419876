(function () {
  'use strict';

  /* @ngdoc object
   * @name wbNavigation
   * @description
   *
   */
  angular
    .module('wbNavigation', [
      'ngMaterial',
      'lodash',
      'wbLoggedUser',
      'pascalprecht.translate',
      'wbInfo'
    ]);
}());
