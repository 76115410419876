<md-card>
  <md-toolbar class="md-whiteframe-z1 wb-toolbar-default">
    <div class="md-toolbar-tools">
      {{::'order.history.pending' | translate}}
    </div>
  </md-toolbar>

  <md-card-content>
    <div class="wb-table">
      <div class="wb-table-header" layout="row">
        <span flex="20">{{::'order.history.date' | translate}}</span>
        <span flex="30">{{::'order.history.description' | translate}}</span>
        <span flex="20">{{::'order.history.product' | translate}}</span>
        <span flex="20">{{::'order.history.status' | translate}}</span>
        <span flex class="text-right">{{::'order.history.total' | translate}}</span>
      </div>

      <div
        class="wb-table-item"
        layout="row"
        layout-align="start center"
        ng-repeat="order in pendingOrders">
        <span flex="20">{{::order.date | moment : 'YYYY/MM/DD'}}</span>
        <span flex="30">{{::'order.history.description.value' | translate : ({order: order})}}</span>
        <span flex="20">{{::order.voucherLabel}}</span>
        <span flex="20">{{::order.statusLabel}}</span>
        <span flex class="text-right">{{::order.amount | isoCurrency : 'HUF' : 0}}</span>
      </div>
    </div>
  </md-card-content>
</md-card>

<md-card>
  <md-toolbar class="md-whiteframe-z1 wb-toolbar-default">
    <div class="md-toolbar-tools">
      {{::'order.history.completed' | translate}}
    </div>
  </md-toolbar>

  <md-card-content>
    <div class="wb-table">
      <div class="wb-table-header" layout="row">
        <span flex="45">{{::'order.history.date' | translate}}</span>
        <span flex="45">{{::'order.history.description' | translate}}</span>
        <span flex class="text-right">{{::'order.history.total' | translate}}</span>
      </div>

      <div
        class="wb-table-item"
        layout="row"
        layout-align="start center"
        ng-repeat="order in completedOrders">
        <span flex="45">{{::order.date | moment : 'YYYY/MM/DD'}}</span>
        <span flex="45">{{::'order.history.description.value' | translate : ({order: order})}}</span>
        <span flex class="text-right">{{::order.amount | isoCurrency : 'HUF' : 0}}</span>
      </div>
    </div>
  </md-card-content>
</md-card>
