(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name wbNavigation.directive:wbFooter
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="wbNavigation">
       <file name="index.html">
        <wb-footer></wb-footer>
       </file>
     </example>
   *
   */
  angular
    .module('wbNavigation')
    .directive('wbFooter', wbFooter);

  function wbFooter() {
    return {
      restrict: 'E',
      templateUrl: 'wb-navigation/directives/wb-footer-directive.tpl.html',
      replace: false
    };
  }
}());
