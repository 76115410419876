(function () {
  'use strict';

  /* @ngdoc object
   * @name wbRestriction
   * @description
   *
   */
  angular
    .module('wbRestriction', [
      'visor'
    ]);
}());
