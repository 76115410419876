(function () {
  'use strict';

  angular
    .module('wbErrorHandler')
    .config(configErrorInterceptor)
    .config(addAnalyticsErrorTracking);

  function configErrorInterceptor($httpProvider) {
    $httpProvider.interceptors.push('ResponseHandlerInterceptor');
  }

  function addAnalyticsErrorTracking($provide) {
    $provide.decorator('$exceptionHandler', function ($delegate, $analytics) {
      return function (exception, cause) {
        $delegate(exception, cause);
        $analytics.eventTrack('AngularJS error', {
          category: exception.message,
          label: exception.stack,
          value: 0,
          noninteraction: true
        });
      };
    });
  }
}());
