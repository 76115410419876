<section class="wb-login" layout-gt-lg="column" layout-align-gt-lg="center center" layout-fill-gt-lg>

  <div class="wb-container wide">
    <form novalidate
          method="post"
          name="passwordResetForm"
          ng-submit="passwordResetForm.$valid && PasswordReset.submit()"
          layout-md="column"
          layout-gt-md="row"
          layout-align="center center">

      <div class="wb-auth-box md-whiteframe-z1" flex="50" layout-margin>
        <div class="wb-auth-box-heading wb-auth-box-heading-login">
          <h1 class="wb-auth-box-title">{{'auth.passwordReset' | translate}}</h1>
        </div>

        <div class="wb-auth-box-inner">
          <p class="wb-auth-legend">{{::'auth.passwordReset.legend' | translate}}</p>

          <md-input-container flex>
            <label>{{::'auth.email' | translate}}</label>
            <input type="email" maxlength="50" required name="email" ng-model="PasswordReset.passwordReset.email">
            <wb-messages form="passwordResetForm" server="passwordResetForm.getServerValidation()" field-name="email"></wb-messages>
          </md-input-container>

          <div layout="row" layout-sm="row" ng-hide="PasswordReset.isProcessing()">
            <div flex flex-sm="50" class="button-wrapper text-left">
              <md-button class="md-raised md-background md-button-lg no-side-margin" flex ui-sref="login">
                <md-icon class="zmdi zmdi-arrow-left zmdi-hc-lg"></md-icon>
                {{::'auth.passwordReset.back' | translate}}
              </md-button>
            </div>

            <div flex flex-sm="50" class="button-wrapper text-right">
              <md-button class="md-raised md-primary md-button-lg no-side-margin"
                         flex
                         ng-disabled="PasswordReset.errorIsPasswordResetLimit()"
                         type="submit">
                {{::'auth.passwordReset.send' | translate}}
              </md-button>
            </div>

          </div>
          <div class="button-loader-wrapper" flex ng-show="PasswordReset.isProcessing()">
            <md-progress-circular class="md-accent button-loader" md-diameter="36px"
                                  md-mode="indeterminate"></md-progress-circular>
          </div>
        </div>
      </div>

    </form>
  </div>
</section>
