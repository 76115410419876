(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbAuth.controller:PasswordResetCtrl
   *
   * @description
   *
   */
  angular
    .module('wbAuth')
    .controller('PasswordResetCtrl', PasswordResetCtrl);

  function PasswordResetCtrl(User, FormControl, _) {
    var vm = this
      , formControl = new FormControl();

    formControl.setSuccessText('auth.passwordReset.success');

    vm.passwordReset = {
      email: ''
    };

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.submit = function submit() {
      formControl.process(User.sendPasswordResetMail(vm.passwordReset).$promise);
    };

    vm.errorIsPasswordResetLimit = function errorIsPasswordResetLimit() {
      var validations = vm.getServerValidation();

      return (_.isEmpty(validations)) ? false : validations.email.passwordResetLimit;
    };
  }
}());
