(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbOrder.controller:CalculatorCtrl
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .controller('CalculatorCtrl', CalculatorCtrl);

  function CalculatorCtrl(OrderCalculatorService, OrderService, LoggedUser, vouchers, _, $q, $state, $scope) {
    var vm = this
      , calculationTypes;

    calculationTypes = {
      MONTHLY: 0,
      ONESHOT: 1,
      PARTNER: 2
    };

    vm.form = {};
    vm.selectedTabIndex = calculationTypes.MONTHLY;
    vm.vouchers = vouchers;
    vm.input = [];
    vm.results = [];
    vm.combinedResults = [];

    vm.disableCalculation = function disableCalculation() {
      if (vm.selectedTabIndex === 1) {
        if (vm.input[1] && vm.input[1].lanes) {
          return vm.input[1].lanes.length === 0;
        }
        return true;
      }

      var field = vm.form['[' + vm.selectedTabIndex + ']amountPerPerson'] ? vm.form['[' + vm.selectedTabIndex + ']amountPerPerson'] : vm.form['[' + vm.selectedTabIndex + ']amount'];
      if (!field) {
        return true;
      }

      return field['$invalid'];
    };

    vm.disableOrder = function disableOrder() {
      var results = vm.selectedTabIndex === 1 ? vm.combinedResults : vm.results;

      return results.length === 0;
    };

    vm.disableCreateLane = function disableCreateLane() {
      return vm.form['[1]amountPerPerson']['$invalid'];
    };

    vm.isUserLoggedIn = function isUserLoggedIn() {
      return LoggedUser.getCompany() && LoggedUser.isAuthenticated();
    };

    vm.createLane = function createLane() {
      var calculationData = vm.input[calculationTypes.ONESHOT];

      calculationData.lanes = calculationData.lanes || [];

      if (!calculationData.staffNumber || !calculationData.amountPerPerson) {
        return;
      }

      calculationData.lanes.push({
        staffNumber: calculationData.staffNumber,
        amountPerPerson: calculationData.amountPerPerson
      });

      clearInputByCalculationType(calculationTypes.ONESHOT);
    };

    vm.removeLane = function removeLane($index) {
      var calculationData = vm.input[calculationTypes.ONESHOT];

      calculationData.lanes.splice($index, 1);
    };

    vm.getFullAmountForGroup = function getFullAmountForGroup() {
      var total = 0,
        currentInput = vm.input[vm.selectedTabIndex];

      if (currentInput.amountPerPerson) {
        total = currentInput.staffNumber * currentInput.amountPerPerson;
      } else if (currentInput.amount) {
        total = currentInput.amount;
      }

      return total;
    };

    vm.submit = function submit() {
      vm.results = [];
      vm.combinedResults = [];
      var currentTab = vm.input[vm.selectedTabIndex],
        lanes = currentTab.lanes;
      var amountProperty = vm.selectedTabIndex === 2 ? 'amountPerPerson' : 'amount';
      if (lanes) {
        var resultPromises = [];
        lanes.forEach(function (item) {
          var amount = item.staffNumber * item.amountPerPerson,
            parts = item.staffNumber,
            voucherId = currentTab.productType;
          resultPromises.push(OrderCalculatorService.getResultsForOneGroup(amount, parts, voucherId));
        });

        vm.setCombinedResults(resultPromises);
      } else {
        var amount = vm.getFullAmountForGroup(),
          parts = currentTab.staffNumber,
          voucherId = currentTab.productType;

        OrderCalculatorService.getResultsForOneGroup(amount, parts, voucherId)
          .then(function (result) {
            vm.results.push(result);
          });
      }
    };

    vm.setCombinedResults = function setCombinedResults(resultPromises) {
      vm.combinedResults = [];
      var combinedObject = {
        'boxes': {},
        'composition': {},
        'totalAmount': 0
      };
      $q.all(resultPromises)
        .then(function (results) {
          combineObjects(results, 'boxes', combinedObject);
          combineObjects(results, 'composition', combinedObject);
          var totalAmount = results.reduce(function (acc, item) {
            return acc + item.totalAmount;
          }, 0);

          combinedObject.totalAmount = totalAmount;

          vm.combinedResults.push(combinedObject);
        });
    };

    vm.createOrder = function createOrder() {
      var voucherId = vm.input[vm.selectedTabIndex].productType;
      var vouchersList = OrderCalculatorService.publicVouchers;
      var calculationResult = vm.results.length ? vm.results : vm.combinedResults;
      var boxes = _.pluck(calculationResult, 'boxes')[0];

      boxes = _.map(vouchersList[voucherId]['boxes'], function (item) {
        return _.assign(item, {unitQuantity: boxes[item['unit_amount']]});
      });

      var orderDetails = {
        'voucherId': voucherId,
        'items': boxes
      };

      OrderService.updateOrder(orderDetails);
      $state.go('intro', {
        type: 'calculated'
      });
    };

    function combineObjects(array, objectKey, target) {
      return array
        .map(function (result) {
          return result[objectKey];
        })
        .forEach(function (item) {
          Object.keys(item).forEach(function (key) {
            if (!target[objectKey][key]) {
              target[objectKey][key] = item[key];
            } else {
              target[objectKey][key] += item[key];
            }
          });
        });
    }

    function clearInputByCalculationType(calculationType) {
      vm.form['[' + calculationType + ']staffNumber'].$setUntouched();
      vm.form['[' + calculationType + ']amountPerPerson'].$setUntouched();
      delete vm.input[calculationType].staffNumber;
      delete vm.input[calculationType].amountPerPerson;
    }
  }
}());
