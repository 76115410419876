<div class="wb-loader-overlay" ng-show="wbOrderLoader.isProcessing()">
  <div class="wb-loader-box md-whiteframe-z5">
    <div class="wb-loader">
      <svg class="moving-part" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
           y="0px" width="610px" height="278px" viewBox="0 0 610 278">
        <path fill="#10154A" d="M333.41-0.002H222.401c-7.836,0-14.478,2.723-19.916,8.157c-5.448,5.444-8.163,11.858-8.163,19.251
        v223.178c0,7.403,2.714,13.807,8.163,19.251c5.438,5.435,11.856,8.157,19.263,8.157h167.819c7.396,0,13.814-2.723,19.263-8.157
        c5.438-5.444,8.162-11.848,8.162-19.251V83.526L333.41-0.002z M361.488,222.523H249.827v-28.06h111.661V222.523z M361.488,167.056
        H249.827v-28.061h111.661V167.056z M319.697,97.23V20.88l76.399,76.351H319.697z"></path>
        <g class="left-wind">
          <rect x="45.719" y="47.906" fill="#10154A" width="116.531" height="24.219"></rect>
          <rect y="101.741" fill="#10154A" width="162.24" height="24.223"></rect>
          <rect x="103.977" y="155.569" fill="#10154A" width="58.263" height="24.223"></rect>
          <rect x="69.916" y="209.397" fill="#10154A" width="92.324" height="24.223"></rect>
        </g>
        <g class="right-wind">
          <rect x="448" y="48" fill="#10154A" width="116.344" height="24.25"></rect>
          <rect x="448.01" y="101.916" fill="#10154A" width="161.99" height="24.26"></rect>
          <rect x="448.01" y="155.826" fill="#10154A" width="58.174" height="24.259"></rect>
          <rect x="448.01" y="209.735" fill="#10154A" width="92.183" height="24.26"></rect>
        </g>
      </svg>

      <svg class="cover" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
           y="0px" width="424px" height="424px" viewBox="0 0 424 424">
        <path class="overlay" fill-rule="evenodd" d="M212,424h212V212C424,329.084,329.084,424,212,424z M212,0H0v212
        C0,94.916,94.916,0,212,0z M0,212v212h212C94.916,424,0,329.084,0,212z M212,0c117.084,0,212,94.916,212,212V0H212z"></path>
        <circle class="circle-border" cx="212" cy="212" r="200"></circle>
      </svg>
    </div>
    <p class="wb-loader-legend">{{::'order.loader.inProgress' | translate}}</p>
  </div>
</div>
