(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbAuth.service:Auth
   *
   * @description
   *
   */
  angular
    .module('wbAuth')
    .service('Auth', Auth);

  function Auth(LoggedUser, $http, Session, visor, $q, $location, $state) {
    var self = this
      , activeSession;

    resetSession();

    this.login = function login(userId) {
      return LoggedUser.setUserById(userId)
        .then(loginUser);
    };

    this.silentLogin = function silentLogin(rawSession) {
      self.setSession(rawSession);

      return activeSession
        .$save()
        .then(function (response) {
          if (response.data.error) {
            return $q.reject(response);
          }

          return response.data;
        })
        .then(function (sessionData) {
          self.setToken(sessionData.token);
          $state.go('companySelector');
        });
    };

    this.adminLogin = function adminLogin(rawSession) {
      self.setSession(rawSession);

      return Session.adminLogin(activeSession).$promise
        .then(function (sessionData) {
          self.setToken(sessionData.token);
          $state.go('companySelector');
        })
        .catch(function (response) {
          return $q.reject(response);
        });
    };

    this.silentlogout = function silentlogout() {
      return activeSession
        .$delete()
        .then(function () {
          clearHeaderToken();
          activeSession = new Session();
        });
    };

    this.logout = function logout() {
      visor.setUnauthenticated();
      LoggedUser.empty();
      resetSession();
    };

    this.invalidateSession = function invalidateSession() {
      activeSession = null;
      this.logout();
    };

    this.setToken = function setToken(token) {
      activeSession.token = token;
      $http.defaults.headers.common.token = token;
    };

    this.setSession = function setSession(rawSession) {
      activeSession.identifier = rawSession.identifier;
      activeSession.password = rawSession.password;
      activeSession.clientEmail = rawSession.clientEmail;
    };

    function setUserData(sessionData) {
      self.setToken(sessionData.token);
      return LoggedUser.setUserById(sessionData.userId);
    }

    function clearHeaderToken() {
      delete $http.defaults.headers.common.token;
    }

    function loginUser() {
      visor.setAuthenticated(LoggedUser.getUser());
    }

    function resetSession() {
      if (activeSession) {
        activeSession.$delete();
      }
      clearHeaderToken();
      activeSession = new Session();
    }
  }
}());
