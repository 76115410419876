(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name voucherExtranet.factory:WbAnalytics
   *
   * @description Save analytical data not only in GA but on the backend too.
   *
   */
  angular
    .module('voucherExtranet')
    .factory('WbAnalytics', WbAnalytics)
    .directive('wbAnalyticsOn', wbAnalyticsOn);

  function WbAnalytics(wbResource) {
    return wbResource('analytics', 'logEvent');
  }

  function wbAnalyticsOn(WbAnalytics, LoggedUser) {
    return {
      restrict: 'A',
      link: function ($scope, $element, $attrs) {
        var eventType = $attrs.wbAnalyticsOn || 'click'
          , company = LoggedUser.getCompany()
          , trackingData = {}
          , isProperty = function(name) {
            return name.substr(0, 11) === 'wbAnalytics' && ['On', 'Event', 'If', 'Properties', 'EventType', 'VatNumber'].indexOf(name.substr(11)) === -1;
          }
          , propertyName = function(name) {
            var s = name.slice(11); // slice off the 'wbAnalytics' prefix
            if (typeof s !== 'undefined' && s !== null && s.length > 0) {
              return s.substring(0, 1).toLowerCase() + s.substring(1);
            } else {
              return s;
            }
          };

        angular.forEach($attrs.$attr, function(attr, name) {
          if (isProperty(name)) {
            trackingData[propertyName(name)] = $attrs[name];
            $attrs.$observe(name, function(value){
              trackingData[propertyName(name)] = value;
            });
          }
        });

        angular.element($element[0]).bind(eventType, function ($event) {
          trackingData.action = $attrs.wbAnalyticsEvent;

          if (!trackingData.vatNumber){
            trackingData.vatNumber = company.vatNumber;
          }

          if($attrs.wbAnalyticsIf){
            if(! $scope.$eval($attrs.wbAnalyticsIf)){
              return; // Cancel this event if we don't pass the wb-analytics-if condition
            }
          }

          WbAnalytics.save(trackingData);
        });
      }
    };
  }
}());
