(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name wbBanner.filter:wbUrlDecode
   *
   * @description Used to fix the new type of urls that comes from the API
   *
   * @param {string} input The link string
   * @returns {string} The fixed link string
   *
   */
  angular
    .module('wbBanner')
    .filter('wbUrlDecode', wbUrlDecode);

  function wbUrlDecode() {
    return function (input) {
      return input ? input.replace(/&amp;/, '&') : '';
    };
  }
}());
