/* eslint "angular/ng_di":0 */

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name country.constant:countries
   *
   * @description
   *
   */
  angular
    .module('wbCountry')
    .constant('countries', [
      {name: 'Afganiszt\u00e1n', alpha3: 'AFG'},
      {name: '\u00c5land', alpha3: 'ALA'},
      {name: 'Alb\u00e1nia', alpha3: 'ALB'},
      {name: 'Alg\u00e9ria', alpha3: 'DZA'},
      {name: 'Amerikai Csendes-\u00f3ce\u00e1ni szigetek', alpha3: 'UMI'},
      {name: 'Amerikai Egyes\u00fclt \u00c1llamok', alpha3: 'USA'},
      {name: 'Amerikai Szamoa', alpha3: 'ASM'},
      {name: 'Amerikai Virgin-szigetek', alpha3: 'VIR'},
      {name: 'Andorra', alpha3: 'AND'},
      {name: 'Angola', alpha3: 'AGO'},
      {name: 'Anguilla', alpha3: 'AIA'},
      {name: 'Antarktisz', alpha3: 'ATA'},
      {name: 'Antigua \u00e9s Barbuda', alpha3: 'ATG'},
      {name: 'Argent\u00edna', alpha3: 'ARG'},
      {name: 'Aruba', alpha3: 'ABW'},
      {name: 'Ausztr\u00e1lia', alpha3: 'AUS'},
      {name: 'Ausztria', alpha3: 'AUT'},
      {name: 'Azerbajdzs\u00e1n', alpha3: 'AZE'},
      {name: 'Bahama-szigetek', alpha3: 'BHS'},
      {name: 'Bahrein', alpha3: 'BHR'},
      {name: 'Banglades', alpha3: 'BGD'},
      {name: 'Barbados', alpha3: 'BRB'},
      {name: 'Belgium', alpha3: 'BEL'},
      {name: 'Belize', alpha3: 'BLZ'},
      {name: 'Benin', alpha3: 'BEN'},
      {name: 'Bermuda', alpha3: 'BMU'},
      {name: 'Bhut\u00e1n', alpha3: 'BTN'},
      {name: 'Bissau-Guinea', alpha3: 'GNB'},
      {name: 'Bol\u00edvia', alpha3: 'BOL'},
      {name: 'Bosznia-Hercegovina', alpha3: 'BIH'},
      {name: 'Botswana', alpha3: 'BWA'},
      {name: 'Bouvet-sziget', alpha3: 'BVT'},
      {name: 'Braz\u00edlia', alpha3: 'BRA'},
      {name: 'Brit Indiai-\u00f3ce\u00e1ni Ter\u00fclet', alpha3: 'IOT'},
      {name: 'Brit Virgin-szigetek', alpha3: 'VGB'},
      {name: 'Brunei', alpha3: 'BRN'},
      {name: 'Bulg\u00e1ria', alpha3: 'BGR'},
      {name: 'Burkina Faso', alpha3: 'BFA'},
      {name: 'Burundi', alpha3: 'BDI'},
      {name: 'Chile', alpha3: 'CHL'},
      {name: 'Ciprus', alpha3: 'CYP'},
      {name: 'Comore-szigetek', alpha3: 'COM'},
      {name: 'Cook-szigetek', alpha3: 'COK'},
      {name: 'Costa Rica', alpha3: 'CRI'},
      {name: 'Cura\u00e7ao', alpha3: 'CUW'},
      {name: 'Cs\u00e1d', alpha3: 'TCD'},
      {name: 'Csehorsz\u00e1g', alpha3: 'CZE'},
      {name: 'D\u00e1nia', alpha3: 'DNK'},
      {name: 'D\u00e9l-afrikai K\u00f6zt\u00e1rsas\u00e1g', alpha3: 'ZAF'},
      {name: 'D\u00e9li-Georgia \u00e9s D\u00e9li-Sandwich-szigetek', alpha3: 'SGS'},
      {name: 'D\u00e9l-Szud\u00e1n', alpha3: 'SSD'},
      {name: 'Koreai K\u00f6zt\u00e1rsas\u00e1g', alpha3: 'KOR'},
      {name: 'Dominikai K\u00f6z\u00f6ss\u00e9g', alpha3: 'DMA'},
      {name: 'Dominikai K\u00f6zt\u00e1rsas\u00e1g', alpha3: 'DOM'},
      {name: 'Dzsibuti', alpha3: 'DJI'},
      {name: 'Ecuador', alpha3: 'ECU'},
      {name: 'Egyenl\u00edt\u0151i-Guinea', alpha3: 'GNQ'},
      {name: 'Egyes\u00fclt Arab Em\u00edrs\u00e9gek', alpha3: 'ARE'},
      {name: 'Egyes\u00fclt Kir\u00e1lys\u00e1g', alpha3: 'GBR'},
      {name: 'Egyiptom', alpha3: 'EGY'},
      {name: 'Elef\u00e1ntcsontpart', alpha3: 'CIV'},
      {name: 'El Salvador', alpha3: 'SLV'},
      {name: 'Eritrea', alpha3: 'ERI'},
      {name: '\u00c9szaki-Mariana-szigetek', alpha3: 'MNP'},
      {name: 'Koreai N\u00e9pi Demokratikus K\u00f6zt\u00e1rsas\u00e1g', alpha3: 'PRK'},
      {name: '\u00c9sztorsz\u00e1g', alpha3: 'EST'},
      {name: 'Eti\u00f3pia', alpha3: 'ETH'},
      {name: 'Falkland-szigetek', alpha3: 'FLK'},
      {name: 'Feh\u00e9roroszorsz\u00e1g', alpha3: 'BLR'},
      {name: 'Fer\u00f6er', alpha3: 'FRO'},
      {name: 'Fidzsi', alpha3: 'FJI'},
      {name: 'Finnorsz\u00e1g', alpha3: 'FIN'},
      {name: 'Francia d\u00e9li \u00e9s antarktiszi ter\u00fcletek', alpha3: 'ATF'},
      {name: 'Francia Guyana', alpha3: 'GUF'},
      {name: 'Franciaorsz\u00e1g', alpha3: 'FRA'},
      {name: 'Francia Polin\u00e9zia', alpha3: 'PYF'},
      {name: 'F\u00fcl\u00f6p-szigetek', alpha3: 'PHL'},
      {name: 'Gabon', alpha3: 'GAB'},
      {name: 'Gambia', alpha3: 'GMB'},
      {name: 'Gh\u00e1na', alpha3: 'GHA'},
      {name: 'Gibralt\u00e1r', alpha3: 'GIB'},
      {name: 'G\u00f6r\u00f6gorsz\u00e1g', alpha3: 'GRC'},
      {name: 'Grenada', alpha3: 'GRD'},
      {name: 'Gr\u00f6nland', alpha3: 'GRL'},
      {name: 'Gr\u00fazia', alpha3: 'GEO'},
      {name: 'Guadeloupe', alpha3: 'GLP'},
      {name: 'Guam', alpha3: 'GUM'},
      {name: 'Guatemala', alpha3: 'GTM'},
      {name: 'Guernsey', alpha3: 'GGY'},
      {name: 'Guinea', alpha3: 'GIN'},
      {name: 'Guyana', alpha3: 'GUY'},
      {name: 'Haiti', alpha3: 'HTI'},
      {name: 'Heard-sziget \u00e9s McDonald-szigetek', alpha3: 'HMD'},
      {name: 'Hollandia', alpha3: 'NLD'},
      {name: 'Honduras', alpha3: 'HND'},
      {name: 'Hongkong', alpha3: 'HKG'},
      {name: 'Horv\u00e1torsz\u00e1g', alpha3: 'HRV'},
      {name: 'India', alpha3: 'IND'},
      {name: 'Indon\u00e9zia', alpha3: 'IDN'},
      {name: 'Irak', alpha3: 'IRQ'},
      {name: 'Ir\u00e1n', alpha3: 'IRN'},
      {name: '\u00cdrorsz\u00e1g', alpha3: 'IRL'},
      {name: 'Izland', alpha3: 'ISL'},
      {name: 'Izrael', alpha3: 'ISR'},
      {name: 'Jamaica', alpha3: 'JAM'},
      {name: 'Jap\u00e1n', alpha3: 'JPN'},
      {name: 'Jemen', alpha3: 'YEM'},
      {name: 'Jersey', alpha3: 'JEY'},
      {name: 'Jord\u00e1nia', alpha3: 'JOR'},
      {name: 'Kajm\u00e1n-szigetek', alpha3: 'CYM'},
      {name: 'Kambodzsa', alpha3: 'KHM'},
      {name: 'Kamerun', alpha3: 'CMR'},
      {name: 'Kanada', alpha3: 'CAN'},
      {name: 'Kar\u00e1csony-sziget', alpha3: 'CXR'},
      {name: 'Karibi Hollandia', alpha3: 'BES'},
      {name: 'Katar', alpha3: 'QAT'},
      {name: 'Kazahszt\u00e1n', alpha3: 'KAZ'},
      {name: 'Kelet-Timor', alpha3: 'TLS'},
      {name: 'Kenya', alpha3: 'KEN'},
      {name: 'K\u00edna', alpha3: 'CHN'},
      {name: 'Kirgiziszt\u00e1n', alpha3: 'KGZ'},
      {name: 'Kiribati', alpha3: 'KIR'},
      {name: 'K\u00f3kusz (Keeling)-szigetek', alpha3: 'CCK'},
      {name: 'Kolumbia', alpha3: 'COL'},
      {name: 'Kong\u00f3i Demokratikus K\u00f6zt\u00e1rsas\u00e1g', alpha3: 'COD'},
      {name: 'Kong\u00f3i K\u00f6zt\u00e1rsas\u00e1g', alpha3: 'COG'},
      {name: 'Koszov\u00f3', alpha3: 'XKX'},
      {name: 'K\u00f6z\u00e9p-Afrika', alpha3: 'CAF'},
      {name: 'Kuba', alpha3: 'CUB'},
      {name: 'Kuvait', alpha3: 'KWT'},
      {name: 'Laosz', alpha3: 'LAO'},
      {name: 'Lengyelorsz\u00e1g', alpha3: 'POL'},
      {name: 'Lesotho', alpha3: 'LSO'},
      {name: 'Lettorsz\u00e1g', alpha3: 'LVA'},
      {name: 'Libanon', alpha3: 'LBN'},
      {name: 'Lib\u00e9ria', alpha3: 'LBR'},
      {name: 'L\u00edbia', alpha3: 'LBY'},
      {name: 'Liechtenstein', alpha3: 'LIE'},
      {name: 'Litv\u00e1nia', alpha3: 'LTU'},
      {name: 'Luxemburg', alpha3: 'LUX'},
      {name: 'Maced\u00f3nia', alpha3: 'MKD'},
      {name: 'Madagaszk\u00e1r', alpha3: 'MDG'},
      {name: 'Magyarorsz\u00e1g', alpha3: 'HUN'},
      {name: 'Maka\u00f3', alpha3: 'MAC'},
      {name: 'Malajzia', alpha3: 'MYS'},
      {name: 'Malawi', alpha3: 'MWI'},
      {name: 'Mald\u00edv-szigetek', alpha3: 'MDV'},
      {name: 'Mali', alpha3: 'MLI'},
      {name: 'M\u00e1lta', alpha3: 'MLT'},
      {name: 'Man', alpha3: 'IMN'},
      {name: 'Marokk\u00f3', alpha3: 'MAR'},
      {name: 'Marshall-szigetek', alpha3: 'MHL'},
      {name: 'Martinique', alpha3: 'MTQ'},
      {name: 'Maurit\u00e1nia', alpha3: 'MRT'},
      {name: 'Mauritius', alpha3: 'MUS'},
      {name: 'Mayotte', alpha3: 'MYT'},
      {name: 'Mexik\u00f3', alpha3: 'MEX'},
      {name: 'Mianmar', alpha3: 'MMR'},
      {name: 'Mikron\u00e9zia', alpha3: 'FSM'},
      {name: 'Moldova', alpha3: 'MDA'},
      {name: 'Monaco', alpha3: 'MCO'},
      {name: 'Mong\u00f3lia', alpha3: 'MNG'},
      {name: 'Montenegr\u00f3', alpha3: 'MNE'},
      {name: 'Montserrat', alpha3: 'MSR'},
      {name: 'Mozambik', alpha3: 'MOZ'},
      {name: 'Nam\u00edbia', alpha3: 'NAM'},
      {name: 'Nauru', alpha3: 'NRU'},
      {name: 'N\u00e9metorsz\u00e1g', alpha3: 'DEU'},
      {name: 'Nep\u00e1l', alpha3: 'NPL'},
      {name: 'Nicaragua', alpha3: 'NIC'},
      {name: 'Niger', alpha3: 'NER'},
      {name: 'Nig\u00e9ria', alpha3: 'NGA'},
      {name: 'Niue', alpha3: 'NIU'},
      {name: 'Norfolk-sziget', alpha3: 'NFK'},
      {name: 'Norv\u00e9gia', alpha3: 'NOR'},
      {name: 'Nyugat-Szahara', alpha3: 'ESH'},
      {name: 'Olaszorsz\u00e1g', alpha3: 'ITA'},
      {name: 'Om\u00e1n', alpha3: 'OMN'},
      {name: 'Oroszorsz\u00e1g', alpha3: 'RUS'},
      {name: '\u00d6rm\u00e9nyorsz\u00e1g', alpha3: 'ARM'},
      {name: 'Pakiszt\u00e1n', alpha3: 'PAK'},
      {name: 'Palau', alpha3: 'PLW'},
      {name: 'Palesztina', alpha3: 'PSE'},
      {name: 'Panama', alpha3: 'PAN'},
      {name: 'P\u00e1pua \u00daj-Guinea', alpha3: 'PNG'},
      {name: 'Paraguay', alpha3: 'PRY'},
      {name: 'Peru', alpha3: 'PER'},
      {name: 'Pitcairn-szigetek', alpha3: 'PCN'},
      {name: 'Portug\u00e1lia', alpha3: 'PRT'},
      {name: 'Puerto Rico', alpha3: 'PRI'},
      {name: 'R\u00e9union', alpha3: 'REU'},
      {name: 'Rom\u00e1nia', alpha3: 'ROU'},
      {name: 'Ruanda', alpha3: 'RWA'},
      {name: 'Saint Barthelemy', alpha3: 'BLM'},
      {name: 'Saint Kitts \u00e9s Nevis', alpha3: 'KNA'},
      {name: 'Saint Lucia', alpha3: 'LCA'},
      {name: 'Saint Martin', alpha3: 'MAF'},
      {name: 'Saint-Pierre \u00e9s Miquelon', alpha3: 'SPM'},
      {name: 'Saint Vincent \u00e9s a Grenadine-szigetek', alpha3: 'VCT'},
      {name: 'Salamon-szigetek', alpha3: 'SLB'},
      {name: 'San Marino', alpha3: 'SMR'},
      {name: 'S\u00e3o Tom\u00e9 \u00e9s Pr\u00edncipe', alpha3: 'STP'},
      {name: 'Seychelle-szigetek', alpha3: 'SYC'},
      {name: 'Sierra Leone', alpha3: 'SLE'},
      {name: 'Sint Maarten', alpha3: 'SXM'},
      {name: 'Spanyolorsz\u00e1g', alpha3: 'ESP'},
      {name: 'Spitzberg\u00e1k \u00e9s Jan Mayen-sziget', alpha3: 'SJM'},
      {name: 'Sr\u00ed Lanka', alpha3: 'LKA'},
      {name: 'Suriname', alpha3: 'SUR'},
      {name: 'Sv\u00e1jc', alpha3: 'CHE'},
      {name: 'Sv\u00e9dorsz\u00e1g', alpha3: 'SWE'},
      {name: 'Szamoa', alpha3: 'WSM'},
      {name: 'Sza\u00fad-Ar\u00e1bia', alpha3: 'SAU'},
      {name: 'Szeneg\u00e1l', alpha3: 'SEN'},
      {name: 'Szent Ilona', alpha3: 'SHN'},
      {name: 'Szerbia', alpha3: 'SRB'},
      {name: 'Szerbia \u00e9s Montenegr\u00f3', alpha3: 'SCG'},
      {name: 'Szingap\u00far', alpha3: 'SGP'},
      {name: 'Sz\u00edria', alpha3: 'SYR'},
      {name: 'Szlov\u00e1kia', alpha3: 'SVK'},
      {name: 'Szlov\u00e9nia', alpha3: 'SVN'},
      {name: 'Szom\u00e1lia', alpha3: 'SOM'},
      {name: 'Szud\u00e1n', alpha3: 'SDN'},
      {name: 'Szv\u00e1zif\u00f6ld', alpha3: 'SWZ'},
      {name: 'T\u00e1dzsikiszt\u00e1n', alpha3: 'TJK'},
      {name: 'Tajvan', alpha3: 'TWN'},
      {name: 'Tanz\u00e1nia', alpha3: 'TZA'},
      {name: 'Thaif\u00f6ld', alpha3: 'THA'},
      {name: 'Togo', alpha3: 'TGO'},
      {name: 'Tokelau', alpha3: 'TKL'},
      {name: 'Tonga', alpha3: 'TON'},
      {name: 'T\u00f6r\u00f6korsz\u00e1g', alpha3: 'TUR'},
      {name: 'Trinidad \u00e9s Tobago', alpha3: 'TTO'},
      {name: 'Tun\u00e9zia', alpha3: 'TUN'},
      {name: 'Turks- \u00e9s Caicos-szigetek', alpha3: 'TCA'},
      {name: 'Tuvalu', alpha3: 'TUV'},
      {name: 'T\u00fcrkmeniszt\u00e1n', alpha3: 'TKM'},
      {name: 'Uganda', alpha3: 'UGA'},
      {name: '\u00daj-Kaled\u00f3nia', alpha3: 'NCL'},
      {name: '\u00daj-Z\u00e9land', alpha3: 'NZL'},
      {name: 'Ukrajna', alpha3: 'UKR'},
      {name: 'Uruguay', alpha3: 'URY'},
      {name: '\u00dczbegiszt\u00e1n', alpha3: 'UZB'},
      {name: 'Vanuatu', alpha3: 'VUT'},
      {name: 'Vatik\u00e1n', alpha3: 'VAT'},
      {name: 'Venezuela', alpha3: 'VEN'},
      {name: 'Vietnam', alpha3: 'VNM'},
      {name: 'Wallis \u00e9s Futuna', alpha3: 'WLF'},
      {name: 'Zambia', alpha3: 'ZMB'},
      {name: 'Zimbabwe', alpha3: 'ZWE'},
      {name: 'Z\u00f6ld-foki-szigetek', alpha3: 'CPV'}
    ]);
}());
