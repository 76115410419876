(function () {
  'use strict';

  angular
    .module('wbErrorHandler')
    .config(config);

  function config($translateProvider) {
    $translateProvider.translations('en', {
      'errorHandler.title': 'Error occurred!',
      'errorHandler.content': 'Please try again...',
      'errorHandler.button.ok': 'Ok',
      'errorHandler.response.5xx.title': 'Failed to connect server',
      'errorHandler.response.5xx.content': 'Please try again...',
      'errorHandler.response.401|3.title': 'Authentication failure',
      'errorHandler.response.401|3.content': 'Please login!'
    });
  }
}());
