(function () {
  'use strict';
  // jscs:disable maximumLineLength

  angular
    .module('wbAccount')
    .config(config);

  function config($translateProvider) {
    $translateProvider.translations('hu', {
      'account.change': 'Profil szerkesztése',
      'account.change.submit': 'Személyes adatok mentése',
      'account.personalData': 'Személyes adatok',
      'account.titleId': 'Megszólítás',
      'account.titleId.1': 'Úr',
      'account.titleId.2': 'Hölgy',
      'account.name': 'Teljes név',
      'account.lastName': 'Vezetéknév',
      'account.firstName': 'Keresztnév',
      'account.contact': 'Elérhetőségek',
      'account.contact.email': 'E-mail',
      'account.contact.phone': 'Telefonszám (opcionális)',
      'account.contact.fax': 'Fax (opcionális)',
      'account.save': 'Mentés',
      'account.updated': 'Adatait elmentettük.',
      'account.company.change': 'Cégadatok szerkesztése',
      'account.company.updated': 'Cége adatait elmentettük.',
      'account.company.companyData': 'Cégadatok',

      'account.company.company.change': 'Cégadatok szerkesztése',
      'account.company.name': 'Cég név',
      'account.company.companyNumber': 'Cégjegyzékszám',
      'account.company.vatNumber': 'Adószám',
      'account.company.bankAccountNumber': 'Bankszámlaszám',
      'account.company.staffCount': 'Munkavállalók száma',
      'account.company.nameOnCard': 'Kártyán szereplő cégnév',
      'account.company.nameOnCard.tooltip': 'A kártyan szereplő név<br/>legfeljebb 21 karakter hosszú lehet.',
      'account.company.contact': 'Elérhetőségek',
      'account.company.contact.invoiceEmail': 'E-mail cím',

      'account.company.address': 'Székhely',
      'account.company.address.zipCode': 'Irányítószám',
      'account.company.address.city': 'Város',
      'account.company.address.address': 'Cím',
      'account.company.address.countryCode': 'Ország',

      'account.company.invoiceAddress': 'Számlázási cím',
      'account.company.invoiceAddress.zipCode': 'Irányítószám',
      'account.company.invoiceAddress.city': 'Város',
      'account.company.invoiceAddress.address': 'Cím',
      'account.company.invoiceAddress.countryCode': 'Ország',

      'account.company.deliveryName': 'Szállítási név',
      'account.company.deliveryName.lastName': 'Átvevő vezetékneve',
      'account.company.deliveryName.firstName': 'Átvevő keresztneve',
      'account.company.deliveryAddress': 'Szállítási cím',
      'account.company.deliveryAddress.zipCode': 'Irányítószám',
      'account.company.deliveryAddress.city': 'Város',
      'account.company.deliveryAddress.address': 'Cím',
      'account.company.deliveryAddress.countryCode': 'Ország',

      'account.company.owner': 'Tulajdonos adatai',
      'account.company.owner.titleId': 'Megszólítás',
      'account.company.owner.name': 'Cégtulajdonos neve',
      'account.company.owner.birthName': 'Születéskori név (ha eltér)',
      'account.company.owner.post': 'Beosztás',
      'account.company.owner.nationality': 'Állampolgárság',
      'account.company.owner.IDType': 'Azonosító okmány típusa',
      'account.company.owner.IDNumber': 'Okmány száma',

      'account.IDType.1': 'Személyi igazolvány',
      'account.IDType.2': 'Útlevél',
      'account.IDType.3': 'Vezetői engedély',

      'account.company.owner.isForeign': 'Külföldi állampolgár',
      'account.company.owner.phone': 'Telefonszám (opcionális)',
      'account.company.owner.fax': 'Fax (opcionális)',
      'account.company.owner.email': 'E-mail cím (opcionális)',

      'account.company.owner.address': 'Tulajdonos lakcíme',
      'account.company.owner.hungarianAddress': 'Magyarországi cím',

      'account.company.save': 'Mentés',
      'account.orderBySumValue.name': 'Új rendelés boríték alapján',
      'account.orderByFaceValue.name': 'Új rendelés névérték alapján',
      'account.orderBySumValue.legend': 'Curabitur aliquet quam id dui posuere blandit. Sed porttitor lectus nibh. Donec sollicitudin molestie malesuada.',
      'account.orderByFaceValue.legend': 'Curabitur aliquet quam id dui posuere blandit. Sed porttitor lectus nibh. Donec sollicitudin molestie malesuada.',
      'account.more': 'Tovább',

      'account.company.openAlert': 'Tisztelt Ügyfelünk!<br><br>Felhívjuk szíves figyelmét, hogy az adatmódosítás 24 órán belül kerül frissítésre a rendszereinkben. Amennyiben cégnevet/adószámot módosít, akkor ahhoz új szerződés adatlap szükséges, emiatt kérjük vegye fel velünk a kapcsolatot a 06-1/413-333-as központi telefonszámon!<br><br>Köszönettel: Edenred csapata',

      'account.company.noCompany': 'Felhasználójához nem sikerült céget találnunk.<br><br>A hibáról munkatársaink értesültek, jelentkezni fognak az Ön által megadott e-mail címen ({{ email }}).<br><br>Megértését köszönjük.'
    });
  }
}());
