(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbBanner.factory:Banner
   *
   * @description
   *
   */
  angular
    .module('wbBanner')
    .factory('Banner', Banner);

  function Banner(wbResource) {
    var url = 'banners'
      , BannerBase = wbResource('banner', url + ':id', {id: '@id'});

    return BannerBase;
  }
}());
