(function () {
  'use strict';

  angular
    .module('wbNavigation')
    .config(config);

  function config($translateProvider) {
    $translateProvider.translations('hu', {
      'navigation.calculator': 'Kalkulátor',
      'navigation.onlineCardManagement': 'Online Ügyintézés',
      'navigation.order': 'Új rendelés',
      'navigation.orders': 'Előző rendelések',
      'navigation.prints': 'Dokumentumok',
      'navigation.login': 'Belépés'
    });
  }
}());
