<md-card>
  <md-toolbar class="md-whiteframe-z1 wb-toolbar-default">
    <div class="md-toolbar-tools">
      {{::'order.thankYou' | translate}}
    </div>
  </md-toolbar>

  <md-card-content class="wb-order-success text-center" layout="column">
    <div>
      <h2>{{::'order.success.furtherSteps' | translate}}</h2>

      <div class="wb-success-steps" layout="row" layout-align="space-around start">
        <div class="wb-success-step" flex="30" layout-padding ng-repeat-start="step in success.steps">
          <md-icon md-font-icon="wb-success-step-icon {{::step.colorClass}} zmdi zmdi-{{::step.icon}} zmdi-hc-lg zmdi-hc-fw"></md-icon>
          <h3>{{::step.title}}</h3>

          <p ng-bind-html="::step.legend"></p>
        </div>

        <div class="wb-success-mark" flex="5" ng-if="!$last" ng-repeat-end>
          <span class="zmdi-hc-stack zmdi-hc-lg">
            <i class="zmdi zmdi-circle zmdi-hc-stack-2x"></i>
            <i class="zmdi zmdi-chevron-right zmdi-hc-stack-1x zmdi-hc-inverse"></i>
          </span>
        </div>
      </div>
    </div>

    <div layout="row" layout-align="end center">
      <md-button class="md-raised" type="button" ui-sref="orders">
        {{::'order.backToHome' | translate}}
      </md-button>
    </div>
  </md-card-content>
</md-card>
