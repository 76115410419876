(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbOrder.controller:InvoiceCtrl
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .controller('InvoiceCtrl', InvoiceCtrl);

  function InvoiceCtrl($scope,
                       $mdDialog,
                       $document,
                       $rootScope,
                       $state,
                       OrderService,
                       FormControl,
                       $translate,
                       _) {
    var vm = this
      , formControl = new FormControl();

    vm.order = OrderService.getOrder();

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.prevStep = function prevStep() {
      $scope.$emit('order.prev', 'itemList');
    };

    vm.calculateItemSum = function calculateItemSum($index) {
      var item = vm.order.items[$index]
        , sum = item.unitQuantity * item.unit_amount;

      return sum;
    };

    vm.calculateTotal = function calculateTotal() {
      return _.reduce(vm.order.items, function (acc, item) {
        return acc + (item.unitQuantity * item.unit_amount);
      }, 0);
    };

    vm.isLargeOrder = function isLargeOrder() {
      return vm.calculateTotal() > 4000000 || getSumOfOrderedItems() > 400;
    };

    vm.submit = function submit() {
      formControl.process(OrderService.submitOrder())
        .then(function () {
          $scope.$emit('order.next', 'success');
        })
        .catch(function () {
          $mdDialog.show(
            $mdDialog.alert()
              .title($translate.instant('order.invoice.failed.title'))
              .content($translate.instant('order.invoice.failed.content'))
              .ok($translate.instant('order.invoice.failed.close'))
          );
        });
    };

    function getSumOfOrderedItems() {
      return _.reduce(vm.order.items, function (acc, item) {
        return acc + item.unitQuantity;
      }, 0);
    };
  }
}());
