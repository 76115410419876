<md-card>
  <md-toolbar class="md-whiteframe-z1 wb-toolbar-default">
    <div class="md-toolbar-tools">
      {{::'order.intro.title' | translate}}
    </div>
  </md-toolbar>

  <md-card-content>
    <form name="intro.introForm"
          ng-submit="intro.submit()"
          novalidate
          method="post">
      <div class="wb-additional-information" layout="row" layout-wrap>
        <div class="item" flex="50" layout="row" layout-align="start start">
          <div class="icon-wrapper" flex>
            <md-icon md-font-icon="zmdi zmdi-pin zmdi-hc-lg zmdi-hc-fw"></md-icon>
          </div>
          <div flex>
            <h3>{{::'order.shippingAddress' | translate}}</h3>

            <p>
              <strong><span ng-if="intro.order.address.departmentName">{{intro.order.address.departmentName}} </span>{{intro.order.address.contactName}}</strong><br>
              {{intro.order.address.address.zip}}
              {{intro.order.address.address.city}},
              {{intro.order.address.address.address}}
            </p>

            <md-button class="md-button md-default md-raised no-side-margin" type="button"
                       ng-click="intro.openAddressDialog()">
              {{::'order.intro.chooseAddress' | translate}}
            </md-button>
          </div>
        </div>

        <div class="item" flex="50" layout="row" layout-align="start start">
          <div class="icon-wrapper" flex>
            <md-icon md-font-icon="zmdi zmdi-time zmdi-hc-lg zmdi-hc-fw"></md-icon>
          </div>
          <div flex>
            <h3>{{::'order.shippingTime' | translate}}</h3>

            <p>{{::'order.shippingTime.legend' | translate}}</p>

            <md-input-container>
              <label>{{::'order.shippingTime' | translate}}</label>
              <md-select class="wb-intro-shipping-time"
                         ng-model="intro.order.deliveryDate"
                         name="deliveryDays"
                         required>
                <md-option
                  ng-repeat="deliveryDay in intro.deliveryDays"
                  ng-value="deliveryDay">
                  {{::deliveryDay | moment : 'YYYY. MMMM D.'}}
                </md-option>
              </md-select>
              <wb-messages form="intro.introForm" server="intro.getServerValidation()"
                           field-name="deliveryDays"></wb-messages>
            </md-input-container>
          </div>
        </div>

        <div class="wb-vertical-spacer large"></div>

        <div class="item" flex="50" layout="row" layout-align="start start">
          <div class="icon-wrapper" flex>
            <md-icon md-svg-src="images/nevertek-cimlet.svg"></md-icon>
          </div>
          <div flex>
            <h3>{{::'order.intro.productType' | translate}}</h3>

            <md-input-container flex="34" class="wb-intro-product-type">
              <label>{{::'order.intro.productType.label' | translate}}</label>
              <md-select ng-model="intro.order.voucherId" ng-change="intro.updateShowCardConsent()"
                         name="productType"
                         required
                         ng-disabled="intro.isCalculatedOrder">
                <md-option
                  ng-repeat="productType in intro.productTypes"
                  ng-value="productType.id">
                  {{::productType.label}}
                </md-option>
              </md-select>
              <wb-messages form="intro.introForm" server="intro.getServerValidation()"
                           field-name="productType"></wb-messages>
            </md-input-container>
          </div>
        </div>
      </div>

      <div flex layout="row" layout-align="end center">
        <md-checkbox
          ng-if="intro.showConsent"
          class="wb-intro-consent"
          name="intro.cardConsent"
          ng-model="intro.cardConsent"
          aria-label="{{::'order.intro.cardConsent' | translate}}"
          ng-required>
          {{::'order.intro.cardConsent' | translate}}
          <wb-messages form="companyForm"
                       server="company.getServerValidation()"
                       field-name="cardConsent"></wb-messages>
        </md-checkbox>
        <md-button class="md-raised md-primary no-side-margin" type="submit" ng-hide="intro.isProcessing"
                   ng-disabled="intro.showConsent && !intro.cardConsent">
          {{::'order.next' | translate}}
        </md-button>
        <div class="button-loader-wrapper" ng-show="intro.isProcessing">
          <md-progress-circular class="md-hue-2 button-loader" md-diameter="36px"
                                md-mode="indeterminate"></md-progress-circular>
        </div>
      </div>
    </form>
  </md-card-content>
</md-card>
