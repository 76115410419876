(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbAccount.factory:User
   *
   * @description
   *
   */
  angular
    .module('wbAccount')
    .factory('User', User);

  function User(wbResource, Company) {
    var url = 'users/'
      , UserBase = wbResource('user', url + ':id', {id: '@id'},
        {
          get: {
            method: 'GET',
            cache: false
          },
          query: {
            method: 'GET',
            cache: false
          },
          changePassword: {
            method: 'PUT',
            params: {id: '@id'},
            cache: false,
            url: url + ':id/password_change'
          },
          sendPasswordResetMail: {
            method: 'POST',
            url: 'send_password_reset_mail'
          },
          resetPassword: {
            method: 'POST',
            url: 'password_reset'
          },
          validateRegistration: {
            method: 'POST',
            url: 'validateRegistration'
          },
          cityNameByZipCode: {
            method: 'GET',
            url: 'cityNameByZipCode/:zip',
            params: {zip: '@zip'},
            cache: true
          }
        });

    UserBase.prototype.getCompany = function getCompany() {
      return Company.getAsSub(url + this.id + '/');
    };

    return UserBase;
  }
}());
