(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name wbForm.directive:wbMessages
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="wbForm">
       <file name="index.html">
          <wb-messages form="formObject" field-name="fieldName"></wb-messages>
       </file>
     </example>
   *
   */
  angular
    .module('wbForm')
    .directive('wbMessages', wbMessages);

  function wbMessages() {
    var templateFolder = 'wb-form/';
    return {
      restrict: 'EA',
      scope: {
        fieldName: '@',
        form: '=',
        server: '='
      },
      templateUrl: templateFolder + 'directives/wb-messages-directive.tpl.html',
      replace: false,
      controllerAs: 'wbMessages',
      bindToController: true,
      controller: function () {
        var vm = this;
        vm.messagesSrc = templateFolder + 'views/wb-messages-include.tpl.html';
      }
    };
  }
}());
