(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbAuth.controller:PasswordChangeCtrl
   *
   * @description
   *
   */
  angular
    .module('wbAuth')
    .controller('PasswordChangeCtrl', PasswordChangeCtrl);

  function PasswordChangeCtrl(User, user, FormControl, $state) {
    var vm = this
      , formControl = new FormControl();

    formControl.setSuccessText('auth.password.change.success');

    vm.passwordChange = {
      id: user.id,
      password: '',
      passwordNew: ''
    };

    vm.user = user;

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.submit = function submit() {
      formControl
        .process(User.changePassword(vm.passwordChange).$promise)
        .then(reloadForm);
    };

    function reloadForm() {
      $state.reload();
    }
  }
}());
