(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbPrints.factory.Prints
   *
   * @description
   *
   */
  angular
    .module('wbPrints')
    .factory('Prints', Prints);

  function Prints(wbResource) {
    var url = 'prints/'
      , xlsxContentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    return wbResource('print', url + ':id', {id: '@id'}, {
      query: {
        method: 'GET',
        cache: false,
        isArray: true
      },
      get: {
        method: 'GET',
        headers: {
          accept: xlsxContentType
        },
        responseType: 'arraybuffer',
        cache: false,
        transformResponse: function (data) {
          return {
            response: new Blob([data], {type: xlsxContentType})
          };
        }
      }
    });
  }
}());
