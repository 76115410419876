(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbAcceptLanguage.provider:AcceptLanguageInterceptor
   *
   * @description
   *
   */
  angular
    .module('wbAcceptLanguage')
    .provider('AcceptLanguageInterceptor', AcceptLanguageInterceptor);

  function AcceptLanguageInterceptor() {
    var primaryLanguage = 'hu'
      , fallbackLanguage;

    return {
      setPrimaryLanguage: setPrimaryLanguage,
      setFallbackLanguage: setFallbackLanguage,
      $get: get
    };

    function get($translate) {
      return {
        request: function ($config) {
          $config.headers['Accept-Language'] = getLanguageValue($translate);
          return $config;
        }
      };
    }

    function setPrimaryLanguage(newPrimaryLanguage) {
      primaryLanguage = newPrimaryLanguage;
    }

    function setFallbackLanguage(newFallbackLanguage) {
      fallbackLanguage = newFallbackLanguage;
    }

    function getLanguageValue($translate) {
      var langValue = (primaryLanguage !== $translate.use()) ? $translate.use() : primaryLanguage;

      if (fallbackLanguage) {
        langValue += ', %1;q=0.5'.replace('%1', fallbackLanguage);
      }

      return langValue;
    }
  }
}());
