(function () {
  'use strict';

  /* @ngdoc object
   * @name wbLoggedUser
   * @description
   *
   */
  angular
    .module('wbLoggedUser', [
      'wbAccount'
    ]);
}());
