(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbErrorHandler.factory:ResponseHandler
   *
   * @description
   *
   */
  angular
    .module('wbErrorHandler')
    .factory('ResponseHandler', ResponseHandler);

  function ResponseHandler(_, $injector) {
    function ResponseHandlerBase(rawResponseHandler) {
      rawResponseHandler = rawResponseHandler || {};
      this.message = rawResponseHandler.message;
      this.weight = rawResponseHandler.weight;
      this.code = rawResponseHandler.code;
      this.header = rawResponseHandler.header;
      this.action = rawResponseHandler.action ? rawResponseHandler.action.bind(this, $injector) : angular.noop;
    }

    ResponseHandlerBase.prototype = {};

    ResponseHandlerBase.prototype.isResponsive = function (response) {
      var headers;

      if (_.isRegExp(this.code)) {
        return response.status.toString().match(this.code);
      }

      if (_.isObject(this.header)) {
        headers = response.headers();
        return _.has(headers, this.header.key) && headers[this.header.key] === this.header.value;
      }

      return false;
    };

    return ResponseHandlerBase;
  }
}());
