(function () {
  'use strict';
  // jscs:disable maximumLineLength

  angular
    .module('wbAccount')
    .config(config);

  function config($translateProvider) {
    $translateProvider.translations('hu', {
      'prints.title': 'Dokumentumok',
      'prints.date': 'Dátum',
      'prints.file': 'Fájl',
      'prints.download': 'Letöltés'
    });
  }
}());
