(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name wbInfo.directive:wbContact
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="wbInfo">
       <file name="index.html">
        <wb-contact></wb-contact>
       </file>
     </example>
   *
   */
  angular
    .module('wbInfo')
    .directive('wbContact', wbContact);

  function wbContact() {
    return {
      restrict: 'E',
      scope: {},
      templateUrl: 'wb-info/directives/wb-contact-directive.tpl.html',
      replace: false
    };
  }
}());
