(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name wbForm.directive:maskFix
   * @restrict A
   *
   * @description
   *
   * @example
     <example module="wbForm">
       <file name="index.html">
        <div mask-fix></div>
       </file>
     </example>
   *
   */
  angular
    .module('wbForm')
    .directive('maskFix', maskFix);

  function maskFix() {
    return {
      restrict: 'A',
      require: '^?mdInputContainer'
    };
  }
}());
