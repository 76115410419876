(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name wbOrder.directive:wbOrderLoader
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="wbOrder">
   <file name="index.html">
   <wb-order-loader></wb-order-loader>
   </file>
   </example>
   *
   */
  angular
    .module('wbOrder')
    .directive('wbOrderLoader', wbOrderLoader);

  function wbOrderLoader() {
    return {
      restrict: 'E',
      templateUrl: 'wb-order/directives/wb-order-loader-directive.tpl.html',
      replace: false,
      controllerAs: 'wbOrderLoader',
      controller: function (OrderService) {
        var vm = this;

        vm.isProcessing = function isProcessing() {
          return OrderService.getInProgress();
        };
      }
    };
  }
}());
