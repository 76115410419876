(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name wbOrder.filter:orderStatusClassName
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('wbOrder')
    .filter('orderStatusClassName', orderStatusClassName);

  function orderStatusClassName() {
    return function (order, colorize) {
      var classNames = [];

      if (order.status) {
        classNames.push('status-' + order.status);
      }

      if (order.type) {
        classNames.push('type-' + order.type);
      }

      if (colorize) {
        switch (order.type) {
          case 1:
            classNames.push('md-accent');
            break;
          case 2:
            classNames.push('md-primary');
            break;
          default :
            break;
        }
      }

      switch (order.type) {
        case 1:
          classNames.push('zmdi-shopping-cart');
          break;
        case 2:
          classNames.push('zmdi-trending-up');
          break;
        default :
          break;
      }

      return getClassName();

      function getClassName() {
        return classNames.join(' ');
      }
    };
  }
}());
