(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbForm.factory:FormControl
   *
   * @description
   *
   */
  angular
    .module('wbForm')
    .factory('FormControl', FormControl);

  function FormControl($translate, $mdToast) {
    function FormControlBase() {
      var processing = false
        , serverValidation = {}
        , successText = '';

      this.isProcessing = function isProcessing() {
        return processing;
      };

      this.process = function process(promise) {
        setProcessing();

        promise.then(onSuccess)
          .then(clearValidation)
          .catch(onFail)
          .finally(clearProcessing);

        return promise;
      };

      this.getServerValidation = function getServerValidation() {
        return serverValidation;
      };

      this.setSuccessText = function setSuccessText(newSuccessText) {
        successText = $translate.instant(newSuccessText);
      };

      function onSuccess() {
        if (successText) {
          showToast(successText);
        }
      }

      function showToast(content) {
        $mdToast.show(
          $mdToast.simple()
            .content(content)
            .position('bottom right')
        );
      }

      function onFail(response) {
        if (response.status === 400) {
          serverValidation = response.data.error;
        }
      }

      function clearProcessing() {
        processing = false;
      }

      function setProcessing() {
        processing = true;
      }

      function clearValidation() {
        serverValidation = {};
      }
    }

    return FormControlBase;
  }
}());
