(function () {
  'use strict';
  // jscs:disable maximumLineLength

  angular
    .module('wbGdpr')
    .config(config);

  function config($translateProvider) {
    $translateProvider.translations('hu', {
      'gdpr.privacyAlert.content': 'Tájékoztatjuk, hogy a honlapon ún. sütiket (cookie-kat) használunk, amelyeknek célja, hogy anonimizált formában látogatottsági információkat gyűjtsön, valamint elképzelhető, hogy bizonyos szolgáltatások nélkülük nem lennének elérhetőek. Egyes cookie-k amelyet használunk harmadik személyek számára gyűjtenek adatokat, amelyeket arra használnak, hogy olyan reklámokat jelenítsünk meg mi és harmadik személyek, amelyek relevánsak Önnek. A sütikről, valamint ezek engedélyezéséről és tiltásáról az <a href="https://adatvedelem.edenred.hu/adatkezelesi-tajekoztato/" target="_blank" rel="noopener noreferrer">Cookie / Süti Tájékoztatóban</a> talál részletes tájékoztatást, vagy a sütiket Ön is bármikor beállíthatja.',
      'gdpr.privacyAlert.agree': 'Elfogadom',
      'gdpr.privacyAlert.preferences': 'Beállítások',
      'gdpr.privacyPreferences.title': 'Adatvédelmi beállítások',
      'gdpr.privacyPreferences.disclaimer': 'Hozzájárulások',
      'gdpr.privacyPreferences.disclaimer.text': '<p>Ezen a panelen állíthatja be a következőket:<br/>- engedélyek a weboldal bizonyos funkcióinak eléréséhez<br/>- cookie-k engedélyezése/letiltása típusonként<br/><br/>A korábbi beállításokat bármikor módosíthatja a jogosultságkezelés oldalon.</p><p><a href="https://adatvedelem.edenred.hu/adatkezelesi-tajekoztato/" target="_blank" rel="noopener noreferrer">Adatkezelési tájékoztató</a></a></p>',
      'gdpr.privacyPreferences.performanceCookies': 'Funkció és teljesítmény sütik',
      'gdpr.privacyPreferences.performanceCookies.text': '<p>A Funkcionális cookie-k lehetővé teszi a honlapunknak a már megadott részletek elmentérését és ezen adatok alapján tökéletesített és személyre szabottabb funkciókat kínáljunk. Ezen cookie-k csak anonimizált információkat gyűjtenek és tárolnak, tehát ne tudják követni az Ön egyéb honlapokon való mozgását. A teljesítmény cookie-k arról gyűjtenek adatokat, hogy hogyan használják a honlapunkat. Ezeket arra használjuk, hogy jobban megértsük miként használják a honlapunkat, hogy annak tartalmát és funkcióit javítsuk. Kérjük vegye figyelembe, hogy a funkcionális és teljesítmény cookie-k kikapcsolása korlátozhatja a honlapunk bizonyos funkcióinak működését. A honlapunkon a következő Funkció és Teljesítmény sütik használjuk:</p>',
      'gdpr.privacyPreferences.strictlyNecessaryCookies': 'Szükséges sütik',
      'gdpr.privacyPreferences.strictlyNecessaryCookies.text': '<p>Feltétlenül szükséges sütik olyan funkciókat biztosítanak, amelyek nélkül a honlapunk nem használhatók rendeltetés szerűen. Ezen cookie-kat kizárólag mi használjuk, ezért ezek az első fél cookie-jai. A feltétlenül szükséges cookie-k használatához az Ön hozzájárulására nincsen szükség. Ezért a feltétlenül szükséges cookiekat nem lehet be és kikapcsolni. A honlapunkon a következő Feltétlenül szükséges sütiket használjuk:</p>',
      'gdpr.privacyPreferences.consents.analytics': 'Google Analytics',
      'gdpr.privacyPreferences.consents.i18n': 'Nyelvesítés',
      'gdpr.privacyPreferences.consents.authentication': 'Autentikáció',
      'gdpr.privacyPreferences.hide': 'Bezárás',
      'gdpr.privacyPreferences.save': 'Mentés'
    });
  }
}());
