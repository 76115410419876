(function () {
  'use strict';

  angular
    .module('voucherExtranet')
    .config(configApi)
    .config(configTranslate)
    .config(configAcceptHeader)
    .config(configLocalization)
    .config(configNgMaterial)
    .config(configEnvironment)
    .config(configLoadingBar)
    .config(configDebugLevel)
    .config(addSetNextUrlMethodToLocation)
    .config(allowBlobLinkHrefs)
    .config(['uiMask.ConfigProvider', function (uiMaskConfigProvider) {
      uiMaskConfigProvider.maskDefinitions({'8': /\d?/});
      uiMaskConfigProvider.clearOnBlur(false);
    }]);

  function configApi(appConfig, wbResourceProvider) {
    wbResourceProvider.setBaseUrl(appConfig.api.url);
  }

  function configTranslate($translateProvider, appConfig) {
    $translateProvider.preferredLanguage(appConfig.language.default);
    $translateProvider.fallbackLanguage(appConfig.language.fallback);
    $translateProvider.useCookieStorage();
  }

  function configAcceptHeader(AcceptLanguageInterceptorProvider, appConfig, $httpProvider) {
    $httpProvider.defaults.headers.common = {
      'Accept-Language': '',
      Accept: 'application/json'
    };
    AcceptLanguageInterceptorProvider.setPrimaryLanguage(appConfig.language.default);
    AcceptLanguageInterceptorProvider.setFallbackLanguage(appConfig.language.default);
  }

  function configLocalization(tmhDynamicLocaleProvider, appConfig) {
    tmhDynamicLocaleProvider.localeLocationPattern(appConfig.localeLocation);
  }

  function configNgMaterial($mdThemingProvider) {
    $mdThemingProvider
      .theme('wbTheme')
      .primaryPalette('blue', {
        default: '500',
        'hue-1': '300',
        'hue-2': '200',
        'hue-3': '100'
      })
      .accentPalette('cyan', {
        default: '400'
      })
      .warnPalette('red', {
        default: '500'
      })
      .backgroundPalette('grey', {
        default: 'A100',
        'hue-1': '400',
        'hue-2': '300',
        'hue-3': '500'
      });

    $mdThemingProvider.setDefaultTheme('wbTheme');
  }

  function configEnvironment($httpProvider, appConfig) {
    if (appConfig.cors) {
      $httpProvider.defaults.withCredentials = true;
    }
  }

  function configLoadingBar(cfpLoadingBarProvider) {
    cfpLoadingBarProvider.includeSpinner = false;
  }

  function configDebugLevel($compileProvider, $logProvider, appConfig) {
    $compileProvider.debugInfoEnabled(appConfig.debug);
    $logProvider.debugEnabled(appConfig.debug);
  }

  function addSetNextUrlMethodToLocation($provide) {
    $provide.decorator('$location', function ($delegate) {
      $delegate.setNextUrl = function setNextUrl(urlToAdd) {
        $delegate.search('next', encodeURIComponent(urlToAdd));
      };

      return $delegate;
    });
  }

  function allowBlobLinkHrefs($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file|blob):/);
  }
}());
