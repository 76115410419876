(function () {
  'use strict';

  angular
    .module('wbOrder')
    .config(config);

  function config($stateProvider, notForAuthenticated, authenticatedOnly) {
    $stateProvider
      .state('order', {
        url: '/order',
        templateUrl: 'wb-order/views/order.tpl.html',
        controller: 'OrderCtrl',
        controllerAs: 'order',
        restrict: authenticatedOnly,
        resolve: {
          user: function (User, LoggedUser, _) {
            var loggedUser = LoggedUser.getUser();

            return (_.isNull(loggedUser)) ? new User() : loggedUser;
          },
          company: function (Company, LoggedUser, _) {
            var loggedUserCompany = LoggedUser.getCompany();

            return (_.isNull(loggedUserCompany)) ? new Company() : loggedUserCompany;
          },
          isAuthenticated: function (user, company, _) {
            return !_.isEmpty(user) && !_.isEmpty(company);
          },
          backendUnreachable: function () {
            return false;
          }
        }
      })
      .state('invoice', {
        url: '/invoice',
        templateUrl: 'wb-order/views/invoice.tpl.html',
        controller: 'InvoiceCtrl',
        controllerAs: 'invoice',
        parent: 'order',
        restrict: authenticatedOnly
      })
      .state('success', {
        url: '/order/success',
        templateUrl: 'wb-order/views/success.tpl.html',
        controller: 'SuccessCtrl',
        controllerAs: 'success',
        restrict: authenticatedOnly
      })
      .state('itemList', {
        url: '/item-list',
        templateUrl: 'wb-order/views/item-list.tpl.html',
        controller: 'ItemListCtrl',
        controllerAs: 'itemList',
        parent: 'order',
        restrict: authenticatedOnly,
        resolve: {
          items: function (OrderService, Order) {
            var voucherId = OrderService.getOrder().voucherId;

            return Order.getBoxes({voucherId: voucherId}).$promise;
          }
        }
      })
      .state('intro', {
        url: '/intro/:type',
        templateUrl: 'wb-order/views/intro.tpl.html',
        controller: 'IntroCtrl',
        controllerAs: 'intro',
        parent: 'order',
        restrict: authenticatedOnly,
        resolve: {
          addresses: function (Order) {
            return Order.getDeliveryAddresses().$promise;
          },
          deliveryDays: function (Order) {
            return Order.getDeliveryDays().$promise;
          },
          vouchers: function (Order) {
            return Order.getAvailableVouchers().$promise;
          }
        }
      })
      .state('calculator', {
        url: '/calculator',
        templateUrl: 'wb-order/views/calculator.tpl.html',
        controller: 'CalculatorCtrl',
        controllerAs: 'calculator',
        resolve: {
          vouchers: function (Order) {
            return Order.getAvailableVouchersPublic().$promise;
          }
        }
      });
  }
}());
