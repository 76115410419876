<md-dialog class="wb-welcome-dialog">
  <md-dialog-content>
    <h2 ng-if="wbTimedInformationDialog.title" class="md-title">{{::wbTimedInformationDialog.title}}</h2>

    <p ng-bind-html="::wbTimedInformationDialog.description"></p>
  </md-dialog-content>
  <div class="md-actions">
    <md-button ng-click="wbTimedInformationDialog.closeDialog()" class="md-primary">
      {{::'auth.firstLogin.button' | translate}}
    </md-button>
  </div>
</md-dialog>
