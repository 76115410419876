(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbOrder.service:OrderService
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .service('OrderService', OrderService);

  function OrderService(Order, $q) {
    var self = this
      , order = new Order();

    self.getOrder = function getOrder() {
      return order;
    };

    self.setOrder = function setOrder(newOrder) {
      order = newOrder;
    };

    self.updateOrder = function setOrderBoxes(orderDetails) {
      order.voucherId = orderDetails.voucherId;
      order.items = orderDetails.items;
    };

    self.resetOrder = function resetOrder() {
      order = new Order();
    };

    self.submitOrder = function submitOrder() {
      var deferred = $q.defer();

      order.addressId = order.address.id;
      order.departmentCode = order.address.departmentCode;

      order.$save(deferred.resolve, deferred.reject);

      return deferred.promise;
    };
  }
}());
