(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbInfo.factory:CardType
   *
   * @description
   *
   */
  angular
    .module('wbInfo')
    .factory('CardType', CardType);

  function CardType(wbResource) {
    var CardTypeBase = wbResource('cardType', 'card_types');

    return CardTypeBase;
  }
}());
