(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbTimedInformationDialog.controller:WbTimedInformationDialogCtrl
   * @description To handle the dialog
   */
  angular
    .module('wbTimedInformationDialog')
    .controller('WbTimedInformationDialogCtrl', WbTimedInformationDialogCtrl);

  function WbTimedInformationDialogCtrl($mdDialog, wbTimedInformationDialogConstant) {
    var vm = this
      , currentDialog = wbTimedInformationDialogConstant.edenredServiceAlert;

    vm.title = currentDialog.title;
    vm.description = currentDialog.description;

    /**
     * To close the dialog via dom element
     */
    vm.closeDialog = function closeDialog() {
      if (currentDialog.onceOnly) {
        localStorage.setItem(currentDialog.name, 1);
      }
      $mdDialog.hide();
    };
  }
}());
