(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name wbBanner.directive:wbBanners
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="wbBanner">
       <file name="index.html">
        <wb-banners></wb-banners>
       </file>
     </example>
   *
   */
  angular
    .module('wbBanner')
    .directive('wbBanners', wbBanners);

  function wbBanners() {
    return {
      restrict: 'E',
      scope: {},
      templateUrl: 'wb-banner/directives/wb-banners-directive.tpl.html',
      replace: false,
      controllerAs: 'WbBanners',
      controller: function (Banner, _, $interval) {
        var vm = this,
          rotationInterval = 5000;

        vm.bannerGroups = [];

        Banner.query().$promise.then(function (banners) {
          /**
           * Sort banners into separate groups according to position (left or right, but there's
           * room for other positions as well).
           */
          var bannerGroups = banners.reduce(function (collection, banner) {
            if (!_.isArray(collection[banner.position])) {
              collection[banner.position] = [];
            }

            // Add the `active` property to each banner
            banner.active = false;

            collection[banner.position].push(banner);

            return collection;
          }, {});

          // Make sure the left group is the first one in the array
          vm.bannerGroups = [bannerGroups.left, bannerGroups.right];
          _.each(vm.bannerGroups, function (bannerGroup) {
            // Set the first item of each group to active
            bannerGroup[0].active = true;

            // If the banner group only has one item, we don't have to iterate
            if (bannerGroup.length < 2) {
              return;
            }

            $interval(function () {
              // Attempt to find an active banner in the group
              var activeBanner = _.find(bannerGroup, function (banner) {
                  return banner.active;
                }),
                activeIndex = !_.isEmpty(activeBanner) ? bannerGroup.indexOf(activeBanner) : 0;

              // Set the current banner item to inactive
              bannerGroup[activeIndex].active = false;

              // Marks either the next or the first banner item active in the current group
              (bannerGroup[activeIndex + 1] || bannerGroup[0]).active = true;
            }, rotationInterval);
          });
        });
      }
    };
  }
}());
