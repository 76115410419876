<md-card>
  <md-toolbar class="md-whiteframe-z1 wb-toolbar-default">
    <div class="md-toolbar-tools">
      {{::'order.invoice.title' | translate}}
    </div>
  </md-toolbar>

  <md-card-content>
    <form name="invoice.invoiceForm"
          ng-submit="invoice.submit()"
          novalidate
          method="post">

      <h2 class="wb-invoice-section-header">{{::'order.invoice.vouchers' | translate}}</h2>

      <!-- Megrendelni kívánt utalványok -->
      <div class="wb-table wb-invoice-table">
        <div class="wb-table-header" layout="row">
          <span flex="33">{{::'order.invoice.faceValue' | translate}}</span>
          <span flex="33" class="text-center">{{::'order.invoice.quantity' | translate}}</span>
          <span flex="33" class="text-right">{{::'order.invoice.net' | translate}}</span>
        </div>

        <div class="wb-table-item" layout="row" ng-repeat="item in invoice.order.items">
          <span flex="33">{{::item.unit_amount | isoCurrency:'HUF':0}} {{::'order.invoice.valueOfBundle' | translate}}</span>
          <span flex="33" class="text-center">{{::item.unitQuantity}}</span>
          <span flex="33" class="text-right"><strong>{{::invoice.calculateItemSum($index) |
            isoCurrency:'HUF':0}}</strong></span>
        </div>
      </div>

      <div class="wb-invoice-sum" layout="row" layout-align="space-between center">
        <div flex="50">{{::'order.invoice.total' | translate}}</div>
        <div flex="50" class="sum text-right">{{::(invoice.calculateTotal() || 0) | isoCurrency:'HUF':0}}</div>
      </div>

      <p class="wb-invoice-disclaimer">{{::'order.invoice.disclaimer' | translate}}</p>

      <div class="wb-large-order-notice" ng-if="invoice.isLargeOrder()">
        <md-icon md-font-icon="zmdi zmdi-alert-circle-o zmdi-hc-3x"></md-icon>
        <div ng-bind-html="::'order.invoice.largeOrder' | translate"></div>
      </div>

      <div class="wb-additional-information" layout="row">
        <div class="item" flex="50" layout="row" layout-align="start start">
          <div class="icon-wrapper" flex>
            <md-icon md-font-icon="zmdi zmdi-pin zmdi-hc-lg zmdi-hc-fw"></md-icon>
          </div>
          <div flex>
            <h3>{{::'order.shippingAddress' | translate}}</h3>

            <p>
              <strong><span ng-if="invoice.order.address.departmentName">{{::invoice.order.address.departmentName}} </span>{{::invoice.order.address.contactName}}</strong><br>
              {{::invoice.order.address.address.zip}} {{::invoice.order.address.address.city}},
              {{::invoice.order.address.address.address}}
            </p>
          </div>
        </div>

        <div class="item" flex layout="row" layout-align="start start">
          <div class="icon-wrapper" flex>
            <md-icon md-font-icon="zmdi zmdi-time zmdi-hc-lg zmdi-hc-fw"></md-icon>
          </div>
          <div flex>
            <h3>{{::'order.shippingTime' | translate}}</h3>

            <h4>{{::invoice.order.deliveryDate | moment : 'YYYY. MMMM D.'}}</h4>
          </div>
        </div>
      </div>

      <div flex-gt-sm="100" layout="row" layout-align="space-between center">
        <md-button
          type="button"
          class="md-raised md-background no-side-margin"
          ng-click="invoice.prevStep()"
          aria-label="{{::'order.back' | translate}}">
          {{::'order.back' | translate}}
        </md-button>

        <md-button analytics-on="click" analytics-event="kattintas" analytics-category="megrendeles" analytics-if="!order.isAuthenticated" ng-disabled="!invoice.invoiceForm.$valid" ng-hide="invoice.isProcessing()" class="md-raised md-primary no-side-margin" type="submit">
          {{::'order.sendOrder' | translate}}
        </md-button>
        <div class="button-loader-wrapper" ng-show="invoice.isProcessing()">
          <md-progress-circular class="md-hue-2 button-loader" md-diameter="36px"
                                md-mode="indeterminate"></md-progress-circular>
        </div>
      </div>
    </form>
  </md-card-content>
</md-card>
