(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbOrder.factory:Order
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .factory('Order', Order);

  function Order(wbResource) {
    var url = 'orders/';

    return wbResource('order', url, {}, {
      query: {
        method: 'GET',
        cache: false,
        isArray: true
      },
      saveExitData: {
        method: 'POST',
        url: 'saveExitData'
      },
      vip: {
        method: 'POST',
        url: 'users/:id/companies/:companyId/orders/vip',
        params: {
          id: '@id',
          companyId: '@companyId'
        }
      },
      getPendingOrders: {
        url: url + 'pending',
        method: 'GET',
        cache: false,
        isArray: true
      },
      getCompletedOrders: {
        url: url + 'completed',
        method: 'GET',
        cache: false,
        isArray: true
      },
      getDeliveryAddresses: {
        url: 'delivery/addresses',
        method: 'GET',
        cache: false,
        isArray: true
      },
      getDeliveryDays: {
        url: 'delivery/days',
        method: 'GET',
        cache: false,
        isArray: true
      },
      getAvailableVouchers: {
        url: 'vouchers',
        method: 'GET',
        cache: false,
        isArray: true
      },
      getBoxes: {
        url: 'vouchers/:voucherId/boxes',
        method: 'GET',
        cache: false,
        isArray: true,
        params: {
          voucherId: '@voucherId',
        }
      },
      getAvailableVouchersPublic: {
        url: 'public_vouchers',
        method: 'GET',
        cache: false
      }
    });
  }
}());
