(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbGdpr.constant:defaultConsents
   *
   * @description
   *
   */
  angular
    .module('wbGdpr')
    .constant('defaultConsents', [
      {
        name: '_ga',
        type: 'performance',
        mandatory: false,
        allowed: true,
        translatedDescriptionKey: 'gdpr.privacyPreferences.consents.analytics',
        feature: 'Google Analytics'
      },
      {
        name: '_gat',
        type: 'performance',
        mandatory: false,
        allowed: true,
        translatedDescriptionKey: 'gdpr.privacyPreferences.consents.analytics',
        feature: 'Google Analytics'
      },
      {
        name: '_gid',
        type: 'performance',
        mandatory: false,
        allowed: true,
        translatedDescriptionKey: 'gdpr.privacyPreferences.consents.analytics',
        feature: 'Google Analytics'
      },
      {
        name: 'NG_TRANSLATE_LANG_KEY',
        type: 'strictlyNecessary',
        mandatory: true,
        allowed: true,
        translatedDescriptionKey: 'gdpr.privacyPreferences.consents.i18n',
        feature: 'Internationalization'
      },
      {
        name: 'loggedUserId',
        type: 'strictlyNecessary',
        mandatory: true,
        allowed: true,
        translatedDescriptionKey: 'gdpr.privacyPreferences.consents.authentication',
        feature: 'Authentication'
      },
      {
        name: 'loggedUserToken',
        type: 'strictlyNecessary',
        mandatory: true,
        allowed: true,
        translatedDescriptionKey: 'gdpr.privacyPreferences.consents.authentication',
        feature: 'Authentication'
      }
    ]);
}());
