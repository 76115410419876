(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name wbOrder.filter:orderName
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('wbOrder')
    .filter('orderName', orderName);

  function orderName($translate) {
    return function (order) {
      return '#' + order.id + ' - ' + order.itemQuantity + ' db ' + $translate.instant('order.type.' + order.type);
    };
  }
}());
