<div class="wb-alert wb-alert--danger" ng-if="itemList.isFoodVoucherOrder()">
  <div ng-bind-html="::'order.info' | translate"></div>
</div>

<form name="itemList.form" ng-submit="itemList.submit()" novalidate>
  <md-card>
    <md-toolbar class="md-whiteframe-z1 wb-toolbar-default">
      <div class="md-toolbar-tools">
        {{::'order.itemList' | translate}}
      </div>
    </md-toolbar>

    <md-card-content>
      <div class="wb-order-itemlist">
        <div class="wb-order-itemlist-header" layout="row" layout-align="space-between center">
          <div flex="30">{{::'order.itemList.value' | translate}}</div>
          <div flex="20">{{::'order.itemList.contents' | translate}}</div>
          <div flex="20">{{::'order.itemList.quantity' | translate}}</div>
          <div flex="10">{{::'order.itemList.sum' | translate}}</div>
          <div flex></div>
        </div>

        <div class="wb-order-itemlist-content">
          <div class="wb-order-itemlist-item" ng-class="{'wb-order-itemlist-item-active': item.unitQuantity}"
               ng-repeat="item in itemList.order.items track by item.id" layout="row" layout-align="space-between center">
            <div flex="30">
              <md-icon class="wb-order-itemlist-item-icon" md-font-icon="zmdi zmdi-email-open zmdi-hc-lg"></md-icon>
              <span class="wb-order-itemlist-item-value">{{::item.unit_amount | isoCurrency:'HUF':0}}</span>
            </div>

            <div flex="20">
              <strong ng-bind-html="::item.fingerprint"></strong>
            </div>

            <div class="wb-order-itemlist-item-quantity" layout="row" layout-align="center center" flex="20">
              <md-input-container md-no-float>
                <label hide>{{::'order.itemList.unitQuantity' | translate}}</label>
                <input type="number" ng-model="item.unitQuantity" ng-min="0" ng-max="9999" pattern="\d*">
              </md-input-container>

              <span>db</span>
            </div>

            <div flex="10">
              <strong ng-if="itemList.calculateItemSum($index)">{{itemList.calculateItemSum($index) |
                isoCurrency:'HUF':0}}</strong>
            </div>

            <div layout="row" layout-align="end center" flex>
              <md-button class="md-button md-flat md-primary" ng-click="itemList.removeItem($index)"
                         ng-if="item.unitQuantity">
                {{::'order.itemList.delete' | translate}}
              </md-button>
            </div>
          </div>
        </div>
      </div>

      <div class="wb-order-itemlist-total" layout="row" layout-align="space-between center">
        <div flex="30"></div>
        <div flex="20"></div>

        <div class="wb-order-itemlist-total-key" flex="20">{{::'order.itemList.total' | translate}}</div>
        <div class="wb-order-itemlist-total-value" flex="10">{{(itemList.calculateTotal() || 0) | isoCurrency:'HUF':0}}
        </div>

        <div flex></div>
      </div>

      <div layout="row" layout-align="space-between center">
        <md-button
          type="button"
          class="md-raised md-background no-side-margin"
          ng-click="itemList.prevStep()"
          aria-label="{{::'order.back' | translate}}">
          {{::'order.back' | translate}}
        </md-button>

        <md-button class="md-button md-raised md-primary" type="submit"
                   ng-disabled="!itemList.form.$valid || itemList.calculateTotal() < 1">
          {{::'order.itemList.next' | translate}}
        </md-button>
      </div>
    </md-card-content>
  </md-card>
</form>
