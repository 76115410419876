(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbAuth.factory:Session
   *
   * @description
   *
   */
  angular
    .module('wbAuth')
    .factory('Session', Session);

  function Session(wbResource) {
    var url = 'sessions/'
      , SessionBase = wbResource('session', url + ':token', {token: '@token'}, {
        save: {method: 'POST', cache: false},
        delete: {method: 'DELETE', cache: false},
        adminLogin: {
          method: 'POST',
          url: 'sessions-admin/',
          cache: false,
          responseType: 'json'
        }
      });

    return SessionBase;
  }
}());
