<div ng-message="required">{{::'form.validation.required' | translate}}</div>
<div ng-message="email">{{::'form.validation.email' | translate}}</div>
<div ng-message="zero">{{::'form.validation.zero' | translate}}</div>
<div ng-message="minlength">{{::'form.validation.minlength' | translate}}</div>
<div ng-message="maxlength">{{::'form.validation.maxlength' | translate}}</div>
<div ng-message="match">{{::'form.validation.match' | translate}}</div>
<div ng-message="login">{{::'form.validation.login' | translate}}</div>
<div ng-message="string">{{::'form.validation.string' | translate}}</div>
<div ng-message="bool">{{::'form.validation.bool' | translate}}</div>
<div ng-message="date">{{::'form.validation.date' | translate}}</div>
<div ng-message="url">{{::'form.validation.url' | translate}}</div>
<div ng-message="number">{{::'form.validation.number' | translate}}</div>
<div ng-message="pattern">{{::'form.validation.pattern' | translate}}</div>
<div ng-message="min">{{::'form.validation.min' | translate}}</div>
<div ng-message="max">{{::'form.validation.max' | translate}}</div>
<div ng-message="password">{{::'form.validation.password' | translate}}</div>
<div ng-message="emailExists">{{::'form.validation.emailExists' | translate}}</div>
<div ng-message="emailNotExists">{{::'form.validation.emailNotExists' | translate}}</div>
<div ng-message="cardNotExists">{{::'form.validation.cardNotExists' | translate}}</div>
<div ng-message="fullNameMismatch">{{::'form.validation.fullNameMismatch' | translate}}</div>
<div ng-message="passwordResetLimit">{{::'form.validation.passwordResetLimit' | translate}}</div>
<div ng-message="loginLimit">{{::'form.validation.loginLimit' | translate}}</div>
<div ng-message="panMismatch">{{::'form.validation.panMismatch' | translate}}</div>
<div ng-message="confirmationCode">{{::'form.validation.confirmationCode' | translate}}</div>
<div ng-message="passwordMismatch">{{::'form.validation.passwordMismatch' | translate}}</div>
<div ng-message="companyNameExists">{{::'form.validation.companyNameExists' | translate}}</div>
<div ng-message="companyNumberExists">{{::'form.validation.companyNumberExists' | translate}}</div>
<div ng-message="companyVatNumberExists">{{::'form.validation.companyVatNumberExists' | translate}}</div>
<div ng-message="fileExtension">{{::'form.validation.fileExtension' | translate}}</div>
<div ng-message="fileLoad">{{::'form.validation.fileLoad' | translate}}</div>
<div ng-message="fileEmpty">{{::'form.validation.fileEmpty' | translate}}</div>
<div ng-message="fileColumns">{{::'form.validation.fileColumns' | translate}}</div>
<div ng-message="fileData">{{::'form.validation.fileData' | translate}}</div>
<div ng-message="fileTooLarge">{{::'form.validation.fileTooLarge' | translate}}</div>
<div ng-message="fileSize">{{::'form.validation.fileSize' | translate}}</div>
<div ng-message="companySearchLimit">{{::'form.validation.companySearchLimit' | translate}}</div>
<div ng-message="mask">{{::'form.validation.mask' | translate}}</div>
<div ng-message="amountRule">{{::'form.validation.amountRule' | translate}}</div>
<div ng-message="permissionMissingForExtranet">
  {{::'form.validation.permissionMissingForExtranet' | translate}}
</div>
