(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbOrder.factory:OrderItem
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .factory('OrderItem', OrderItem);

  function OrderItem(wbResource) {
    return wbResource('item', 'items/:id', {id: '@id'}, {
      query: {
        method: 'GET',
        cache: false,
        isArray: true
      },
      get: {
        method: 'GET',
        cache: false
      }
    });
  }
}());
