(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name wbNavigation.directive:wbSideMenu
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="wbNavigation">
       <file name="index.html">
        <wb-side-menu></wb-side-menu>
       </file>
     </example>
   *
   */
  angular
    .module('wbNavigation')
    .directive('wbSideMenu', wbSideMenu);

  function wbSideMenu() {
    return {
      restrict: 'E',
      templateUrl: 'wb-navigation/directives/wb-side-menu-directive.tpl.html',
      replace: true,
      controllerAs: 'wbSideMenu',
      controller: function (LoggedUser) {
        var vm = this;

        /**
         * Check if the current user is authenticated and has a selected company
         *
         * @returns {boolean}
         */
        vm.hasLoggedInElements = function hasLoggedInElements() {
          return LoggedUser.getCompany() && LoggedUser.isAuthenticated();
        };

        /**
         * Check if the logged company is a VIP company
         *
         * @returns {boolean}
         */
        vm.isVipCompany = function isVipCompany() {
          var company = LoggedUser.getCompany();

          return company && company.isVIP;
        };

        /**
         * Return the logged company's vat number
         *
         * @returns {string}
         */
        vm.getActiveCompanyVatNumber = function getActiveCompanyVatNumber() {
          return LoggedUser.getCompany().vatNumber;
        };
      }
    };
  }
}());
