<md-card>
  <form name="calculator.form" ng-submit="calculator.submit()" novalidate>
    <md-toolbar class="md-whiteframe-z1 wb-toolbar-default">
      <div class="md-toolbar-tools">
        {{::'order.calculator.calculator.title' | translate}}
      </div>
    </md-toolbar>

    <md-card-content layout="column">
      <md-tabs
        md-no-pagination
        md-dynamic-height
        md-border-bottom
        md-selected="calculator.selectedTabIndex"
      >
        <md-tab
          label="{{::'order.calculator.calculator.monthlyBenefit.title' | translate}}"
        >
          <md-content class="md-padding">
            <div layout="row" layout-align="center center">
              <md-input-container flex="34">
                <label
                  >{{::'order.calculator.calculator.productType' | translate}}</label
                >
                <md-select ng-model="calculator.input[0].productType">
                  <md-option
                    ng-repeat="voucher in calculator.vouchers"
                    ng-value="voucher.id"
                  >
                    {{::voucher.label}}
                  </md-option>
                </md-select>
              </md-input-container>

              <md-input-container flex="28" offset="5">
                <label
                  >{{::'order.calculator.calculator.staffNumber' | translate}}</label
                >
                <input
                  type="number"
                  ng-model="calculator.input[0].staffNumber"
                  name="[0]staffNumber"
                  ng-min="1"
                  required
                />
              </md-input-container>

              <md-input-container flex="28" offset="5">
                <label
                  >{{::'order.calculator.calculator.amountPerPerson' | translate}}</label
                >
                <input
                  type="number"
                  ng-model="calculator.input[0].amountPerPerson"
                  wb-order-amount-rule
                  name="[0]amountPerPerson"
                  ng-min="1"
                  required
                />
                <md-tooltip md-direction="bottom">
                  <span
                    ng-bind-html="::'order.calculator.calculator.amountTooltip' | translate"
                  ></span>
                </md-tooltip>
                <wb-messages
                  form="calculator.form"
                  server="intro.getServerValidation()"
                  field-name="[0]amountPerPerson"
                ></wb-messages>
              </md-input-container>
            </div>
          </md-content>
        </md-tab>

        <md-tab
          label="{{::'order.calculator.calculator.oneShotBenefit.title' | translate}}"
        >
          <md-content class="md-padding">
            <div layout="row" layout-align="center center">
              <md-input-container flex="25">
                <label
                  >{{::'order.calculator.calculator.productType' | translate}}</label
                >
                <md-select ng-model="calculator.input[1].productType">
                  <md-option
                    ng-repeat="voucher in calculator.vouchers"
                    ng-value="voucher.id"
                  >
                    {{::voucher.label}}
                  </md-option>
                </md-select>
              </md-input-container>

              <md-input-container flex="25" offset="5">
                <label
                  >{{::'order.calculator.calculator.staffNumber' | translate}}</label
                >
                <input
                  type="number"
                  ng-model="calculator.input[1].staffNumber"
                  name="[1]staffNumber"
                  ng-min="1"
                  required
                />
              </md-input-container>

              <md-input-container flex="25" offset="5">
                <label
                  >{{::'order.calculator.calculator.amountPerPerson' | translate}}</label
                >
                <input
                  type="number"
                  ng-model="calculator.input[1].amountPerPerson"
                  wb-order-amount-rule
                  name="[1]amountPerPerson"
                  ng-min="1"
                  required
                />
                <md-tooltip md-direction="bottom">
                  <span
                    ng-bind-html="::'order.calculator.calculator.amountTooltip' | translate"
                  ></span>
                </md-tooltip>
                <wb-messages
                  form="calculator.form"
                  server="intro.getServerValidation()"
                  field-name="[1]amountPerPerson"
                ></wb-messages>
              </md-input-container>

              <div flex offset="5">
                <md-button
                  class="md-fab"
                  type="button"
                  ng-click="calculator.createLane()"
                  ng-disabled="calculator.disableCreateLane()"
                >
                  <md-icon md-font-icon="zmdi zmdi-plus zmdi-hc-lg"></md-icon>
                </md-button>
              </div>
            </div>

            <div ng-if="calculator.input[1].lanes.length">
              <div
                class="wb-table wb-calculation-lane-list"
                flex="75"
                offset="30"
              >
                <div class="wb-table-header" layout="row">
                  <span
                    flex
                    >{{::'order.calculator.calculator.staffNumber' | translate}}</span
                  >
                  <span
                    flex
                    >{{::'order.calculator.calculator.amountPerPerson' | translate}}</span
                  >
                  <span flex></span>
                </div>

                <div
                  class="wb-table-item"
                  layout="row"
                  layout-align="start center"
                  ng-repeat="lane in calculator.input[1].lanes"
                >
                  <span flex>{{::lane.staffNumber}}</span>
                  <span
                    flex
                    >{{::lane.amountPerPerson | isoCurrency : 'HUF' : 0}}</span
                  >
                  <span flex class="text-right">
                    <md-button
                      type="button"
                      ng-click="calculator.removeLane($index)"
                    >
                      <md-icon md-font-icon="zmdi zmdi-delete"></md-icon>
                    </md-button>
                  </span>
                </div>
              </div>
            </div>
          </md-content>
        </md-tab>

        <md-tab
          label="{{::'order.calculator.calculator.incentiveBenefit.title' | translate}}"
        >
          <md-content class="md-padding">
            <div layout="row" layout-align="center center">
              <md-input-container flex>
                <label
                  >{{::'order.calculator.calculator.productType' | translate}}</label
                >
                <md-select ng-model="calculator.input[2].productType">
                  <md-option
                    ng-repeat="voucher in calculator.vouchers"
                    ng-value="voucher.id"
                  >
                    {{::voucher.label}}
                  </md-option>
                </md-select>
              </md-input-container>

              <md-input-container flex offset="5">
                <label
                  >{{::'order.calculator.calculator.staffNumber' | translate}}</label
                >
                <input
                  type="number"
                  ng-model="calculator.input[2].staffNumber"
                  name="[2]staffNumber"
                  ng-min="1"
                  required
                />
              </md-input-container>

              <md-input-container flex offset="5">
                <label
                  >{{::'order.calculator.calculator.totalAmount' | translate}}</label
                >
                <input
                  type="number"
                  ng-model="calculator.input[2].amount"
                  name="[2]amount"
                  ng-min="1"
                  wb-order-amount-rule
                  required
                />
                <md-tooltip md-direction="bottom">
                  <span
                    ng-bind-html="::'order.calculator.calculator.amountTooltip' | translate"
                  ></span>
                </md-tooltip>
                <wb-messages
                  form="calculator.form"
                  server="calculator.getServerValidation()"
                  field-name="[2]amount"
                >
                </wb-messages>
              </md-input-container>
            </div>
          </md-content>
        </md-tab>
      </md-tabs>

      <div layout="row" layout-align="end center">
        <md-button
          class="md-raised md-primary"
          type="submit"
          ng-disabled="calculator.disableCalculation()"
        >
          {{::'order.calculator.calculator.submit' | translate}}
        </md-button>
      </div>
    </md-card-content>
  </form>
</md-card>

<md-card>
  <md-toolbar class="md-whiteframe-z1 wb-toolbar-default">
    <div class="md-toolbar-tools">
      {{::'order.calculator.result.title' | translate}}
    </div>
  </md-toolbar>

  <md-card-content layout="column">
    <div class="wb-order-itemlist">
      <div
        class="wb-order-itemlist-header"
        layout="row"
        layout-align="space-between center"
      >
        <div flex>{{::'order.calculator.result.sum' | translate}}</div>
        <div flex="30">
          <md-icon
            class="wb-order-itemlist-header-icon"
            md-font-icon="zmdi zmdi-email-open zmdi-hc-lg zmdi-hc-fw"
          >
          </md-icon>
          {{::'order.calculator.result.boxes' | translate}}
        </div>
        <div flex="30">
          <md-icon
            class="wb-order-itemlist-header-icon"
            md-svg-src="images/nevertek-cimlet.svg"
          ></md-icon>
          {{::'order.calculator.result.fingerprint' | translate}}
        </div>
      </div>

      <div class="wb-order-itemlist-content">
        <div
          class="wb-order-itemlist-item"
          layout="row"
          layout-align="space-between center"
          ng-repeat="result in calculator.results"
        >
          <div flex>
            <span
              class="wb-order-itemlist-item-value"
              >{{::result.totalAmount | isoCurrency:'HUF':0}}</span
            >
          </div>

          <div flex="30">
            <div ng-repeat="(box, pieces) in result.boxes">
              <strong
                ><span>{{::box | isoCurrency:'HUF':0}}</span> :
                <span>{{::pieces}} db</span></strong
              ><br />
            </div>
          </div>

          <div flex="30">
            <div ng-repeat="(composite, pieces) in result.composition">
              <strong
                ><span>{{::composite | isoCurrency:'HUF':0}}</span> :
                <span>{{::pieces}} db</span></strong
              >
            </div>
          </div>
        </div>
        <div
          class="wb-order-itemlist-item wb-order-itemlist-item__combined"
          layout="row"
          layout-align="space-between center"
          ng-repeat="result in calculator.combinedResults"
        >
          <div flex>
            <span
              class="wb-order-itemlist-item-value"
              >{{::result.totalAmount | isoCurrency:'HUF':0}}</span
            >
          </div>

          <div flex="30">
            <div ng-repeat="(box, pieces) in result.boxes">
              <strong
                ><span>{{::box | isoCurrency:'HUF':0}}</span> :
                <span>{{::pieces}} db</span></strong
              ><br />
            </div>
          </div>

          <div flex="30">
            <div ng-repeat="(composite, pieces) in result.composition">
              <strong
                ><span>{{::composite | isoCurrency:'HUF':0}}</span> :
                <span>{{::pieces}} db</span></strong
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <p ng-if="!calculator.isUserLoggedIn()">
      <strong
        >{{::'order.calculator.result.loggedOutNotice' | translate}}</strong
      >
    </p>

    <div
      ng-if="calculator.isUserLoggedIn()"
      layout="row"
      layout-align="end center"
    >
      <md-button
        class="md-raised md-primary"
        type="button"
        ng-click="calculator.createOrder()"
        ng-disabled="calculator.disableOrder()"
      >
        {{::'order.calculator.result.order' | translate}}
      </md-button>
    </div>
  </md-card-content>
</md-card>
