(function () {
  'use strict';

  angular
    .module('wbErrorHandler')
    .config(config);

  function config($translateProvider) {
    $translateProvider.translations('hu', {
      'errorHandler.title': 'Hiba történt!',
      'errorHandler.content': 'Kérjük próbálja újra...',
      'errorHandler.button.ok': 'Ok',
      'errorHandler.response.5xx.title': 'Nem sikerült kapcsolódni a szerverhez',
      'errorHandler.response.5xx.content': 'Kérjük próbálja újra...',
      'errorHandler.response.401|3.title': 'Autentikációs hiba',
      'errorHandler.response.401|3.content': 'Kérjük, jelentkezzen be újra!'
    });
  }
}());
