(function () {
  'use strict';

  /* @ngdoc object
   * @name wbAccount
   * @description
   *
   */
  angular
    .module('wbAccount', [
      'ui.router',
      'pascalprecht.translate',
      'wbResource',
      'wbLoggedUser',
      'wbRestriction',
      'wbAccount.wbCompany'
    ]);
}());
