(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name voucherExtranet.controller:AppCtrl
   *
   * @description
   *
   */
  angular
    .module('voucherExtranet')
    .controller('AppCtrl', AppCtrl);

  function AppCtrl($rootScope, $scope, $translate) {
    var vm = this
      , isResponsiveState = false;

    vm.currentLanguage = $translate.use();

    vm.isTransitioning = function isTransitioning() {
      return $rootScope.appIsTransitioning;
    };

    vm.getContentWidth = function getContentWidth() {
      return isResponsiveState ? 'device-width' : '1200';
    };

    $scope.$on('$stateChangeSuccess', onStateChangeSuccess);

    function onStateChangeSuccess(event, toState) {
      $rootScope.appIsTransitioning = false;
      vm.hasNavigation = (toState.data && toState.data.skipNavigation) ? !toState.data.skipNavigation : true;
      vm.hasOrderToolbar = (toState.data && toState.data.hasOrderToolbar) ? toState.data.hasOrderToolbar : false;
      isResponsiveState = (toState.data && toState.data.isResponsiveState) ? toState.data.isResponsiveState : false;
    }
  }
}());
