(function () {
  'use strict';
  // jscs:disable maximumLineLength

  angular
    .module('wbGdpr')
    .config(config);

  function config($translateProvider) {
    $translateProvider.translations('en', {
      'gdpr.privacyAlert.content': 'Please note that cookies are used on this website for the purpose of collecting visit information in an anonymized format, and certain services may not be accessible without such cookies. Certain cookies we use may collect data for third parties, who use them for displaying advertisements by ourselves or by third parties that may be relevant for you. Detailed information is given about cookies, and about enabling and disabling them, in the <a href="https://privacy.edenred.hu/cookie/" target="_blank" rel="noopener noreferrer">Notice about Cookies</a>, or you can set the cookies yourself at any time.',
      'gdpr.privacyAlert.agree': 'Approved',
      'gdpr.privacyAlert.preferences': 'Settings',
      'gdpr.privacyPreferences.title': 'Consent management',
      'gdpr.privacyPreferences.disclaimer': 'Consents',
      'gdpr.privacyPreferences.disclaimer.text': '<p>On this panel, you can set the following:<br/>- consent to access to certain website functions<br/>- enabling/disabling cookies per type<br/><br/>You can modify your previous settings at any time on the authorization management page.</p><p><a href="https://privacy.edenred.hu/data-processing-policy/" target="_blank" rel="noopener noreferrer">Data Processing Policy</a></a></p>',
      'gdpr.privacyPreferences.performanceCookies': 'Function and Performance Cookies',
      'gdpr.privacyPreferences.performanceCookies.text': '<p>Functional cookies allow our website to save the specified details and based on these data we offer improved and more custom-tailored functions. These cookies only collect and store anonymized information to prevent the tracing of your movements on other websites. Performance cookies collect data on the way our website is used. We use them to better understand how our website is used in order to improve their content and functions. Please note that disabling functional and performance cookies may restrict the operation of certain functions of our website. The following Functional and Performance Cookies are used on our website:</p>',
      'gdpr.privacyPreferences.strictlyNecessaryCookies': 'Necessary cookies',
      'gdpr.privacyPreferences.strictlyNecessaryCookies.text': '<p>Strictly necessary cookies ensure functions without which our website cannot be used for its intended purpose. As these cookies are only used by us, they are First-Party Cookies. Your consent is not required for the use of strictly necessary cookies. Thus, strictly necessary cookies may not be enabled and disabled. The following strictly necessary cookies are used on our website:</p>',
      'gdpr.privacyPreferences.consents.analytics': 'Google Analytics',
      'gdpr.privacyPreferences.consents.i18n': 'Internationalization',
      'gdpr.privacyPreferences.consents.authentication': 'Authentication',
      'gdpr.privacyPreferences.hide': 'Close',
      'gdpr.privacyPreferences.save': 'Save settings'
    });
  }
}());
