(function () {
  'use strict';
  // jscs:disable maximumLineLength

  angular
    .module('wbAuth')
    .config(config);

  function config($translateProvider) {
    $translateProvider.translations('hu', {
      'form.validation.required': 'A mező kitöltése kötelező',
      'form.validation.minlength': 'A mező tartalma nem elég hosszú',
      'form.validation.maxlength': 'A mező tartalma túl hosszú',
      'form.validation.email': 'Helytelen formátumú email cím',
      'form.validation.match': 'A mezők tartalmai nem azonosak',
      'form.validation.login': 'Hibás azonosító vagy jelszó',
      'form.validation.date': 'Adjon meg valós dátumot',
      'form.validation.zero': 'A mező tartalma nem lehet nulla',
      'form.validation.min': 'A mező tartalma nem elég nagy',
      'form.validation.max': 'A mező tartalma túl nagy',
      'form.validation.string': 'A mező tartalma nem szöveg',
      'form.validation.bool': 'A mező tartalma nem logikai értékű',
      'form.validation.url': 'Adjon meg valós url-t',
      'form.validation.number': 'A mező tartalma nem szám',
      'form.validation.pattern': 'A mező tartalma nem a kívánt formátumban van',
      'form.validation.password': 'Hibás jelszó',
      'form.validation.emailExists': 'Az email címet más már használja',
      'form.validation.emailNotExists': 'Az email cím nem található a rendszerünkben',
      'form.validation.cardNotExists': 'A megadott kártyaszámmal nem létezik kártya',
      'form.validation.fullNameMismatch': 'Hibás kártyán szereplő név',
      'form.validation.passwordResetLimit': 'Az új jelszó kérés jelenleg le van tiltva. Próbálja később!',
      'form.validation.loginLimit': 'A bejelentkezés jelenleg le van tiltva. Próbálja később!',
      'form.validation.panMismatch': 'A kártya adatai nem egyeznek!',
      'form.validation.fileExtension': 'Hibás fájlkiterjesztés',
      'form.validation.fileLoad': 'A fájl nem tölthető be',
      'form.validation.fileEmpty': 'Üres a feltöltött file',
      'form.validation.fileColumns': 'Nem a példa fájlnak megfelelő fájl felépítés',
      'form.validation.confirmationCode': 'Nem megfelelő ellenőrző kód',
      'form.validation.passwordMismatch': 'Hibás jelszó a felhasználóhoz',
      'form.validation.companyNameExists': 'A cégnév már létezik a rendszerben',
      'form.validation.companyNumberExists': 'A cégjegyzékszám már létezik a rendszerben',
      'form.validation.companyVatNumberExists': 'Az adószám már létezik a rendszerben',
      'form.validation.fileData': 'Hibás tartalom. Kérjük, az XLS-t annak példa sora alapján töltse ki!',
      'form.validation.fileTooLarge': 'Túl sok adat az XLS-ben. (Max 9999 kártya)',
      'form.validation.companySearchLimit': 'Túl sok céglekérés történt. Kérjük, próbálja meg később!',
      'form.validation.cardTypeMismatch': 'Hibás kártyatípus',
      'form.validation.mask': 'A mező tartalma nem a kívánt formátumban van',
      'form.validation.fileSize': 'Túl nagy a feltöltött fájl! (max 35MB)',
      'form.validation.amountRule': 'Az összegnek ötszázasra, vagy ezresre kell végződnie',
      'form.validation.permissionMissingForExtranet': 'Nincs jogosultsága az extranethez'
    });
  }
}());
