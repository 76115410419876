(function () {
  'use strict';

  /* @ngdoc object
   * @name wbAuth
   * @description
   *
   */
  angular
    .module('wbAuth', [
      'ui.router',
      'pascalprecht.translate',
      'wbAccount',
      'wbResource',
      'wbLoggedUser',
      'visor',
      'ngCookies',
      'wbRestriction',
      'wbNavigation',
      'wbForm'
    ]);
}());
