(function () {
  'use strict';

  angular
    .module('wbNavigation')
    .config(config);

  function config($translateProvider) {
    $translateProvider.translations('en', {
      'navigation.calculator': 'Calculator',
      'navigation.learnMoreButton': 'More about our cards',
      'navigation.learnMoreButton.href': 'http://edenredkartya.hu/',
      'navigation.onlineCardManagement': 'Online Card Management',
      'navigation.newCardOrder': 'New card',
      'navigation.topUp': 'Top up card',
      'navigation.vip': 'Secure file upload',
      'navigation.previousOrders': 'Previous orders',
      'navigation.faq': 'FAQ',
      'navigation.toggle': 'Navigation',
      'navigation.languageSwitcher': 'Language switcher'
    });
  }
}());
