<div class="wb-privacy-alert md-whiteframe-z2" ng-if="wbPrivacyAlert.isVisible">
  <div class="wb-privacy-alert-content" ng-bind-html="::'gdpr.privacyAlert.content' | translate"></div>

  <div class="wb-privacy-alert-controls">
    <md-button class="md-flat" type="button" ng-click="wbPrivacyAlert.openPreferencesDialog()">
      {{::'gdpr.privacyAlert.preferences' | translate}}
    </md-button>
    <md-button class="md-raised md-accent" type="button" ng-click="wbPrivacyAlert.agree()">
      <md-icon md-font-icon="zmdi zmdi-hc-lg zmdi-hc-fw zmdi-check-circle"></md-icon>
      {{::'gdpr.privacyAlert.agree' | translate}}
    </md-button>
  </div>
</div>
