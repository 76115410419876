(function () {
  'use strict';

  angular
    .module('wbAcceptLanguage')
    .config(configAcceptLanguageInterceptor);

  function configAcceptLanguageInterceptor($httpProvider) {
    $httpProvider.interceptors.push('AcceptLanguageInterceptor');
  }
}());
