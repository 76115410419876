(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name wbOrder.directive:wbOrderAmountRule
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="wbOrder">
   <file name="index.html">
   <wb-order-amount-rule></wb-order-amount-rule>
   </file>
   </example>
   *
   */
  angular
    .module('wbOrder')
    .directive('wbOrderAmountRule', wbOrderAmountRule);

  function wbOrderAmountRule() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attrs, ngModel) {
        ngModel.$validators.amountRule = function (modelValue, viewValue) {
          var value = modelValue || viewValue;
          return value % 500 === 0;
        };
      }
    };
  }
}());
