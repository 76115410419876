<md-card class="wb-contact">
  <md-card-content>
    <h2 class="md-title">
      {{::'info.contact.title' | translate}}
    </h2>

    <div class="contact-row" layout="row" layout-wrap>
      <div flex="75">
        <div class="label">
          {{::'info.contact.1.title' | translate}}<br/>
          {{::'info.contact.openingHours' | translate}}
        </div>
        <!--@todo tel format &/ ng-bind-html?-->
      </div>
      <md-icon class="text-right" flex="25" md-font-icon="zmdi zmdi-phone zmdi-hc-lg zmdi-hc-fw"></md-icon>
      <a href="tel:{{::'info.contact.1.content' | translate}}">{{::'info.contact.1.content' | translate}}</a>
    </div>

    <div class="contact-row" layout="row" layout-wrap>
      <div flex="75">
        <div class="label">{{::'info.contact.2.title' | translate}}</div>
      </div>
      <md-icon class="text-right" flex="25" md-font-icon="zmdi zmdi-email zmdi-hc-lg zmdi-hc-fw"></md-icon>
      <span ng-bind-html="::'mailto:' + ( 'info.contact.2.content' | translate ) | linky"></span>
    </div>
  </md-card-content>
</md-card>
