<md-toolbar layout="row" layout-align="center center" class="wb-page-header md-whiteframe-z3">
  <div class="wb-container" layout="row" layout-align="center center" flex>
    <span flex class="brand-wrapper">
        <a class="md-toolbar-tools brand" ui-sref="orders"><img src="images/rewin-logo-white.svg" alt="Rewin"></a>
    </span>

    <div flex="80" class="toolbar-menu-wrapper" ng-if="wbMainMenu.hasLoggedInElements()">
      <span layout="row" flex layout-align-gt-lg="end center">
        <span layout="column" layout-align="space-between center" class="toolbar-user-menu-item">
          <md-menu md-position-mode="target-right target" md-offset="-185 87">
            <md-button class="my-account-toggle" ng-click="$mdOpenMenu()" aria-label="{{wbMainMenu.getFullNameOfCurrentUser()}}">
              <span>{{wbMainMenu.getFullNameOfCurrentUser()}}</span>
              <md-icon md-font-icon="zmdi zmdi-caret-down zmdi-hc-lg zmdi-hc-fw"></md-icon>
            </md-button>

            <md-menu-content class="wb-user-menu">
              <md-button title="{{::'auth.password.change' | translate}}"
                         aria-label="{{::'auth.password.change' | translate}}"
                         ui-sref="passwordChange">
                <md-icon md-font-icon="zmdi zmdi-time-restore-setting zmdi-hc-lg zmdi-hc-fw" hide-sm></md-icon>
                {{::'auth.password.change' | translate}}
              </md-button>
            </md-menu-content>
          </md-menu>
        </span>

        <span layout="column" layout-align="space-between center" class="toolbar-user-menu-item" ng-if="wbMainMenu.hasLoggedInElements()">
          <md-button class="logout narrow" ui-sref="logout" aria-label="{{::'auth.logout' | translate}}">
            <md-icon md-font-icon="zmdi zmdi-square-right zmdi-hc-lg zmdi-hc-fw"></md-icon>
          </md-button>
          <md-tooltip>
            {{::'auth.logout' | translate}}
          </md-tooltip>
        </span>
      </span>
    </div>

  </div>
</md-toolbar>
