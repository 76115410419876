(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbPrints.controller:WbPrintsCtrl
   *
   * @description
   *
   */
  angular
    .module('wbPrints')
    .controller('WbPrintsCtrl', WbPrintsCtrl);

  function WbPrintsCtrl(prints, Prints) {
    var vm = this;

    vm.prints = prints;

    _.forEach(vm.prints, function (csnFile) {
      csnFile.isProcessing = false;
    });

    /**
     * Download a selected csn file
     *
     * @param printableFile
     */
    vm.download = function download(printableFile) {
      toggleProcessing(printableFile);

      Prints
        .get({id: printableFile.id})
        .$promise
        .then(function (fileData) {
          downloadTheFileViaBrowserApi(fileData.response, printableFile.fileName);
        })
        .then(function () {
          toggleProcessing(printableFile);
        });
    };

    function toggleProcessing(printableFile) {
      printableFile.isProcessing = !printableFile.isProcessing;
    }

    function downloadTheFileViaBrowserApi(fileBlob, fileName) {
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(fileBlob, fileName);
      } else {
        var elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(fileBlob);
        elem.download = fileName;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }
    }
  }
}());
