<md-card class="wb-modify-user-data">
  <md-toolbar>
    <div class="md-toolbar-tools">
      {{::'account.company.company.change' | translate}}
    </div>
  </md-toolbar>

  <md-card-content>

    <form name="companyForm" ng-submit="companyForm.$valid && !company.isProcessing() && company.submit()" novalidate>

      <div layout-sm="column" layout-gt-sm="row" layout-align="space-between start">

        <div flex-gt-sm layout-fill-sm>

          <div class="group-label">
            <md-icon class="zmdi zmdi-case zmdi-hc-lg zmdi-hc-fw"></md-icon>
            {{::'account.company.companyData' | translate}}
          </div>

          <div class="inset">
            <md-input-container flex>
              <label>{{::'account.company.name' | translate}}</label>
              <input required ng-model="company.company.name" name="name" type="text">
              <wb-messages form="companyForm" server="company.getServerValidation()" field-name="name"></wb-messages>
            </md-input-container>

            <md-input-container flex>
              <md-icon class="wb-tooltip zmdi zmdi-help zmdi-hc-lg"></md-icon>

              <md-tooltip md-direction="bottom">
                <span ng-bind-html="::'account.company.nameOnCard.tooltip' | translate"></span>
              </md-tooltip>

              <label>{{::'account.company.nameOnCard' | translate}}</label>
              <input ng-model="company.company.nameOnCard" name="nameOnCard" type="text" ng-min="2" ng-max="21">
              <wb-messages form="companyForm" server="company.getServerValidation()" field-name="nameOnCard"></wb-messages>
            </md-input-container>

            <div layout="row">
              <md-input-container flex>
                <label>{{::'account.company.companyNumber' | translate}}</label>
                <input required ng-model="company.company.companyNumber" name="companyNumber" type="text" ui-mask="99-99-999999" mask-fix>
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="companyNumber"></wb-messages>
              </md-input-container>

              <md-input-container flex>
                <label>{{::'account.company.vatNumber' | translate}}</label>
                <input required ng-model="company.company.vatNumber" name="vatNumber" type="text" ui-mask="99999999-9-99" mask-fix>
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="vatNumber"></wb-messages>
              </md-input-container>
            </div>

            <md-input-container flex>
              <label>{{::'account.company.bankAccountNumber' | translate}}</label>
              <input required ng-model="company.company.bankAccountNumber" name="bankAccountNumber" type="text" ui-mask="99999999-99999999-99999999" mask-fix>
              <wb-messages form="companyForm" server="company.getServerValidation()" field-name="bankAccountNumber"></wb-messages>
            </md-input-container>

            <md-input-container flex>
              <label>{{::'account.company.staffCount' | translate}}</label>
              <input required ng-model="company.company.staffCount" name="staffCount" type="text">
              <wb-messages form="companyForm" server="company.getServerValidation()" field-name="staffCount"></wb-messages>
            </md-input-container>
          </div>

        </div>

        <div flex-gt-sm offset-gt-sm="5" layout-fill-sm>

          <div class="group-label">
            <md-icon class="zmdi zmdi-comment-text zmdi-hc-lg zmdi-hc-fw"></md-icon>
            {{::'account.company.contact' | translate}}
          </div>

          <div class="inset">
            <md-input-container flex>
              <label>{{::'account.company.contact.invoiceEmail' | translate}}</label>
              <input required ng-model="company.company.invoiceEmail" name="invoiceEmail" type="email">
              <wb-messages form="companyForm" server="company.getServerValidation()" field-name="invoiceEmail"></wb-messages>
            </md-input-container>

            <div layout="row">
              <md-input-container flex="50">
                <label>{{::'account.company.deliveryName.lastName' | translate}}</label>
                <input required ng-model="company.company.deliveryLastName" name="deliveryLastName" type="text">
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="deliveryLastName"></wb-messages>
              </md-input-container>

              <md-input-container flex="50">
                <label>{{::'account.company.deliveryName.firstName' | translate}}</label>
                <input required ng-model="company.company.deliveryFirstName" name="deliveryFirstName" type="text">
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="deliveryFirstName"></wb-messages>
              </md-input-container>
            </div>
          </div>

          <div class="group-label">
            <md-icon class="zmdi zmdi-pin zmdi-hc-lg zmdi-hc-fw"></md-icon>
            {{::'account.company.address' | translate}}
          </div>

          <div class="inset">
            <div layout="row">
              <md-input-container flex="35">
                <label>{{::'account.company.address.zipCode' | translate}}</label>
                <input zip-to-city="address.zipCode" receive="false" required ng-model="company.company.address.zipCode" name="address.zipCode" type="text">
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="address.zipCode"></wb-messages>
              </md-input-container>

              <md-input-container flex="65">
                <label>{{::'account.company.address.city' | translate}}</label>
                <input zip-to-city="address.zipCode" receive="true" required ng-model="company.company.address.city" name="address.city" type="text">
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="address.city"></wb-messages>
              </md-input-container>
            </div>

            <div layout="row">
              <md-input-container flex="35">
                <label>{{::'account.company.address.countryCode' | translate}}</label>
                <md-select placeholder="{{::'account.company.address.countryCode' | translate}}"
                           ng-model="company.company.address.countryCode"
                           required
                           name="address.countryCode"
                           class="full-width-select">
                  <md-option ng-repeat="country in company.countries"
                             value="{{country.alpha3}}">
                    {{country.name}}
                  </md-option>
                </md-select>
                <wb-messages form="companyForm"
                             server="company.getServerValidation()"
                             field-name="address.countryCode"></wb-messages>
              </md-input-container>

              <md-input-container flex="65">
                <label>{{::'account.company.address.address' | translate}}</label>
                <input required ng-model="company.company.address.address" name="address.address" type="text">
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="address.address"></wb-messages>
              </md-input-container>
            </div>
          </div>
        </div>

      </div>

      <div layout-sm="column" layout-gt-sm="row" layout-align="space-between start">
        <div flex-gt-sm layout-fill-sm>

          <div class="group-label">
            <md-icon class="zmdi zmdi-truck zmdi-hc-lg zmdi-hc-fw"></md-icon>
            {{::'account.company.deliveryAddress' | translate}}
          </div>

          <div class="inset">
            <div layout="row">
              <md-input-container flex="35">
                <label>{{::'account.company.deliveryAddress.zipCode' | translate}}</label>
                <input zip-to-city="deliveryAddress.zipCode" receive="false" required ng-model="company.company.deliveryAddress.zipCode" name="deliveryAddress.zipCode" type="text">
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="deliveryAddress.zipCode"></wb-messages>
              </md-input-container>

              <md-input-container flex="65">
                <label>{{::'account.company.deliveryAddress.city' | translate}}</label>
                <input zip-to-city="deliveryAddress.zipCode" receive="true" required ng-model="company.company.deliveryAddress.city" name="deliveryAddress.city" type="text">
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="deliveryAddress.city"></wb-messages>
              </md-input-container>
            </div>

            <div layout="row">
              <md-input-container flex="35">
                <label>{{::'account.company.address.countryCode' | translate}}</label>
                <md-select placeholder="{{::'account.company.address.countryCode' | translate}}"
                           ng-model="company.company.deliveryAddress.countryCode"
                           required
                           name="deliveryAddress.countryCode"
                           class="full-width-select">
                  <md-option ng-repeat="country in company.countries"
                             value="{{country.alpha3}}">
                    {{country.name}}
                  </md-option>
                </md-select>
                <wb-messages form="companyForm"
                             server="company.getServerValidation()"
                             field-name="deliveryAddress.countryCode"></wb-messages>
              </md-input-container>

              <md-input-container flex="65">
                <label>{{::'account.company.deliveryAddress.address' | translate}}</label>
                <input required ng-model="company.company.deliveryAddress.address" name="deliveryAddress.address" type="text">
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="deliveryAddress.address"></wb-messages>
              </md-input-container>
            </div>
          </div>

        </div>
        <div flex-gt-sm offset-gt-sm="5" layout-fill-sm>
          <div class="group-label">
            <md-icon class="zmdi zmdi-money zmdi-hc-lg zmdi-hc-fw"></md-icon>
            {{::'account.company.invoiceAddress' | translate}}
          </div>

          <div class="inset">
            <div layout="row">
              <md-input-container flex="35">
                <label>{{::'account.company.invoiceAddress.zipCode' | translate}}</label>
                <input zip-to-city="invoiceAddress.zipCode" receive="false" required ng-model="company.company.invoiceAddress.zipCode" name="invoiceAddress.zipCode" type="text">
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="invoiceAddress.zipCode"></wb-messages>
              </md-input-container>

              <md-input-container flex="65">
                <label>{{::'account.company.invoiceAddress.city' | translate}}</label>
                <input zip-to-city="invoiceAddress.zipCode" receive="true" required ng-model="company.company.invoiceAddress.city" name="invoiceAddress.city" type="text">
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="invoiceAddress.city"></wb-messages>
              </md-input-container>
            </div>

            <div layout="row">
              <md-input-container flex="35">
                <label>{{::'account.company.address.countryCode' | translate}}</label>
                <md-select placeholder="{{::'account.company.address.countryCode' | translate}}"
                           ng-model="company.company.invoiceAddress.countryCode"
                           required
                           name="invoiceAddress.countryCode"
                           class="full-width-select">
                  <md-option ng-repeat="country in company.countries"
                             value="{{country.alpha3}}">
                    {{country.name}}
                  </md-option>
                </md-select>
                <wb-messages form="companyForm"
                             server="company.getServerValidation()"
                             field-name="invoiceAddress.countryCode"></wb-messages>
              </md-input-container>

              <md-input-container flex="65">
                <label>{{::'account.company.invoiceAddress.address' | translate}}</label>
                <input required ng-model="company.company.invoiceAddress.address" name="invoiceAddress.address" type="text">
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="invoiceAddress.address"></wb-messages>
              </md-input-container>
            </div>
          </div>
        </div>
      </div>

      <div layout-sm="column" layout-gt-sm="row" layout-align="space-between start">
        <div flex-gt-sm layout-fill-sm>

          <div class="group-label">
            <md-icon class="zmdi zmdi-account zmdi-hc-lg zmdi-hc-fw"></md-icon>
            {{::'account.company.owner' | translate}}
          </div>

          <div class="inset">
            <div layout="row">
              <md-input-container flex="40">
                <label>{{::'account.company.owner.titleId' | translate}}</label>
                <md-select placeholder="{{::'account.company.owner.titleId' | translate}}"
                           ng-model="company.owner.titleId"
                           required
                           name="owner.titleId"
                           class="full-width-select">
                  <md-option value="1">
                    {{::'account.titleId.1' | translate}}
                  </md-option>
                  <md-option value="2">
                    {{::'account.titleId.2' | translate}}
                  </md-option>
                </md-select>
                <wb-messages form="companyForm"
                             server="company.getServerValidation()"
                             field-name="owner.titleId"></wb-messages>
              </md-input-container>

              <md-input-container flex="60">
                <label>{{::'account.company.owner.name' | translate}}</label>
                <input required ng-model="company.owner.name" name="owner.name" type="text">
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="owner.name"></wb-messages>
              </md-input-container>
            </div>

            <md-input-container flex>
              <label>{{::'account.company.owner.birthName' | translate}}</label>
              <input ng-model="company.owner.birthName" name="owner.birthName" type="text">
              <wb-messages form="companyForm" server="company.getServerValidation()" field-name="owner.birthName"></wb-messages>
            </md-input-container>

            <md-input-container flex>
              <label>{{::'account.company.owner.post' | translate}}</label>
              <input required ng-model="company.owner.post" name="owner.post" type="text">
              <wb-messages form="companyForm" server="company.getServerValidation()" field-name="owner.post"></wb-messages>
            </md-input-container>

            <md-input-container flex>
              <label>{{::'account.company.owner.nationality' | translate}}</label>
              <input required ng-model="company.owner.nationality" name="owner.nationality" type="text">
              <wb-messages form="companyForm" server="company.getServerValidation()" field-name="owner.nationality"></wb-messages>
            </md-input-container>

            <div layout="row">
              <md-input-container flex="auto">
                <label>{{::'account.company.owner.IDType' | translate}}</label>
                <md-select placeholder="{{::'account.company.owner.IDType' | translate}}"
                           ng-model="company.owner.IDType"
                           required
                           name="owner.IDType"
                           class="full-width-select">
                  <md-option value="1">
                    {{::'account.IDType.1' | translate}}
                  </md-option>
                  <md-option value="2">
                    {{::'account.IDType.2' | translate}}
                  </md-option>
                  <md-option value="3">
                    {{::'account.IDType.3' | translate}}
                  </md-option>
                </md-select>
                <wb-messages form="companyForm"
                             server="company.getServerValidation()"
                             field-name="owner.IDType"></wb-messages>
              </md-input-container>

              <md-input-container flex="auto">
                <label>{{::'account.company.owner.IDNumber' | translate}}</label>
                <input required ng-model="company.owner.IDNumber" name="owner.IDNumber" type="text">
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="owner.IDNumber"></wb-messages>
              </md-input-container>
            </div>

            <md-input-container flex>
              <label>{{::'account.company.owner.email' | translate}}</label>
              <input ng-model="company.owner.email" name="owner.email" type="email">
              <wb-messages form="companyForm" server="company.getServerValidation()" field-name="owner.email"></wb-messages>
            </md-input-container>

            <div layout="row">
              <md-input-container flex>
                <label>{{::'account.company.owner.phone' | translate}}</label>
                <input ng-model="company.owner.phone" name="owner.phone" type="text" ui-mask="+36-99-999-9998" mask-fix model-view-value="true">
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="owner.phone"></wb-messages>
              </md-input-container>

              <md-input-container flex>
                <label>{{::'account.company.owner.fax' | translate}}</label>
                <input ng-model="company.owner.fax" name="owner.fax" type="text" ui-mask="+36-99-999-999" mask-fix model-view-value="true">
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="owner.fax"></wb-messages>
              </md-input-container>
            </div>
          </div>
        </div>
        <div flex-gt-sm offset-gt-sm="5" layout-fill-sm>

          <div class="group-label">
            <md-icon class="zmdi zmdi-pin zmdi-hc-lg zmdi-hc-fw"></md-icon>
            {{::'account.company.owner.address' | translate}}
          </div>

          <div class="inset">
            <div layout="row">
              <md-input-container flex="35">
                <label>{{::'account.company.address.zipCode' | translate}}</label>
                <input zip-to-city="owner.address.zipCode" receive="false" required ng-model="company.owner.address.zipCode" name="owner.address.zipCode" type="text">
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="owner.address.zipCode"></wb-messages>
              </md-input-container>

              <md-input-container flex="65">
                <label>{{::'account.company.address.city' | translate}}</label>
                <input zip-to-city="owner.address.zipCode" receive="true" required ng-model="company.owner.address.city" name="owner.address.city" type="text">
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="owner.address.city"></wb-messages>
              </md-input-container>
            </div>

            <div layout="row">
              <md-input-container flex="35">
                <label>{{::'account.company.address.countryCode' | translate}}</label>
                <md-select placeholder="{{::'account.company.address.countryCode' | translate}}"
                           ng-model="company.owner.address.countryCode"
                           required
                           name="owner.address.countryCode"
                           class="full-width-select">
                  <md-option ng-repeat="country in company.countries"
                             value="{{country.alpha3}}">
                    {{country.name}}
                  </md-option>
                </md-select>
                <wb-messages form="companyForm"
                             server="company.getServerValidation()"
                             field-name="owner.address.countryCode"></wb-messages>
              </md-input-container>

              <md-input-container flex="65">
                <label>{{::'account.company.address.address' | translate}}</label>
                <input required ng-model="company.owner.address.address" name="owner.address.address" type="text">
                <wb-messages form="companyForm" server="company.getServerValidation()" field-name="owner.address.address"></wb-messages>
              </md-input-container>
            </div>

            <md-checkbox
              class="md-align-top-left"
              name="owner.isForeign"
              ng-model="company.owner.isForeign"
              aria-label="{{::'account.company.owner.isForeign' | translate}}">
              {{::'account.company.owner.isForeign' | translate}}
              <wb-messages form="companyForm"
                           server="company.getServerValidation()"
                           field-name="owner.isForeign"></wb-messages>
            </md-checkbox>
          </div>

          <div ng-if="company.owner.isForeign">
            <div class="group-label">
              <md-icon class="zmdi zmdi-pin zmdi-hc-lg zmdi-hc-fw"></md-icon>
              {{::'account.company.owner.hungarianAddress' | translate}}
            </div>

            <div class="inset">
              <div layout="row">
                <md-input-container flex="35">
                  <label>{{::'account.company.address.zipCode' | translate}}</label>
                  <input zip-to-city="owner.hungarianAddress.zipCode" receive="false" required ng-model="company.owner.hungarianAddress.zipCode" name="owner.hungarianAddress.zipCode" type="text">
                  <wb-messages form="companyForm" server="company.getServerValidation()" field-name="owner.hungarianAddress.zipCode"></wb-messages>
                </md-input-container>

                <md-input-container flex="65">
                  <label>{{::'account.company.address.city' | translate}}</label>
                  <input zip-to-city="owner.hungarianAddress.zipCode" receive="true" required ng-model="company.owner.hungarianAddress.city" name="owner.hungarianAddress.city" type="text">
                  <wb-messages form="companyForm" server="company.getServerValidation()" field-name="owner.hungarianAddress.city"></wb-messages>
                </md-input-container>
              </div>

              <div layout="row">
                <md-input-container flex="35">
                  <label>{{::'account.company.address.countryCode' | translate}}</label>
                  <md-select placeholder="{{::'account.company.address.countryCode' | translate}}"
                             ng-model="company.owner.hungarianAddress.countryCode"
                             required
                             name="owner.hungarianAddress.countryCode"
                             class="full-width-select">
                    <md-option ng-repeat="country in company.countries"
                               value="{{country.alpha3}}">
                      {{country.name}}
                    </md-option>
                  </md-select>
                  <wb-messages form="companyForm"
                               server="company.getServerValidation()"
                               field-name="owner.hungarianAddress.countryCode"></wb-messages>
                </md-input-container>

                <md-input-container flex="65">
                  <label>{{::'account.company.address.address' | translate}}</label>
                  <input required ng-model="company.owner.hungarianAddress.address" name="owner.hungarianAddress.address" type="text">
                  <wb-messages form="companyForm" server="company.getServerValidation()" field-name="owner.hungarianAddress.address"></wb-messages>
                </md-input-container>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div layout-gt-sm="row" layout-align-gt-sm="end start">
        <div>
          <md-button ng-hide="company.isProcessing()"
                     class="md-raised md-accent full-width-button-sm" type="submit">
            {{::'account.company.save' | translate}}
          </md-button>
          <div class="button-loader-wrapper" ng-show="company.isProcessing()">
            <md-progress-circular class="md-hue-2 button-loader" md-diameter="36px"
                                  md-mode="indeterminate"></md-progress-circular>
          </div>
        </div>
      </div>

    </form>

  </md-card-content>

</md-card>
