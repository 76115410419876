(function () {
  'use strict';
  // jscs:disable maximumLineLength

  angular
    .module('wbAuth')
    .config(config);

  function config($translateProvider) {
    $translateProvider.translations('en', {
      'app.title': 'Edenred Online Card order and top up',

      'auth.title': 'Welcome to Edenred Online Card order and top up page!',
      'auth.login.title': 'Login',
      'auth.login.subtitle': 'For our existing partners',
      'auth.login.legend': 'Please enter your e-mail address<br />which you have registered with earlier<br />and your password.',
      'auth.logout': 'Logout',
      'auth.identifier': 'E-mail / Card number',
      'auth.identifierTooltip': 'On the back of your card,<br /> starting as 705xxxxxxx 10 digit identifier',
      'auth.email': 'E-mail',
      'auth.username': 'Username',
      'auth.password': 'Password',
      'auth.passwordConfirm': 'Confirm password',
      'auth.password.tooltip': 'Your password has to be longer than 8 digits <br />and has to contain lower and upper case and numbers as well.',
      'auth.passwordReset': 'Reset password',
      'auth.passwordReset.legend': 'In case of forgotten password, you can reset your password by entering your registered e-mail address. After clicking \'Send\' button you will be receiving your new password shortly.',
      'auth.passwordReset.send': 'Send',
      'auth.passwordReset.back': 'Back',
      'auth.passwordReset.success': 'We received your inquiry, you will be receiving your new password shortly.',
      'auth.passwordReset.confirm.legend': 'Thank you! We accepted your request and will send your new password shortly via e-mail.',
      'passwordResetConfirmDialog.title': 'An error occured during request',
      'passwordResetConfirmDialog.content': 'Please reset your password and click the link in the e-mail.',
      'passwordResetConfirmDialog.button.ok': 'ok',
      'auth.password.change': 'Password change',
      'card.password.change.legend': 'If you would like to change your password, please enter your current password and then the new password',
      'auth.password.change.password': 'Current password',
      'auth.password.change.passwordNew': 'New password',
      'auth.password.change.passwordConfirm': 'Confirm new password',
      'auth.password.change.submit': 'Change submit',
      'auth.password.change.success': 'Your password changed successfully',
      'auth.registration.title': 'Registration',
      'auth.registration.subtitle': 'For new clients',
      'auth.steps.first': 'Sign up quick and easy!',
      'auth.steps.second': 'Request Edenred Card in our system!',
      'auth.steps.third': 'Then top up your card in two minutes!',
      'auth.legalNotice.title': 'Legal Declaration',
      'auth.legalNotice.url': 'http://edenred.hu/en/legal-declaration',
      'auth.imprint.title': 'Impressum',
      'auth.imprint.url': 'http://edenred.hu/en/impressum',
      'auth.copyrightNotice': 'All rights reserved.',

      'auth.companySelector': 'Welcome!',
      'auth.companySelector.legend': 'More company belongs to this account, please choose from the following. After login you can easily change between the related companies.',
      'auth.companySelector.send': 'Login',
      'auth.companySelector.selectedCompany': 'Company',
      'auth.companySelector.pleaseSelect': 'Choose a company',

      'auth.login.firstLoginDialog.content': 'Tisztelt Ügyfelünk!<br><br>Az online kártyarendelő felületünk megújult, mostantól az edenredonline.hu oldalon tudja intézni kártyás rendeléseit.<br>Amennyiben bármi kérdése van, kérje az Ügyfélszolgálatunk segítségét a 06-1/413-3333-as telefonszámon.<br><br>Üdvözlettel:<br>Az Edenred csapata'
    });
  }
}());
