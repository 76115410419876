(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name wbOrder.directive:wbOrderHistory
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="wbOrder">
   <file name="index.html">
   <wb-order-history></wb-order-history>
   </file>
   </example>
   *
   */
  angular
    .module('wbOrder')
    .directive('wbOrderHistory', wbOrderHistory);

  function wbOrderHistory() {
    return {
      restrict: 'EA',
      templateUrl: 'wb-order/directives/wb-order-history-directive.tpl.html',
      replace: false,
      controllerAs: 'wbOrderHistory',
      bindToController: true,
      controller: function () {
      }
    };
  }
}());
