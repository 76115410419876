(function () {
  'use strict';

  /* @ngdoc object
   * @name wbResource
   * @description
   *
   */
  angular
    .module('wbResource', [
      'ngResource',
      'lodash'
    ]);
}());
