<md-card class="wb-order-history">
  <md-toolbar class="md-whiteframe-z1 wb-toolbar-default">
    <div class="md-toolbar-tools">
      {{::'prints.title' | translate}}
    </div>
  </md-toolbar>

  <md-card-content>
    <div class="wb-table">
      <div class="wb-table-header" layout="row">
        <span flex="90">{{::'prints.file' | translate}}</span>
        <span flex class="text-right">{{::'prints.download' | translate}}</span>
      </div>

      <div
        class="wb-table-item"
        layout="row"
        layout-align="start center"
        ng-repeat="printableFile in wbPrints.prints track by printableFile.id">
        <span flex="90">{{::printableFile.name}}</span>
        <span flex class="text-right">
          <md-button class="md-icon-button"
                     aria-label="{{::'prints.download' | translate}}"
                     ng-if="!printableFile.url"
                     ng-click="wbPrints.download(printableFile)">
            <md-icon class="md-accent"
                     ng-if="!printableFile.isProcessing"
                     md-font-icon="zmdi zmdi-hc-2x zmdi-hc-fw zmdi-download"></md-icon>
            <md-progress-circular ng-if="printableFile.isProcessing"
                                  class="md-accent button-loader"
                                  md-mode="indeterminate"
                                  md-diameter="25"></md-progress-circular>
          </md-button>
          <md-button class="md-icon-button" ng-if="printableFile.url" ng-href="{{printableFile.url}}" target="_blank">
            <md-icon class="md-accent" md-font-icon="zmdi zmdi-hc-2x zmdi-hc-fw zmdi-open-in-new"></md-icon>
          </md-button>
        </span>
      </div>
    </div>
  </md-card-content>

  <md-card-footer class="wb-prints-footer" layout="row" layout-align="space-between center"></md-card-footer>
</md-card>
