(function () {
  'use strict';

  angular
    .module('wbAccount')
    .config(config);

  function config($stateProvider, authenticatedOnly) {
    $stateProvider
      .state('companySelector', {
        url: '/company-selector',
        templateUrl: 'wb-account/views/company-selector.tpl.html',
        controller: 'CompanySelectorCtrl',
        controllerAs: 'companySelector',
        resolve: {
          companies: function (User, Auth, _, $state) {
            return User
              .query()
              .$promise
              .then(function (data) {
                if (data.length === 1) {
                  return Auth.login(_.first(data).id)
                    .then(function () {
                      $state.go('orders');
                    });
                }

                return data;
              });
          }
        },
        data: {
          skipNavigation: true
        }
      })
      .state('orders', {
        url: '/orders',
        templateUrl: 'wb-account/views/orders.tpl.html',
        controller: 'OrdersCtrl',
        controllerAs: 'orders',
        restrict: authenticatedOnly,
        resolve: {
          pendingOrders: function (Order) {
            return Order.getPendingOrders().$promise;
          },
          completedOrders: function (Order) {
            return Order.getCompletedOrders().$promise;
          }
        }
      });
  }
}());
