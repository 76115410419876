(function () {
  'use strict';
  // jscs:disable maximumLineLength

  angular
    .module('wbOrder')
    .config(config);

  function config($translateProvider) {
    $translateProvider.translations('hu', {
      'order.back': 'Vissza',
      'order.next': 'Tovább',
      'order.itemList': 'Új rendelés',
      'order.itemList.next': 'Megrendelés áttekintése',
      'order.itemList.value': 'Boríték értéke',
      'order.itemList.contents': 'Boríték tartalma',
      'order.itemList.quantity': 'Boríték darabszáma',
      'order.itemList.sum': 'Érték',
      'order.itemList.delete': 'Törlés',
      'order.itemList.total': 'Összesen',
      'order.itemList.info.tenThousandTemporarilyUnavailable': 'Tájékoztatjuk, hogy ideiglenesen készlethiány van a <strong>10.000 Ft</strong> értékű utalványtömbünkből 2019. július 31-ig. Az átmeneti időszakban <strong>12.000-es</strong> és <strong>8.000-es</strong> címletű utalványaink állnak rendelkezésre. Köszönjük együttműködését és türelmét!',
      'order.itemList.info.voucher': 'Kérjük vegye figyelembe, hogy a választott boríték tartalma címletezésben az aktuális készlet függvényében eltérhet az itt találhatótól. Pl. 10 ezer Ft-os boríték érték esetében 10*1 000 Ft helyett 2*5 000 Ft',
      'order.invoice.title': 'Az Ön rendelése',
      'order.invoice.vouchers': 'Megrendelni kívánt utalványok',
      'order.invoice.faceValue': 'Névérték',
      'order.invoice.valueOfBundle': 'értékű boríték',
      'order.invoice.quantity': 'Mennyiség',
      'order.invoice.net': 'Nettó ár',
      'order.invoice.vat': 'ÁFA',
      'order.invoice.gross': 'Bruttó',
      'order.invoice.total': 'Összesen',
      'order.invoice.largeOrder': '<strong>Tisztelt Ügyfelünk!</strong> Amennyiben a leadott megrendelése a díjbekérő kiegyenlítését követően 3 munkanapon belül, vagy a kért szállítási határidőre nem teljesíthető, úgy Ügyfélszolgálatunk felveszi Önnel a kapcsolatot!',
      'order.invoice.additionalFees': 'Egyéb költség',
      'order.invoice.feeName': 'Megnevezés',
      'order.invoice.shipping': 'Szállítási díj',
      'order.invoice.disclaimer': 'Figyelem, a végleges számlaérték a díjbekérőn szerepel! Az itt feltüntettek értékek tájékoztató jellegűek kizárólag a névértékeket tartalmazzák.',
      'order.invoice.failed.title': 'Hiba',
      'order.invoice.failed.content': 'Megrendelése elküldése közben váratlan hiba történt. Kérjük, próbálja újra később!',
      'order.invoice.failed.close': 'Bezárás',
      'order.shippingAddress': 'Szállítási cím és átvevő elérhetőségei',
      'order.shippingTime': 'Kívánt szállítási időpont',
      'order.shippingTime.legend': 'Megrendelését a díjbekérő kiegyenlítése után szállítjuk.',
      'order.intro.title': 'Rendelési részletek',
      'order.intro.chooseAddress': 'Másik cím választása',
      'order.intro.productType': 'Termék típusa',
      'order.intro.productType.label': 'Kívánt terméktípus',
      'order.intro.cardConsent': 'Tájékoztatjuk, hogy a rendelhető Beiskolázási utalványunk érvényességi ideje a rendelés időpontjától függetlenül 2018. december 31. Ezt az utalványtípust kizárólag a rendelkezésre álló készlet erejéig értékesítjük. \n' +
        'Ajándékutalványaink továbbra is elérhetőek már 2019-es érvényességi idővel.',
      'order.intro.documentsDialog': 'Tisztelt Ügyfelünk! Felhívjuk figyelmét, hogy az Utalvány kizárólag fogyasztásra kész hideg- és melegételre (Adómentes, jogszabályban meghatározott célokra) termékünk felhasználási köre korlátozott, nem egyezik meg az Ajándékutalvány termék felhasználási körével.',
      'order.sendOrder': 'Elküldöm a megrendelést',
      'order.addressChooserDialog.title': 'Szállítási cím kiválasztása',
      'order.addressChooserDialog.help': 'Kérjük, amennyiben új szállítási címet szeretne megadni, küldje el az új címet Ügyfélszolgálatunk e-mail címére: <br><a href="mailto:info@rewin.hu">info@rewin.hu</a>!',
      'order.addressChooserDialog.subtitle': 'Felhívjuk figyelmét, hogy a Raiffeisen bankfiókokban, ill. az Edenred irodai átvétel megszűnt!',
      'order.addressChooserDialog.close': 'Ablak bezárása',
      'order.addressChooserDialog.save': 'Mentés',
      'order.success.furtherSteps': 'További lépések',
      'order.success.first.title': 'Díjbekérő küldése',
      'order.success.first.legend': 'Az Ön által megadott <strong>{{email}}</strong> e-mail címre 24 órán belül <strong>díjbekérőt kap,</strong> amely előlegszámlaként funkcionál.',
      'order.success.second.title': 'Fizetés átutalással',
      'order.success.second.legend': 'Az ott feltüntetett összeget <strong>kérjük a megadott számlaszámra átutalni!</strong>',
      'order.success.third.title': 'Kiszállítás',
      'order.success.third.legend': 'Megkezdjük az utalványok összekészítését, melyeket <strong>futár fog Önnek kiszállítani. Az eredeti számlát külön, postai úton, a teljesítést követő 15 napon belül kézbesítjük.</strong>',
      'order.thankYou': 'Megrendelését rögzítettük, köszönjük megtisztelő bizalmát!',
      'order.backToHome': 'Vissza a főoldalra',
      'order.history.pending': 'Folyamatban lévő rendelések',
      'order.history.completed': 'Lezárt megrendelések',
      'order.history.date': 'Leadás dátuma',
      'order.history.type': 'Típus',
      'order.history.product': 'Termékek',
      'order.history.status': 'Státusz',
      'order.history.description': 'Megrendelés',
      'order.history.description.value': '#{{order.id}} - {{order.voucherQuantity}} db utalvány igénylés',
      'order.history.total': 'Összeg',
      'order.calculator.calculator.title': 'Kalkulátor',
      'order.calculator.result.title': 'Optimális borítékszám és összetétel',
      'order.calculator.calculator.monthlyBenefit.title': 'Havi juttatás',
      'order.calculator.calculator.monthlyBenefit.text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut eleifend et leo eget molestie. Donec a pellentesque enim. Suspendisse potenti. Mauris quis dui mauris. Maecenas eget nunc porta, ultrices arcu quis, eleifend sapien.',
      'order.calculator.calculator.oneShotBenefit.title': 'Egyszeri juttatás',
      'order.calculator.calculator.oneShotBenefit.text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut eleifend et leo eget molestie. Donec a pellentesque enim. Suspendisse potenti. Mauris quis dui mauris. Maecenas eget nunc porta, ultrices arcu quis, eleifend sapien.',
      'order.calculator.calculator.incentiveBenefit.title': 'Partneri motiváció',
      'order.calculator.calculator.incentiveBenefit.text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut eleifend et leo eget molestie. Donec a pellentesque enim. Suspendisse potenti. Mauris quis dui mauris. Maecenas eget nunc porta, ultrices arcu quis, eleifend sapien.',
      'order.calculator.calculator.productType': 'Termék típusa',
      'order.calculator.calculator.staffNumber': 'Fő',
      'order.calculator.calculator.amountPerPerson': 'Összeg/fő',
      'order.calculator.calculator.totalAmount': 'Teljes összeg',
      'order.calculator.calculator.submit': 'Kalkulálok',
      'order.calculator.calculator.amountTooltip': 'Kérjük ötszázzal osztható összeget írjon',
      'order.calculator.result.sum': 'Teljes összeg',
      'order.calculator.result.boxes': 'Borítékok',
      'order.calculator.result.fingerprint': 'Címletek',
      'order.calculator.result.loggedOutNotice': 'Megrendeléshez kérjük lépjen be felhasználói fiókjába!',
      'order.calculator.result.order': 'Megrendelem',
      'order.info': '<p>Amennyiben nem ragaszkodik a kizárólag hideg- és melegételre szóló utalványhoz, úgy <strong>ajánljuk figyelmébe a szélesebb felhasználhatóságú Ajándékutalványunkat</strong>, melyet új rendelésnél a termék típusnál tud kiválasztani.<br><br>Kérdés esetén kérjük keresse kapcsolattartóját! Köszönjük megértését!</p>'
    });
  }
}());
