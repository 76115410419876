(function () {
  'use strict';

  /* @ngdoc object
   * @name wbAcceptLanguage
   * @description
   *
   */
  angular
    .module('wbAcceptLanguage', [
    ]);
}());
