<div class="wb-sidenav-wrapper">
  <md-sidenav class="md-sidenav-left wb-sidebar wb-sidenav-left" md-component-id="left" md-is-locked-open="true">
    <md-content layout="column" flex role="navigation" class="md-default-theme">

      <md-button class="sidenav-toggle" ng-click="wbSideMenu.toggleSideNav()" hide-gt-lg
        aria-label="{{::'navigation.toggle' | translate}}">
        <md-icon md-font-icon="zmdi zmdi-menu zmdi-hc-lg zmdi-hc-fw"></md-icon>
      </md-button>

      <md-button class="md-raised md-primary wb-sidebar-tall-button" ui-sref="login"
        ng-if="!wbSideMenu.hasLoggedInElements()">{{::'navigation.login' | translate}}</md-button>

      <md-card class="wb-sidebar-navigation" ng-if="wbSideMenu.hasLoggedInElements()">
        <md-card-content>
          <div class="md-title">{{::'navigation.onlineCardManagement' | translate}}</div>
          <md-list layout="column" layout-align="center start">
            <md-item ui-sref="calculator" class="wb-sidebar-navigation-item">
              <md-button layout-fill>
                <md-button class="md-fab not-a-button md-mini md-primary md-wbTheme-theme sidemenu-calculator"
                  aria-label="{{::'navigation.calculator' | translate}}">
                  <md-icon class="md-icon-calculator" md-svg-src="images/calculator.svg"></md-icon>
                </md-button>
                <span>{{::'navigation.calculator' | translate}}</span>
              </md-button>
            </md-item>

            <md-item ui-sref="order" class="wb-sidebar-navigation-item">
              <md-button layout-fill>
                <md-button class="md-fab not-a-button md-mini md-primary md-wbTheme-theme sidemenu-new-order"
                  aria-label="{{::'navigation.newCardOrder' | translate}}">
                  <md-icon md-font-icon="zmdi zmdi-email-open zmdi-hc-lg zmdi-hc-fw"></md-icon>
                </md-button>
                <span>{{::'navigation.order' | translate}}</span>
              </md-button>
            </md-item>

            <md-item ui-sref="orders" class="wb-sidebar-navigation-item">
              <md-button layout-fill>
                <md-button class="md-fab not-a-button md-mini md-warn md-wbTheme-theme sidemenu-orders"
                  aria-label="{{::'navigation.previousOrders' | translate}}">
                  <md-icon md-font-icon="zmdi zmdi-assignment zmdi-hc-lg zmdi-hc-fw"></md-icon>
                </md-button>
                <span>{{::'navigation.orders' | translate}}</span>
              </md-button>
            </md-item>

            <md-item ui-sref="wbPrints" class="wb-sidebar-navigation-item">
              <md-button layout-fill>
                <md-button class="md-fab not-a-button md-mini md-primary md-wbTheme-theme sidemenu-prints"
                  aria-label="{{::'prints.title' | translate}}">
                  <md-icon md-font-icon="zmdi zmdi-download zmdi-hc-lg zmdi-hc-fw"></md-icon>
                </md-button>
                <span>{{::'navigation.prints' | translate}}</span>
              </md-button>
            </md-item>

          </md-list>
        </md-card-content>
      </md-card>

      <wb-contact></wb-contact>

<!--       <a href="https://edenred.hu/wp-content/uploads/2023/01/Fenntarthato_Dolgozoi_juttatasok_2023_Edenred.pdf" target="_blank" rel="noopener noreferrer">
        <img class="wb-sidebar-banner" src="images/banners/225x400px-v15.png">
      </a> -->
    </md-content>
  </md-sidenav>
</div>
