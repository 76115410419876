(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name wbGdpr.directive:wbPrivacyAlert
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="wbGdpr">
   <file name="index.html">
   <wb-privacy-alert></wb-privacy-alert>
   </file>
   </example>
   *
   */
  angular
    .module('wbGdpr')
    .directive('wbPrivacyAlert', wbPrivacyAlert);

  function wbPrivacyAlert() {
    return {
      restrict: 'E',
      scope: {},
      templateUrl: 'wb-gdpr/directives/wb-privacy-alert-directive.tpl.html',
      replace: false,
      controllerAs: 'wbPrivacyAlert',
      controller: function (PrivacyPreferences) {
        var vm = this;

        vm.isVisible = !PrivacyPreferences.hasUserSetConsentOptions;

        vm.openPreferencesDialog = function openPreferencesDialog() {
          PrivacyPreferences.show();
        };

        vm.agree = function agree() {
          PrivacyPreferences.agree();
          hide();
        };

        function hide() {
          vm.isVisible = false;
        }
      }
    };
  }
}());
