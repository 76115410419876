(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbOrder.service:OrderCalculatorService
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .service('OrderCalculatorService', OrderCalculatorService);

  function OrderCalculatorService(Order) {
    var self = this,
      api_mock = Order.getAvailableVouchersPublic().$promise;

    self.getResultsForOneGroup = function cumulateOneGroupApiMockCalculator(amount, parts, voucherId) {
      return api_mock
        .then(function (data) {

          self.publicVouchers = data;
          var api_vouchers = helpers.getBoxesUnitAmountsByVoucher(data, voucherId);
          var amountPerPerson = helpers.getAmountPerPerson(amount, parts);
          var piecesByPerson = helpers.getVoucherPieces(amountPerPerson, api_vouchers, parts);
          var piecesByGroup = helpers.updateVoucherPiecesForGroup(piecesByPerson, parts);
          var groupCumulation = helpers.getGroupCumulation(data, voucherId, piecesByGroup);

          return groupCumulation;
        });
    };

    var helpers = {
      orderNumbersDesc: function (arrayOfNumbers) {
        return arrayOfNumbers.sort(function (a, b) {
          return b - a;
        });
      },
      setupPiecesObject: function (vouchers) {
        var pieces = {};
        vouchers.forEach(function (element) {
          pieces[element] = 0;
        });

        return pieces;
      },
      getAmountPerPerson: function (amount, numberOfPeople) {
        return parseInt(amount, 10) / numberOfPeople;
      },
      getVoucherPieces: function (amount, vouchers, parts) {
        var orderedVouchers = helpers.orderNumbersDesc(vouchers),
          i = 0, remainingAmount = amount,
          pieces = helpers.setupPiecesObject(orderedVouchers);

        while (remainingAmount > 0 && i < orderedVouchers.length) {
          var currentVoucher = orderedVouchers[i],
            currentPieces = Math.floor(remainingAmount / currentVoucher);
          remainingAmount = remainingAmount % currentVoucher;
          pieces[currentVoucher] = currentPieces;
          i++;
        }

        if (remainingAmount > 0) {
          if (helpers.getCurrentVoucherBoxWithZeroRemainder(amount, vouchers)) {
            pieces = helpers.getVoucherPiecesWithoutRemainder(amount, vouchers);
          } else {
            var extendedPieces = helpers.getVoucherPiecesForTotalRemainderAmount(remainingAmount, parts, orderedVouchers);
            pieces = helpers.mergePieces(pieces, extendedPieces);
          }
        }

        return pieces;
      },
      mergePieces: function (firstPiece, secondPiece) {
        Object.keys(secondPiece).forEach(function (key) {
          firstPiece[key] = firstPiece[key] + secondPiece[key];
        });

        return firstPiece;
      },
      getVoucherPiecesForTotalRemainderAmount: function (remainingAmount, parts, orderedVouchers) {
        var remainingAmountForAllPeople = remainingAmount * parts,
          i = 0,
          reversedVouchers = orderedVouchers.slice().reverse(),
          remainingPieces = helpers.setupPiecesObject(reversedVouchers);

        while (remainingAmountForAllPeople > 0 && i < reversedVouchers.length) {
          var currentVoucher = reversedVouchers[i],
            currentPieces = Math.floor(remainingAmountForAllPeople / currentVoucher) / parts;
          remainingAmountForAllPeople = remainingAmountForAllPeople % currentVoucher;
          remainingPieces[currentVoucher] = currentPieces;
          i++;
        }

        if (remainingAmountForAllPeople > 0) {
          remainingPieces[orderedVouchers[i - 1]] += 1 / parts;
        }

        return remainingPieces;
      },
      getVoucherPiecesWithoutRemainder: function (amount, vouchers) {
        var orderedVouchers = helpers.orderNumbersDesc(vouchers),
          remainingAmount = amount,
          pieces = helpers.setupPiecesObject(orderedVouchers);

        while (remainingAmount !== 0) {
          var currentVoucher = helpers.getCurrentVoucherBoxWithZeroRemainder(remainingAmount, orderedVouchers)
          remainingAmount = remainingAmount - currentVoucher;
          pieces[currentVoucher] += 1;
        }

        return pieces;
      },
      getCurrentVoucherBoxWithZeroRemainder: function (remainingAmount, orderVouchers) {
        return _.find(orderVouchers, function (item) {
          return remainingAmount % item === 0;
        });
      },
      updateVoucherPiecesForGroup: function (pieces, groupSize) {
        Object.keys(pieces).forEach(function (key) {
          pieces[key] = groupSize * pieces[key];
        });

        return pieces;
      },
      getBoxesUnitAmountsByVoucher: function (vouchersList, voucherId) {
        return _.map(vouchersList[voucherId]['boxes'], 'unit_amount');
      },
      findBoxByUnitAmount: function (vouchersList, voucherId, unitAmount) {
        return _.find(vouchersList[voucherId]['boxes'], {'unit_amount': unitAmount});
      },
      getTotalForGroup: function (piecesByGroup) {
        var total = 0;
        Object.keys(piecesByGroup).forEach(function (key) {
          total += key * piecesByGroup[key];
        });

        return total;
      },
      getCompositionForGroup: function (vouchersList, voucherId, piecesByGroup) {
        var composition = {};
        Object.keys(piecesByGroup).forEach(function (unitAmount) {
          if (piecesByGroup[unitAmount] > 0) {
            var composite = helpers.findBoxByUnitAmount(vouchersList, voucherId, unitAmount).composition;
            Object.keys(composite).forEach(function (key) {
              if (!composition.hasOwnProperty(key)) {
                composition[key] = 0;
              }
              composition[key] += composite[key] * piecesByGroup[unitAmount];
            });
          }
        });

        return composition;
      },
      getBoxesByGroup: function (piecesByGroup) {
        return _.reduce(piecesByGroup, function (result, val, key) {
          if (val > 0) result[key] = val;
          return result;
        }, {});
      },
      getGroupCumulation: function (vouchersList, voucherId, piecesByGroup) {
        var total = helpers.getTotalForGroup(piecesByGroup);
        var boxes = helpers.getBoxesByGroup(piecesByGroup);
        var composition = helpers.getCompositionForGroup(vouchersList, voucherId, piecesByGroup);
        var groupCumulation = {
          "totalAmount": total,
          "boxes": boxes,
          "composition": composition
        };

        return groupCumulation;
      }
    };
  }
}());
