(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbOrder.controller:IntroCtrl
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .controller('IntroCtrl', IntroCtrl);

  function IntroCtrl($scope,
                     $mdDialog,
                     $document,
                     $rootScope,
                     addresses,
                     deliveryDays,
                     vouchers,
                     _,
                     OrderService,
                     $state,
                     $translate) {
    var vm = this;

    vm.isCalculatedOrder = $state.params.type || false;

    if (!vm.isCalculatedOrder) {
      resetOrder();
    }

    vm.order = OrderService.getOrder();
    vm.introForm = {};
    vm.addresses = addresses;
    vm.order.address = vm.order.address || vm.addresses[0];
    vm.deliveryDays = deliveryDays;
    vm.deliveryDate = vm.order.deliveryDate || null;
    vm.productTypes = vouchers;
    vm.order.voucherId = vm.order.voucherId || null;
    vm.isProcessing = false;

    $rootScope.$on('order.selectedAddressChanged', function (e, selectedAddress) {
      vm.order.address = selectedAddress;
    });

    $mdDialog.show(
      $mdDialog
        .alert({focusOnOpen: false})
        .parent(angular.element($document.body))
        .clickOutsideToClose(true)
        .content($translate.instant('order.intro.documentsDialog'))
        .ok($translate.instant('passwordResetConfirmDialog.button.ok'))
    );

    vm.openAddressDialog = function openAddressDialog() {
      $mdDialog.show({
        templateUrl: 'wb-order/views/address-chooser-dialog.tpl.html',
        controllerAs: 'addressChooserDialog',
        parent: angular.element($document.body),
        focusOnOpen: false,
        clickOutsideToClose: true,
        locals: {
          addresses: vm.addresses,
          selectedAddress: vm.order.address
        },
        controller: function AddressChooserDialogCtrl($mdDialog, addresses, selectedAddress) {
          var vm = this;

          vm.addresses = addresses;
          vm.selectedAddress = selectedAddress;

          vm.selectAddress = function selectAddress($index) {
            vm.selectedAddress = vm.addresses[$index];
          };

          vm.save = function save() {
            $rootScope.$emit('order.selectedAddressChanged', vm.selectedAddress);
            vm.close();
          };

          vm.close = function close() {
            $mdDialog.hide();
          };
        }
      });
    };

    vm.showConsent = (_.find(vouchers, {'id': vm.order.voucherId}) && _.find(vouchers, {'id': vm.order.voucherId}).id === 7);

    vm.updateShowCardConsent = function updateShowCardConsent() {
      vm.showConsent = _.find(vouchers, {'id': vm.order.voucherId}).id === 7;
    };

    vm.nextStep = function nextStep() {
      $scope.$emit('order.next', 'itemList');
    };

    vm.submit = function submit() {
      if (!vm.introForm.$valid) {
        return;
      }

      vm.isProcessing = true;

      OrderService.setOrder(vm.order);

      vm.nextStep();
    };

    function resetOrder() {
      OrderService.resetOrder();
    }
  }
}());
