(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbTimedInformationDialog.constant:wbTimedInformationDialog
   *
   * @description
   *
   */
  angular
    .module('wbTimedInformationDialog')
    .constant('wbTimedInformationDialogConstant', {
      'edenredServiceAlert': {
        name: 'edenredServiceAlertDialog',
        startDate: '2024-01-19 18:00',
        endDate: '2024-01-21 14:00',
        title: '',
        description: '<img class="img-responsive" src="images/maintenance.jpg" />',
        onceOnly: false
      }
    }
    );
}());
