(function () {
  'use strict';

  /* @ngdoc object
   * @name wbErrorHandler
   * @description
   *
   */
  angular
    .module('wbErrorHandler', [
    ]);
}());
