(function () {
  'use strict';
  // jscs:disable maximumLineLength

  angular
    .module('wbOrder')
    .config(config);

  function config($translateProvider) {
    $translateProvider.translations('en', {
      'order.type.1': 'order card',
      'order.type.2': 'top up card',
      'order.status.S': 'successful',
      'order.status.F': 'unsuccessful',
      'order.legend': '{{type}} {{ status}}',
      'order.validation': 'Validation',

      'order.company.name': 'Company name',
      'order.company.taxNum': 'VAT number',

      'order.stuckWhileOrdering': 'Stuck while ordering?',
      'order.steps.beforeYouBegin': 'Before you begin...',
      'order.steps.orderType': 'Order type',
      'order.steps.cardData': 'Card information',
      'order.steps.billingData': 'Billing information',
      'order.steps.sendOrder': 'Send order',

      'order.steps.first.title': 'First step before you order',
      'order.steps.first.subtitle': 'Cut down on your ordering process!',
      'order.steps.first.legend': 'Please enter the company name your would like to order to! <strong>With these information ordering process would be much shorter</strong>, because we fill in the data required instead of you.',

      'order.soon': 'Soon!',

      'order.newCardOrder': 'New card',
      'order.topUp': 'Top up card',

      'order.bulk.files.title': 'New card with top up',
      'order.bulk.files.sampleFile': 'Excel file sample',
      'order.bulk.files.sampleFile.legend': 'Please enter the sample and fill in all of the fields. The chart can not be edited with extra columns. After filling in please save the document',
      'order.bulk.files.uploadFile': 'Upload file',
      'order.bulk.files.uploadFile.legend': 'Please upload the sample you filled out and click ‘Next’.',
      'order.bulk.files.changeFile': 'Replace file',

      'order.bulk.files.error.fields.lastName': 'Last name',
      'order.bulk.files.error.fields.firstName': 'First name',
      'order.bulk.files.error.fields.dateOfBirth': 'Date of birth',
      'order.bulk.files.error.fields.nameOnCard': 'Name on card',
      'order.bulk.files.error.fields.address': 'Address',
      'order.bulk.files.error.fields.city': 'City',
      'order.bulk.files.error.fields.zipCode': 'Zip code',
      'order.bulk.files.error.fields.phoneNumber': 'Telephone',
      'order.bulk.files.error.fields.email': 'Card owner e-mail',
      'order.bulk.files.error.fields.cardData': 'Card information',
      'order.bulk.files.error.fields.cardType': 'Type of card',
      'order.bulk.files.error.fields.loadAmount': 'Top up amount',

      'order.bulk.files.error.field': '{{::field | translate}} error in columns:',

      'order.companyData.details': 'Company information',
      'order.companyData.hq': 'Company address',

      'order.companyData.companyName': 'Company name',
      'order.companyData.companyNumber': 'Company number',
      'order.companyData.vatNumber': 'VAT number',
      'order.companyData.nameOnCard': 'Company name on card',
      'order.companyData.zipCode': 'Zip code',
      'order.companyData.city': 'City',
      'order.companyData.address': 'Address',
      'order.deliveredTo': 'Recipient name',
      'order.deliveredTo.lastName': 'Recipient last name',
      'order.deliveredTo.firstName': 'Recipient first name',
      'order.companyData.billingDifferentFromHq': 'Billing address differs from the company’s address',
      'order.companyData.shippingDifferentFromHq': 'Shipping address differs from the company’s address',
      'order.companyData.billingData': 'Billing information',
      'order.companyData.shippingData': 'Shipping information',

      'order.ownerStatement.title': 'Beneficiary Owner Declaration (TTNY)',

      'order.ownerStatement.ownerDetails': 'Dully Authorized Manager / General Manager',
      'order.ownerStatement.ownerDetails.title': 'Owner’s information',
      'order.ownerStatement.ownerDetails.titleId': 'Title',
      'order.ownerStatement.ownerDetails.name': 'Name of Dully Authorized Manager / General Manager',
      'order.ownerStatement.ownerDetails.birthName': 'Name of birth',
      'order.ownerStatement.ownerDetails.occupation': 'Occupation',
      'order.ownerStatement.ownerDetails.citizenship': 'Citizenship',
      'order.ownerStatement.ownerDetails.identification.type': 'ID type',
      'order.ownerStatement.ownerDetails.identification.number': 'ID number',

      'order.ownerStatement.ownerAddress.title': 'Address',
      'order.ownerStatement.ownerAddress.hungarianAddress.title': 'Hungarian address',
      'order.ownerStatement.ownerAddress.hungarianAddress.subtitle': 'Temporary hungarian address is required in case of foreign citizenship',
      'order.ownerStatement.ownerAddress.foreigner': 'Foreign citizenship',
      'order.ownerStatement.ownerAddress.phoneNumber': 'Phone number (optional)',
      'order.ownerStatement.ownerAddress.faxNumber': 'Fax (optional)',
      'order.ownerStatement.ownerAddress.email': 'E-mail address (optional)',

      'order.administration.title': 'Administration information',
      'order.administration.proforma.title': 'Prepayment information',
      'order.administration.proforma.bankAccount': 'Bank account',
      'order.administration.proforma.employeeNumber': 'Number of employees',
      'order.administration.proforma.phoneNumber': 'Phone number (optional)',
      'order.administration.proforma.faxNumber': 'Fax (optional)',
      'order.administration.proforma.email': 'Administrator email',

      'order.administration.credentials.title': 'Information for login',
      'order.administration.credentials.titleId': 'Title',
      'order.administration.credentials.name': 'Administrator’s name',
      'order.administration.credentials.lastName': 'Last name',
      'order.administration.credentials.firstName': 'First name',
      'order.administration.credentials.password': 'Password',
      'order.administration.credentials.password.legend': 'Please enter your password which you can use later for order in two easy steps. In case you have already registered with this e-mail address, please enter the password required.',
      'order.administration.credentials.password.tooltip': 'Your password has to be longer than 8 digits <br />and has to contain lower and upper case and numbers as well.',

      'order.invoice.title': 'Your invoice',
      'order.invoice.1.title': 'New cards',
      'order.invoice.2.title': 'Topped up cards',
      'order.invoice.additionalFees': 'Additional fees',
      'order.invoice.notes': 'Note',

      'order.invoice.cardType.5.title': 'Gift and shopping card',
      'order.invoice.cardType.6.title': 'Enrollment card',
      'order.invoice.cardType.7.title': 'White Gift Card',
      'order.invoice.cardType.8.title': 'Gold Gift Crad',
      'order.invoice.cardType.9.title': 'Culture and Sport Card',
      'order.invoice.cardType.10.title': 'Silver Gift Card',
      'order.invoice.cardType.undefined.title': 'Unknown type',
      'order.invoice.cardType.topup.title': 'Top ups',

      'order.invoice.item': 'Item',
      'order.invoice.cardType': 'Card type',
      'order.invoice.fees': 'Fees',
      'order.invoice.qty': 'Quantity',
      'order.invoice.net': 'Net',
      'order.invoice.vat': 'VAT',
      'order.invoice.gross': 'Gross',
      'order.invoice.total': 'Total',
      'order.invoice.shippingAddress': 'Billing address of cards',
      'order.invoice.estShipping': 'Estimated delivery',
      'order.invoice.estShippingLegend': 'The sixth working day from the transfer in between 8 am - 6 pm (the day before delivery we send a confirmation email)',
      'order.invoice.recipient': 'Recipient’s name',
      'order.invoice.topUpFee': 'Top up fee ({{handlingFeePercent}}%)',
      'order.invoice.cardFee': 'Card fee',
      'order.invoice.shipping': 'Shipping fee',

      'order.sendOrder': 'Send order',

      'order.thankYou': 'Thank you for ordering',
      'order.backToHome': 'Back to Home',

      'order.success.vipHeading': 'Our Customer Service received sour files.',
      'order.success.vipContent': 'Once processed, we will send you information on the following e-mail address: <strong>{{email}}</strong>.',
      'order.success.furtherSteps': 'Next steps',
      'order.success.furtherStepsLegend': 'We saved your order in our system.',

      'order.success.registered.first.title': 'Prepayment send',
      'order.success.registered.first.legend': 'The entered <strong>{{email}}</strong> e-mail address within 24 hour <strong>we will send you the prepayment</strong>, which is a deposit invoice.',
      'order.success.registered.second.title': 'Payment with transfer',
      'order.success.registered.second.legend': 'Please transfer the amount <strong>to the bank account given</strong>',
      'order.success.registered.newCard.title': 'Shipping',
      'order.success.registered.newCard.legend': 'We started to produce the cards, as soon as they are ready courier will deliver them to you. <strong>We mail the original invoice separately.</strong>',
      'order.success.registered.topUp.title': 'Upload',
      'order.success.registered.topUp.legend': 'Start <strong>top up.</strong>',
      'order.success.newUser.first.title': 'Payment with transfer',
      'order.success.newUser.first.legend': 'The entered <strong>{{email}}</strong> e-mail address within 1 hour <strong>we will send you the prepayment</strong>, which is a deposit invoice.\', <strong>Please transfer to the bank account</strong>\', the amount given',
      'order.success.newUser.second.title': 'Shipping',
      'order.success.newUser.second.legend': 'We started to produce the cards, as soon as they are ready courier will deliver them to you. <strong>We mail the original invoice separately.</strong>',
      'order.success.newUser.third.title': 'Send back documents',
      'order.success.newUser.third.legend': 'Please send two copies of the<strong>contract</strong>, and the <strong>Beneficiary Owner Declaration</strong> printed and <strong>signed send for us</strong> as soon as possiblel!',

      'order.history': 'Order history',
      'order.created': 'Date',
      'order.acceptor': 'Acceptor',
      'order.status': 'Type',
      'order.amount': 'Amount',
      'order.amount.header': 'Top up amount',
      'order.more': 'Next',
      'order.filter': 'Filter',
      'order.filter.dateFrom': 'Start date',
      'order.filter.dateTo': 'End date',
      'order.filter.submit': 'Submit',

      'order.cart': 'Cart',

      'order.1.unique.title': 'Unique order',
      'order.1.unique.subtitle': '(Maximum 25 cards)',
      'order.1.unique.legend': 'For orders with maximum 25 cards we suggest to save information one-by-one for the cards.',

      'order.1.bulk.title': 'Bulk order',
      'order.1.bulk.subtitle': '(1-9999 cards)',
      'order.1.bulk.legend': 'You can upload the cards to our system with the help of an Excel file. We suggest this primarily for ordering 25 cards or more.',

      'order.2.unique.title': 'Unique order',
      'order.2.unique.subtitle': '(Maximum 25 cards)',
      'order.2.unique.legend': 'For orders with maximum 25 cards we suggest to add the amount one-by-one for the cards.',

      'order.2.bulk.title': 'Bulk order',
      'order.2.bulk.subtitle': '(1-9999 cards)',
      'order.2.bulk.legend': 'You can upload the cards to our system with the help of an Excel file. We suggest this primarily for ordering 25 cards ormore.',

      'order.unique.toast.save': 'Added',
      'order.unique.toast.edit': 'Saved',
      'order.unique.toast.delete': 'Deleted',

      'order.back': 'Back',

      'order.itemList.addNew': 'Add new',
      'order.itemList.firstName': 'First name',
      'order.itemList.lastName': 'Last name',
      'order.itemList.csn': 'Card number',
      'order.itemList.cardType.full': 'Card type',
      'order.itemList.sum': 'Amount',

      'order.itemDialog.1.create.title': 'Add new card',
      'order.itemDialog.2.create.title': 'New card top up',
      'order.itemDialog.1.edit.title': 'Edit card',
      'order.itemDialog.2.edit.title': 'Edit card',
      'order.loader.inProgress': 'Your order is being processed. Please wait!',

      'order.itemDialog.cardOwner.title': 'Owner’s information',
      'order.itemDialog.cardData.title': 'Card information',

      'order.itemDialog.close': 'Close window',
      'order.itemDialog.save': 'Save',

      'order.itemList.name': 'Name',
      'order.itemList.dob': 'Date of birth',
      'order.itemList.address': 'Address',
      'order.itemList.city': 'City',
      'order.itemList.zip': 'Zip code',
      'order.itemList.email': 'E-mail',
      'order.itemList.cardType': 'C.type',
      'order.itemList.amount': 'Amount',

      'order.itemDialog.lastName': 'Last name',
      'order.itemDialog.firstName': 'First name',
      'order.itemDialog.dob.full': 'Date of birth',
      'order.itemDialog.nameOnCard': 'Name on card',
      'order.itemDialog.address': 'Address',

      'order.itemDialog.card.title': 'Cards ordered',
      'order.itemDialog.card.cardType': 'Card type',
      'order.itemDialog.card.amount': 'Top up amount',
      'order.itemDialog.card.legend': 'By clicking the button below you can save the information added to your card, and can add new card to the order.',
      'order.itemDialog.card.saveAndContinue': 'Save and add new employee',

      'order.itemDialog.cardTypes.title': 'Get to know our cards',

      'order.itemDialog.delete': 'Delete',
      'order.itemDialog.confirmDelete': 'Sure deleting requested items?',
      'order.itemDialog.cancel': 'Cancel',

      'order.generateFromPrevious': 'Order again',
      'order.bulk.files.download': 'Download',
      'order.bulk.files.delete': 'Remove uploaded file',
      'order.bulk.files.delete.dialog': 'Do you really want to delete the uploaded file ({{fileName}})?',

      'order.errorDialog.title': 'Error',
      'order.errorDialog.legend': 'The Excel file uploaded is invalid. We collected the possible failures for the faster correction. Please correct the failures and upload again the file!',

      'order.sampleFileDownloadAlert': 'Please, do not reformat the excel file and do not delete any column, or puncture. The sample data help for accurate filling, please do not wipe it out.',

      'order.cardInfo.open': 'Click here to see a comparison of our cards!',

      'order.cardComparison.title': 'Comparison of cards',
      'order.cardComparison.close': 'Close',

      'order.comparisonTable.services': 'For what use?',
      'order.comparisonTable.affiliates': 'Number of affiliate merchants',
      'order.comparisonTable.taxRate': 'Tax',

      'order.comparisonTable.cardFee': 'Card fee',
      'order.comparisonTable.cardFee.text': 'Under 100 pieces it is 1000 HUF, in case of 100 pcs or more the fee is 500 HUF',

      'order.comparisonTable.cardReplacementFee': 'Card renewal fee',
      'order.comparisonTable.cardReplacementFee.text': '1 000 HUF',

      'order.comparisonTable.deliveryFee': 'Delivery cost',
      'order.comparisonTable.deliveryFee.text': '999 HUF',

      'order.comparisonTable.handlingFee': 'Handling fee',
      'order.comparisonTable.handlingFee.text': '3%',

      'order.comparisonTable.cards.1.name': 'Edenred Gift and Shopping Card',
      'order.comparisonTable.cards.1.services': 'Monthly regular benefits, ad hoc rewards, fuel',
      'order.comparisonTable.cards.1.affiliates': '60 000',
      'order.comparisonTable.cards.1.taxRate': '43,66%',

      'order.comparisonTable.cards.2.name': 'Edenred Gift Silver Card',
      'order.comparisonTable.cards.2.services': 'Monthly regular benefits, ad hoc rewards, fuel, other extra usages',
      'order.comparisonTable.cards.2.affiliates': '70 000',
      'order.comparisonTable.cards.2.taxRate': '43,66%',

      'order.comparisonTable.cards.3.name': 'Edenred Gift Gold Card',
      'order.comparisonTable.cards.3.services': 'Monthly regular benefits, ad hoc rewards, fuel, other extra usages',
      'order.comparisonTable.cards.3.affiliates': '100 000',
      'order.comparisonTable.cards.3.taxRate': '43,66%',

      'order.comparisonTable.cards.4.name': 'Edenred Schooling Card',
      'order.comparisonTable.cards.4.services': 'School books, school supplies & clothing, shoes, bags, sportswear, optical products, home furnishing, computer devices, do-it-yourself goods.',
      'order.comparisonTable.cards.4.affiliates': '30 000',
      'order.comparisonTable.cards.4.taxRate': '43,66%',

      'order.comparisonTable.cards.5.name': 'Edenred Culture and Sport Card',
      'order.comparisonTable.cards.5.services': 'Culture (museum, festivals, dance performances, teather, concert, circus) & sport tickets',
      'order.comparisonTable.cards.5.affiliates': '1 000',
      'order.comparisonTable.cards.5.taxRate': '0% limitless',

      'order.comparisonTable.cards.6.name': 'Edenred MotivAction Card',
      'order.comparisonTable.cards.6.services': 'Incentive & rewards: motivation of employees, business partners, maintenance of key persons: gift, anniversary payment, year-end or ad-hoc rewards, 13th month payment, game etc.',
      'order.comparisonTable.cards.6.affiliates': '70 000',
      'order.comparisonTable.cards.6.taxRate': '43,66%',

      'order.callback.title': 'Request a callback',
      'order.callback.standalone.heading': 'We\'d like to have You amongst our clients too!',
      'order.callback.merchant.heading': 'Would you like to become Edenred affiliate merchant?',
      'order.callback.legend': 'If you can’t complete your order now, because you don’t have all the data, we kindly ask you to fill out the following form!<br/>Then our sales representative will call You back soon and will help You to go through the online contracting and ordering process.',
      'order.callback.standalone.legend': 'In case our product has caught your interest as an employer, we kindly ask you to fill out your contact details on the right, so our sales representative can call you to provide more information.',
      'order.callback.merchant.legend': 'Please fill out this form and we will call you back soon!',
      'order.callback.name': 'Name',
      'order.callback.companyName': 'Company name',
      'order.callback.phone': 'Phone (direct contact)',
      'order.callback.customerType': 'Client type',
      'order.callback.customerType.1': 'Employer',
      'order.callback.customerType.2': 'Cardholder',
      'order.callback.customerType.3': 'Affiliate partner',
      'order.callback.submit': 'Call me back',
      'order.callback.cancel': 'Cancel',
      'order.callback.success': 'Your request has been sent!',

      'order.orderStart.title.1': 'Card ordering with Online contracting',
      'order.orderStart.title.2': 'Request a callback',
      'order.orderStart.aboutCards': 'Compare cards',
      'order.orderStart.order': 'Order with contract',
      'order.orderStart.text': 'Your online contract will automatically be created when the first order is submitted.<br/>The order contains <strong>5 simple steps</strong> and approx. it takes <strong>10 minutes</strong>.<br/>Before proceeding, please complete the following steps:',
      'order.orderStart.steps.1.title': 'Preparation of company data',
      'order.orderStart.steps.1.description': '(company name, tax number, owner and contact data)',
      'order.orderStart.steps.2.title': 'Select the cards to be ordered',
      'order.orderStart.steps.3.title': 'Collecting prospective cardholders, employee\'s data',
      'order.orderStart.steps.3.description': '(name, date of birth, address, email address)',
      'order.orderStart.steps.4.title': 'Determine the amount to be uploaded per card',
      'order.orderStart.callback.title': 'Request a callback',
      'order.orderStart.callback.text': 'If you do not yet have all the above-mentioned data or the final list of cards to order, please fill in the following form and our sales representative will call You back soon!',
    });
  }
}());
