(function () {
  'use strict';

  /* @ngdoc object
   * @name lodash
   * @description
   *
   */
  angular
    .module('lodash', [
    ]);
}());
