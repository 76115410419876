(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name wbAccount.directive:zipToCity
   * @restrict A
   * @description Send 4 digit zip codes from the zipCode input to the api and their city name response set into the city input
   *
   * @example
   <example module="wbAccount">
   <file name="index.html">
   <div zip-to-city="test" receive="true"></div>
   </file>
   </example>
   *
   */
  angular
    .module('wbAccount')
    .directive('zipToCity', zipToCity);

  function zipToCity($rootScope, _) {
    return {
      restrict: 'A',
      require: ['zipToCity', 'ngModel'],
      controllerAs: 'zipToCity',
      controller: function ($rootScope, User) {
        var vm = this;

        /**
         * Get the name of a city based on the zip code, then broadcast it to the desired targets
         *
         * @param {int} zip
         * @param {string} targetName
         */
        vm.getCityName = function (zip, targetName) {
          var unwrapResponse = function (response) {
              return response.name;
            },
            broadcastCityNameToTarget = function (cityName) {
              $rootScope.$broadcast(targetName, cityName);
            };

          User
            .cityNameByZipCode({zip: zip})
            .$promise
            .then(unwrapResponse)
            .then(broadcastCityNameToTarget)
            .catch(angular.noop);
        };
      },
      link: function (scope, element, attrs, ctrls) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        var ngModel = ctrls[1]
          , zipToCity = ctrls[0];

        // if the directive is a receiver, hook on the event
        if (attrs.receive == 'true') {
          $rootScope.$on(attrs.zipToCity, function (e, cityName) {
            ngModel.$setViewValue(cityName);
            ngModel.$render();
          });
        }

        // if the directive is not a reciever send events if not pristine
        if (attrs.receive == 'false') {
          scope.$watch(function () {
            return ngModel.$modelValue;
          }, function (newValue) {
            var zip = parseInt(newValue);

            if (ngModel.$pristine) {
              return;
            }

            if (zip.toString().length === 4) {
              zipToCity.getCityName(newValue, attrs.zipToCity);
            }
          });
        }
      }
    };
  }
}());
