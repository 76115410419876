<md-dialog class="wb-dialog wb-dialog-wide">
  <h2 class="wb-dialog-title">{{::'order.addressChooserDialog.title' | translate}}</h2>

  <p class="wb-address-chooser-help" ng-bind-html="::'order.addressChooserDialog.help' | translate"></p>

  <p class="wb-address-chooser-notice">{{::'order.addressChooserDialog.subtitle' | translate}}</p>

  <md-dialog-content>
    <ul class="wb-address-chooser">
      <li class="wb-address-chooser-item"
          ng-repeat="address in addressChooserDialog.addresses track by address.departmentCode"
          ng-click="addressChooserDialog.selectAddress($index)"
          ng-class="{'wb-address-chooser-item-selected': address.departmentCode === addressChooserDialog.selectedAddress.departmentCode}">
        <span class="wb-address-chooser-item-name"><span ng-if="address.departmentName"><strong>{{::address.departmentName}} </strong></span>{{::address.contactName}}</span>
        <span>{{::address.address.zip}} {{::address.address.city}}, {{::address.address.address}}</span>
      </li>
    </ul>
  </md-dialog-content>

  <div class="md-actions">
    <md-button class="md-button md-flat" type="button" ng-click="addressChooserDialog.close()">
      {{::'order.addressChooserDialog.close' | translate}}
    </md-button>
    <md-button class="md-button md-raised md-primary" type="button" ng-click="addressChooserDialog.save()">
      {{::'order.addressChooserDialog.save' |
      translate}}
    </md-button>
  </div>
</md-dialog>
